.booking-details {
  padding-bottom: 30px 0;
  background-color: var(--white);
}

.subHeadStick h2 {
  font-size: 24px;
  font-weight: bold;
}

.tabs {
  width: -webkit-fill-available;
  display: flex;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  overflow: auto;
}

.tab {
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: gray;
  border-bottom: 2px solid transparent;
}

.tab.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color) !important;
}

.pagination-container {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0px 30px;
  background-color: var(--white);
}

.pagination-line {
  width: 100%;
  border: 1px solid #ccc;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pagination-arrow {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.pagination-text {
  font-size: 16px;
  color: #666;
}

.FavrateDivs {
  margin-bottom: 3rem;
}

.SubmtFeedBck {
  height: fit-content;
}

/* Booking Cards */

.booking-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
  gap: 10px;
}

.carImageBooking {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 6px;
}

.booking-info {
  flex: 1;
  min-width: 0;
  /* Prevents overflow in flex container */
}

.booking-info h3 {
  margin: 0;
  font-size: 20px;
  color: var(--text-black);
  font-weight: 600;
  line-height: normal !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.booking-info p {
  margin: 0;
  font-size: 13px;
  color: var(--text-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DetailBtn {
  transition: all 0.3s ease;
  border: 1px solid #dee2e6 !important;
  color: var(--black) !important;
  background-color: transparent !important;
  transform-origin: left;

  &:hover {
    background-color: var(--primary-color) !important;
    transform: translateX(-5px);
  }
}



.Mobile-status {
  margin-top: -2.5%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

}

.action-button-booking {
  border: none;
  background: none;

  cursor: pointer;
  color: #888;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 12px;

  padding: 5px 10px;
  font-size: 12px !important;
}

.agreement-button {
  width: 100%;
  background-color: #007670;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
}

.agreement-button:hover {
  background-color: #41774d;
}

/* Responsive Breakpoints */
@media screen and (max-width: 768px) {
  .booking-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .booking-card>div:first-child {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
  }

  .carImageBooking {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }

  .booking-info h3 {
    font-size: 20px;
  }

  .booking-info p {
    font-size: 14px;
  }

  .Mobile-status {
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .carImageBooking {
    width: 60px;
    height: 60px;
    margin-right: 8px;
  }

  .booking-info h3 {
    font-size: 18px;
  }

  .booking-info p {
    font-size: 12px;
  }

  .action-button {
    font-size: 14px;
  }

  .full-text-label::before {
    content: 'Status: ';
    margin-right: 4px;
    font-weight: 600;
  }
}

@media screen and (max-width: 320px) {
  .booking-card {
    padding: 10px;
  }

  .carImageBooking {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  .booking-info h3 {
    font-size: 16px;
  }

  .booking-info p {
    font-size: 10px;
  }

  .action-button {
    font-size: 12px;
  }
}