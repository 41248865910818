.profile_img_section {

    .profile-image-container {
        display: flex;
        /* justify-content: center; */
        margin-bottom: 24px;
    }

    .profile-image-upload {
        position: relative;
        width: 128px;
        height: 128px;
        border-radius: 50%;
        border: 2px dashed #e5e5e5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        transition: border-color 0.2s;
    }

    .profile-image-upload:hover {
        border-color: #4ade80;
    }

    .profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .upload-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #6b7280;
    }

    .upload-text {
        font-size: 12px;
        margin-top: 8px;
    }

    .hidden {
        display: none;
    }



}


.error-text {
    color: red;
    font-size: 13px;
}