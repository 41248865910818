/***
 Theme Name: Carento
 Description: Car rental HTML template
 Version: 1.0
 License: GNU General Public License v2 or later
 License URI: http://www.gnu.org/licenses/gpl-2.0.htm
==============================
TABLE CONTENT
 Import Google Fonts
 Include Third Party CSS Library
 GENERAL
 HEADER
 Offcanvas Sidebar
 Search form
 Main header
 Mega menu
 Header layouts
 Page layouts
 Pagination
 Breadcrumb
 CATEGORY PAGES
 SINGLE POST
 Author box
 comment
 Post share social
 WP STYPE
 Custom amine
***/
@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@100..900&display=swap);
@import url(../css/vendors/normalize.css);
@import url(../css/vendors/bootstrap.min.css);
@import url(../css/vendors/uicons-regular-rounded.css);
@import url(../css/plugins/swiper-bundle.min.css);
@import url(../css/plugins/magnific-popup.css);
@import url(../css/plugins/animate.css);
@import url(../css/plugins/select2.min.css);
@import url(../css/plugins/perfect-scrollbar.css);
@import url(../css/plugins/carouselTicker.css);
@import url(../css/plugins/bootstrap-datepicker.css);
@import url(../css/plugins/bootstrap-datepicker3.css);
@import url(../css/plugins/slick.css);
@import url(../css/plugins/odometer.css);
:root {
  --bs-border-color: #dde1de;
  --bs-color-white: #ffffff;
  --bs-color-black: #000000;
  --bs-color-700: #4d4d4d;
  --bs-background-1000: #000000;
  --bs-background-white: #ffffff;
  --bs-color-1000: #000000;
  --bs-background-body: #ffffff;
  --bs-background-1: #fff0ec;
  --bs-background-2: #d8f4db;
  --bs-background-3: #e3f0ff;
  --bs-background-4: #f6f3fc;
  --bs-background-5: #ffec88;
  --bs-background-6: #9dd3fb;
  --bs-background-7: #fcf2fa;
  --bs-background-8: #f6f3fc;
  --bs-background-9: #fcfcf3;
  --bs-background-12: #a8e2ef;
  --bs-background-card: #ffffff;
  --bs-neutral-0: #ffffff;
  --bs-neutral-100: #f2f4f6;
  --bs-neutral-200: #e4e6e8;
  --bs-neutral-300: #d6d7d8;
  --bs-neutral-400: #8e8e8e;
  --bs-neutral-500: #737373;
  --bs-neutral-600: #575756;
  --bs-neutral-700: #4d4d4d;
  --bs-neutral-800: #454545;
  --bs-neutral-900: #313131;
  --bs-neutral-1000: #000000;
  --bs-button-bg: #70f46d;
  --bs-button-bg-2: #f2f4f6;
  --bs-button-text: #101010;
  --bs-button-text-2: #000000;
  --bs-brand-2: #70f46d;
  --bs-brand-2-dark: #70f46d;
  --bs-brand-2-darken: #5edd5b;
  --bs-brand-2-light: #2d4a2c;
  --bs-link-hover-color: #235922;
  --bs-brand-1: #8acfff;
  --bs-warning: #ffc700;
  --bs-success: #34d674;
  --bs-danger: #ff2e00;
  --bs-color-grey: #afafaf;
  --bs-color-2: #c5ff41;
  --bs-color-grey-2: #f0f0f0;
  --bs-neutral-950: #1e1e1e;
  --bs-primary-rgb: 112, 244, 109;
}

/*import vendors*/
/*import plugins*/
/*RESET*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

main {
  display: block;
  clear: both;
}

thead {
  font-weight: 600;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
}

input,
select,
button,
textarea {
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

a {
  color: var(--bs-neutral-1000);
}
a:hover {
  color: var(--bs-link-hover-color) !important;
}

li.hr span {
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
  margin: 20px 0;
  display: block;
}

/*--- Common Classes---------------------*/
::placeholder {
  color: var(--bs-neutral-600);
  opacity: 1;
}

:-ms-input-placeholder,
::-webkit-input-placeholder {
  color: var(--bs-neutral-600);
  opacity: 1;
}

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

.height-100vh {
  height: 100vh !important;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  outline: none !important;
  box-shadow: none;
}

.hover-up {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.hover-up:hover {
  transform: translateY(-3px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

body {
  background: #fff;
  background-size: contain;
}

.form-control {
  border: 1px solid var(--bs-neutral-200);
  border-radius: 8px;
  background-color: var(--bs-background-card);
  padding: 11px 15px 13px 15px;
  width: 100%;
  color: var(--bs-neutral-600);
}
.form-control::placeholder {
  color: var(--bs-neutral-400);
}

.form-group {
  position: relative;
}
.form-group i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  font-size: 18px;
  color: var(--bs-neutral-600);
}

textarea.form-control {
  height: auto;
}

.form-icons {
  padding-left: 41px;
}

.text-white {
  color: #ffffff;
}

.brand-1 {
  color: var(--bs-brand-1);
}

@media (min-width: 1400px) {
  .container {
    max-width: 1248px;
  }
}
strong {
  font-weight: bold;
}

.divider {
  border-top: 1px solid var(--bs-neutral-200);
  margin: 20px 0px;
}

a {
  text-decoration: none;
}

*:hover {
  transition-duration: 0.2s;
}

.section-box,
.box-section {
  display: block;
  width: 100%;
}

.text-shadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.img-responsive {
  max-width: 100%;
}

.link-green {
  color: #319f1f;
}

.dropdown {
  position: relative;
}
.dropdown .dropdown-menu.show {
  border: thin solid var(--bs-neutral-200);
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 100, 0.05);
  background-color: var(--bs-neutral-0);
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
}
.dropdown .dropdown-menu.show .dropdown-item {
  padding: 10px 20px;
  color: var(--bs-neutral-900);
  font-size: 14px;
}
.dropdown .dropdown-menu.show .dropdown-item.active {
  color: var(--bs-neutral-0);
  text-decoration: none;
  background-color: var(--bs-neutral-700);
}
.dropdown .dropdown-menu.show .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--bs-neutral-700);
}
.dropdown.dropdown-sort .dropdown-menu.show {
  border-radius: 10px;
  margin-top: 20px;
}

.display-1 {
  font-size: 96px;
  line-height: 76px;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
}

.display-2 {
  font-size: 82px;
  line-height: 76px;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  font-family: "Urbanist", sans-serif;
  font-weight: 700;
}

h1,
.heading-1 {
  font-size: 64px;
  line-height: 76px;
}

h2,
.heading-2 {
  font-size: 52px;
  line-height: 76px;
}

h3,
.heading-3 {
  font-size: 44px;
  line-height: 58px;
}

h4,
.heading-4 {
  font-size: 36px;
  line-height: 48px;
}

h5,
.heading-5 {
  font-size: 28px;
  line-height: 36px;
}

h6,
.heading-6 {
  font-size: 24px;
  line-height: 32px;
}

.heading-1-regular {
  font-weight: 400;
}

.heading-1-medium {
  font-weight: 500;
}

.heading-2-regular {
  font-weight: 400;
}

.heading-2-medium {
  font-weight: 500;
}

.heading-3-regular {
  font-weight: 400;
}

.heading-3-medium {
  font-weight: 500;
}

.heading-4-regular {
  font-weight: 400;
}

.heading-4-medium {
  font-weight: 500;
}

.heading-5-regular {
  font-weight: 400;
}

.heading-5-medium {
  font-weight: 500;
}

.heading-6-regular {
  font-weight: 400;
}

.heading-6-medium {
  font-weight: 500;
}

p {
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
}

.text-sm,
.text-md,
.text-lg,
.text-xl,
.text-label {
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
}

.text-xs,
.text-xs-medium,
.text-xs-bold {
  font-size: 12px;
  line-height: 18px;
}

.text-sm,
.text-sm-medium,
.text-sm-bold {
  font-size: 14px;
  line-height: 22px;
}

.text-md,
.text-md-medium,
.text-md-bold {
  font-size: 16px;
  line-height: 26px;
}

.text-lg,
.text-lg-medium,
.text-lg-bold {
  font-size: 18px;
  line-height: 28px;
}

.text-xl,
.text-xl-medium,
.text-xl-bold {
  font-size: 20px;
  line-height: 32px;
}

.text-xs-bold,
.text-sm-bold,
.text-md-bold,
.text-lg-bold,
.text-xl-bold {
  font-weight: 700 !important;
}

.text-xs-medium,
.text-sm-medium,
.text-md-medium,
.text-lg-medium,
.text-xl-medium {
  font-weight: 500;
}

.text-label {
  font-size: 22px;
  line-height: 27px;
}

.text-15-medium {
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

.text-20-medium {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

.text-24-semibold {
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  letter-spacing: 3%;
  font-family: "Urbanist", sans-serif;
}

.text-24-bold {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
}

.text-24-medium {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

.text-22-bold {
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
}

.text-20-medium {
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

.text-18-bold {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
}

.text-16-bold {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
}

.text-18-semibold {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
}

.text-32-bold {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
}

.text-48-semibold {
  font-size: 48px;
  line-height: 55px;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
}

.text-88-semibold {
  font-size: 88px;
  line-height: 76px;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
}

.text-96 {
  font-size: 96px !important;
  line-height: 103px !important;
  font-weight: 400 !important;
  font-family: "Urbanist", sans-serif !important;
}

.linear-3 {
  background: linear-gradient(236deg, #22d1ee 0%, #c5ff41 100%);
}

.color-grey {
  color: var(--bs-color-grey);
}

.color-grey-2 {
  color: var(--bs-color-grey-2);
}

.linear-1 {
  background: linear-gradient(236deg, #22d1ee 0%, #3d5af1 100%);
}

.linear-2 {
  background: linear-gradient(236deg, #27d9ae 0%, #23caee 100%);
}

.border-linear-2 {
  border-image-source: linear-gradient(to left, #22d1ee, #3d5af1) !important;
}

.bg-1 {
  background-color: var(--bs-background-1) !important;
}

.bg-2 {
  background-color: var(--bs-background-2) !important;
}

.bg-3 {
  background-color: var(--bs-background-3) !important;
}

.bg-4 {
  background-color: var(--bs-background-4) !important;
}

.bg-5 {
  background-color: var(--bs-background-5) !important;
}

.bg-6 {
  background-color: var(--bs-background-6) !important;
}

/*page loading*/
.preloader {
  background-color: #ecf0f1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}
.preloader img {
  max-width: 250px;
}

.w-85 {
  width: 85% !important;
}

.dropdown-language .dropdown-item img {
  margin-right: 5px;
}

.box-dropdown-cart {
  position: relative;
}

.box-dropdown-cart .dropdown-account,
.box-dropdown-cart .dropdown-cart {
  padding: 25px 25px;
  width: 120px;
}

.box-dropdown-cart .dropdown-cart {
  width: 90px;
}

.box-dropdown-cart .dropdown-account ul li,
.box-dropdown-cart .dropdown-cart ul li {
  margin-bottom: 15px;
}

.box-dropdown-cart .dropdown-account ul li a,
.box-dropdown-cart .dropdown-cart ul li a {
  color: var(--bs-neutral-1000);
  text-decoration: none;
  transition-duration: 0.2s;
}
.box-dropdown-cart .dropdown-account ul li a:hover,
.box-dropdown-cart .dropdown-cart ul li a:hover {
  transition-duration: 0.2s;
}

.box-dropdown-cart .dropdown-account ul li a img,
.box-dropdown-cart .dropdown-cart ul li a img {
  float: left;
  margin: 4px 10px 0px 0px;
}

.dropdown-cart {
  display: none;
  visibility: hidden;
  position: absolute;
  right: -70px;
  top: calc(100% + 22px);
  z-index: 99;
  width: 320px;
  background-color: #fff;
  padding: 22px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0 0 4px 4px;
  border: 1px solid #3d565f;
}

.dropdown-cart.dropdown-open {
  display: block;
  visibility: visible;
}

.dropdown-account,
.dropdown-cart {
  right: 0;
  display: none;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 0px);
  border-radius: 8px;
  z-index: 99;
  width: 220px;
  background-color: var(--bs-neutral-0);
  padding: 20px;
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 100, 0.05);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 0 !important;
}

.dropdown-account.dropdown-open,
.dropdown-cart.dropdown-open {
  display: block;
  visibility: visible;
}

.dropdown-account ul li,
.dropdown-cart ul li {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
}

.dropdown-account ul li:last-child,
.dropdown-cart ul li:last-child {
  margin: 0;
}

.dropdown-account ul li a,
.dropdown-cart ul li a {
  color: var(--bs-neutral-1000);
}

.dropdown-account ul li a:hover,
.dropdown-cart ul li a:hover {
  color: var(--bs-link-hover-color) !important;
}

.bdrd8 {
  border-radius: 8px;
}

.form-group .cb-remember {
  height: 15px;
  width: 15px;
  float: left;
  margin: 1px 10px 0px 0px;
}

.form-group .remeber-me label {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

.form-group label.text-sm-medium {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  margin-bottom: 8px;
}
.form-group input.username {
  background-image: url(../imgs/template/popup/username.svg);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.form-group input.username:focus {
  background-image: url(../imgs/template/popup/username.svg);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.form-group input.email {
  background-image: url(../imgs/template/popup/email.svg);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.form-group input.email:focus {
  background-image: url(../imgs/template/popup/email.svg);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.form-group input.phone {
  background-image: url(../imgs/template/popup/phone.svg);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.form-group input.phone:focus {
  background-image: url(../imgs/template/popup/phone.svg);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.form-group input.password {
  background-image: url(../imgs/template/popup/pass.svg);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.form-group input.password:focus {
  background-image: url(../imgs/template/popup/pass.svg);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}

.box-remember-forgot {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.background-body {
  background-color: var(--bs-background-body) !important;
}

.background-brand-2 {
  background-color: var(--bs-brand-2) !important;
}

.button-bg-2 {
  background-color: var(--bs-button-bg-2) !important;
}

.background-1 {
  background-color: var(--bs-background-1) !important;
}

.background-2 {
  background-color: var(--bs-background-2) !important;
}

.background-3 {
  background-color: var(--bs-background-3) !important;
}

.background-4 {
  background-color: var(--bs-background-4) !important;
}

.background-5 {
  background-color: var(--bs-background-5) !important;
}

.background-6 {
  background-color: var(--bs-background-6) !important;
}

.background-7 {
  background-color: var(--bs-background-7) !important;
}

.background-8 {
  background-color: var(--bs-background-8) !important;
}

.background-9 {
  background-color: var(--bs-background-9) !important;
}

.background-card {
  background-color: var(--bs-background-card) !important;
}

.background-0 {
  background-color: var(--bs-neutral-0) !important;
}

.background-100 {
  background-color: var(--bs-neutral-100) !important;
}

.background-1000 {
  background-color: var(--bs-neutral-1000) !important;
}

.color-white {
  color: var(--bs-color-white) !important;
}

.neutral-0 {
  color: var(--bs-neutral-0) !important;
}

.neutral-100 {
  color: var(--bs-neutral-100) !important;
}

.neutral-200 {
  color: var(--bs-neutral-200) !important;
}

.neutral-300 {
  color: var(--bs-neutral-300) !important;
}

.neutral-400 {
  color: var(--bs-neutral-400) !important;
}

.neutral-500 {
  color: var(--bs-neutral-500) !important;
}

.neutral-600 {
  color: var(--bs-neutral-600) !important;
}

.neutral-700 {
  color: var(--bs-neutral-700) !important;
}

.neutral-800 {
  color: var(--bs-neutral-800) !important;
}

.neutral-900 {
  color: var(--bs-neutral-900) !important;
}

.neutral-1000 {
  color: var(--bs-neutral-1000) !important;
}

.light-mode {
  display: inline-block !important;
}

.dark-mode {
  display: none !important;
}

.divider-line {
  margin: 20px 0px;
  border-bottom: 1px solid var(--bs-border-color);
}

[data-bs-theme=dark] .invert {
  filter: invert(1);
}
[data-bs-theme=dark] .card-miles {
  filter: invert(1);
}
[data-bs-theme=dark] .card-gear {
  filter: invert(1);
}
[data-bs-theme=dark] .card-fuel {
  filter: invert(1);
}
[data-bs-theme=dark] .card-seat {
  filter: invert(1);
}
[data-bs-theme=dark] .bg-footer {
  filter: invert(1);
}
[data-bs-theme=dark] .card-popular .card-info .card-title:hover,
[data-bs-theme=dark] .card-journey-big .card-info .card-title a:hover {
  color: var(--bs-brand-2);
}
[data-bs-theme=dark] .card-popular .card-info .card-meta .card-button a svg {
  stroke: var(--bs-color-grey);
}
[data-bs-theme=dark] .btn.btn-dropdown {
  background-image: url(../imgs/template/icons/arrow-down-white.svg);
  background-repeat: no-repeat;
}
[data-bs-theme=dark] #dropdownSort {
  background: url(../imgs/template/icons/sort-w.svg) no-repeat right 16px center;
}
[data-bs-theme=dark] .box-top-rated {
  background-image: url(../imgs/page/homepage1/bg-top-rate.png);
}
[data-bs-theme=dark] .header .main-menu li.has-children > a::after,
[data-bs-theme=dark] .header .main-menu li.has-children > a:hover::after {
  background-image: url(../imgs/template/icons/arrow-down-white.svg);
}
[data-bs-theme=dark] .light-mode {
  display: none !important;
}
[data-bs-theme=dark] .dark-mode {
  display: inline-block !important;
}
[data-bs-theme=dark] .card-journey-small .card-image .wish,
[data-bs-theme=dark] .card-journey-big .card-image .wish {
  background-color: rgba(25, 25, 25, 0.7960784314);
}
[data-bs-theme=dark] .card-journey-small .card-image .wish svg,
[data-bs-theme=dark] .card-journey-big .card-image .wish svg {
  stroke: #f2f2f2;
}
[data-bs-theme=dark] .block-testimonials .testimonials-left::before {
  background: url(../imgs/page/homepage1/plane-w.png) no-repeat center;
}
[data-bs-theme=dark] .icon-cart span {
  background: url(../imgs/template/icons/arrow-down-sm-w.svg) no-repeat right center;
}
[data-bs-theme=dark] .card-flight .card-info .card-route .icon-route {
  background: url(../imgs/template/icons/route.svg) no-repeat center;
}
[data-bs-theme=dark] .btn.btn-dropdown-search:after {
  background-image: url(../imgs/page/homepage2/dropdown-w.svg);
}
[data-bs-theme=dark] .need-some-help {
  background: url(../imgs/template/icons/user-w.svg) no-repeat left center;
}
[data-bs-theme=dark] .box-calendar-date::before {
  background-image: url(../imgs/page/homepage2/dropdown-w.svg);
}
[data-bs-theme=dark] .accordion-button::after {
  background-image: url(../imgs/template/icons/open-faq-dark.png);
}
[data-bs-theme=dark] .accordion-button:not(.collapsed)::after {
  background-image: url(../imgs/template/icons/close-faq-dark.png);
}
[data-bs-theme=dark] .box-faqs-inner {
  background-image: url(../imgs/page/homepage3/bg-faq2-dark.png);
}
[data-bs-theme=dark] .box-faqs {
  background-image: url(../imgs/page/homepage3/bg-faq-dark.png);
}
[data-bs-theme=dark] .cb-container input:checked ~ .checkmark {
  background-image: url(../imgs/template/icons/checked-dark.svg);
}
[data-bs-theme=dark] .link-see-more:hover {
  color: var(--bs-brand-1);
}
[data-bs-theme=dark] .pagination li a:hover {
  background-color: var(--bs-button-bg);
}
[data-bs-theme=dark] .dropdown-sort .dropdown-toggle,
[data-bs-theme=dark] .dropdown-sort #dropdownSort {
  background-image: url(../imgs/template/icons/dropdown-dark.svg);
}
[data-bs-theme=dark] .box-filters-sidebar .block-filter .item-collapse {
  background-image: url(../imgs/template/icons/arrow-up-dark.svg);
}
[data-bs-theme=dark] .box-filters-sidebar .block-filter .item-collapse.collapsed-item {
  background-image: url(../imgs/template/icons/arrow-down-filter-dark.svg);
}
[data-bs-theme=dark] .form-group input.username {
  background-image: url(../imgs/template/popup/username-w.svg);
}
[data-bs-theme=dark] .form-group input.password {
  background-image: url(../imgs/template/popup/pass-w.svg);
}
[data-bs-theme=dark] .form-group input.email {
  background-image: url(../imgs/template/popup/email-w.svg);
}
[data-bs-theme=dark] #scrollUp svg {
  fill: var(--bs-neutral-0);
}
[data-bs-theme=dark] .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand i {
  background: url(../imgs/template/icons/arrow-right-big-w.png) no-repeat center;
}
[data-bs-theme=dark] .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand i {
  background: url(../imgs/template/icons/arrow-down-big-w.png) no-repeat center;
}
[data-bs-theme=dark] .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-logo .burger-icon {
  background-image: url(../imgs/template/icons/close-w.png);
}
[data-bs-theme=dark] .box-search-style-2 .btn-search-submit {
  background-image: url(../imgs/page/blog/icon-search-dark.png);
}

.mobile-menu {
  margin-top: 30px;
}

[data-bs-theme=dark] {
  --bs-border-color: #313131;
  --bs-color-white: #ffffff;
  --bs-background-1000: #000000;
  --bs-background-body: #171717;
  --bs-background-1: #242120;
  --bs-background-2: #739393;
  --bs-background-3: #1d2024;
  --bs-background-4: #242226;
  --bs-background-5: #ffdc88;
  --bs-background-6: #93dced;
  --bs-background-7: #1e1c1d;
  --bs-background-8: #121d20;
  --bs-background-9: #212114;
  --bs-background-card: #191919;
  --bs-neutral-0: #000000;
  --bs-neutral-100: #313131;
  --bs-neutral-200: #454545;
  --bs-neutral-300: #4d4d4d;
  --bs-neutral-400: #575756;
  --bs-neutral-500: #737373;
  --bs-neutral-600: #8e8e8e;
  --bs-neutral-700: #d6d7d8;
  --bs-neutral-800: #e4e6e8;
  --bs-neutral-900: #f2f4f6;
  --bs-neutral-1000: #f2f2f2;
  --bs-button-bg: #313131;
  --bs-button-bg-2: #222222;
  --bs-button-text: #ededed;
  --bs-button-text-2: #afafaf;
}
[data-bs-theme=dark] .dark-invert {
  filter: invert(1) !important;
}
[data-bs-theme=dark] .card-popular .card-info .card-meta .meta-links a {
  border: 0 !important;
}

.background-brand-2 {
  background-color: var(--bs-brand-2) !important;
}

/*Datepick style*/
.datepicker table tr td,
.datepicker table tr th {
  width: auto;
  height: auto;
  border-radius: 6px;
  padding: 8px 15px;
}

.datepicker .next {
  background-image: url(../imgs/template/icons/next-day.png);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  text-indent: -100px;
  overflow: hidden;
}
.datepicker .next:hover {
  background-image: url(../imgs/template/icons/next-day.png);
  background-repeat: no-repeat;
  background-position: center;
}

.datepicker .prev {
  background-image: url(../imgs/template/icons/prev-day.png);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  text-indent: -100px;
  overflow: hidden;
}
.datepicker .prev:hover {
  background-image: url(../imgs/template/icons/prev-day.png);
  background-repeat: no-repeat;
  background-position: center;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  background-color: var(--bs-brand-2);
  border-color: var(--bs-brand-2);
  color: var(--bs-color-black);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(var(--bs-brand-2)), to(var(--bs-brand-2)));
  background-image: -webkit-linear-gradient(to bottom, var(--bs-brand-2), var(--bs-brand-2));
  background-image: -o-linear-gradient(to bottom, var(--bs-brand-2), var(--bs-brand-2));
  background-image: linear-gradient(to bottom, var(--bs-brand-2), var(--bs-brand-2));
}

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  color: var(--bs-color-black);
}

.box-calendar-date {
  position: relative;
}
.box-calendar-date::before {
  content: "";
  height: 10px;
  width: 14px;
  background-color: transparent;
  background-image: url(../imgs/page/homepage2/dropdown.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0px;
}
.box-calendar-date input {
  border: 0px;
  height: auto;
  background-color: transparent;
  padding-left: 22px !important;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  background: url(../imgs/page/homepage2/calendar.svg) no-repeat left center;
}
.box-calendar-date input:focus {
  border: 0px;
  background: url(../imgs/page/homepage2/calendar.svg) no-repeat left center;
}

.w-68 {
  width: 68%;
}

.noUi-handle {
  cursor: pointer !important;
}

/*end*/
a,
button,
img,
input,
span,
h4 {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@keyframes slideleft {
  10% {
    opacity: 0;
    transform: scale(0);
    right: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 0;
    transform: scale(0);
    right: 100%;
  }
}
[data-loader=spinner] {
  width: 35px;
  height: 35px;
  display: inline-block;
  -webkit-animation: spinner 1.2s infinite ease-in-out;
  -o-animation: spinner 1.2s infinite ease-in-out;
  animation: spinner 1.2s infinite ease-in-out;
  background: url(../imgs/favicon.svg);
  box-shadow: 0 0 10px #fff;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(151, 119, 250, 0.8);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}
@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
@keyframes jump {
  0% {
    transform: translate3d(0, 20%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.jump {
  transform-origin: 0;
  animation: jump 0.5s linear alternate infinite;
}

.blur-move {
  animation: blur-move 10s ease infinite;
}

@keyframes blur-move {
  0% {
    top: 0;
    left: -10%;
  }
  25% {
    top: -45%;
    left: -12%;
  }
  50% {
    top: -45%;
    left: 50%;
  }
  75% {
    top: -45%;
    left: -12%;
  }
  100% {
    top: 0;
    left: -10%;
  }
}
/**
  * Spinning coin fall
  *
  * @author jh3y
*/
@-webkit-keyframes spinning-coin-fall {
  0% {
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(0deg) rotateX(0deg);
    transform: translate(-50%, -50%) perspective(160px) rotateY(0deg) rotateX(0deg);
  }
  10% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(-65deg) rotateX(0deg);
    transform: translate(-50%, -50%) perspective(160px) rotateY(-65deg) rotateX(0deg);
  }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(1440deg) rotateX(0deg);
    transform: translate(-50%, -50%) perspective(160px) rotateY(1440deg) rotateX(0deg);
  }
  90%, 100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(2880deg) rotateX(90deg);
    transform: translate(-50%, -50%) perspective(160px) rotateY(2880deg) rotateX(90deg);
  }
}
@keyframes spinning-coin-fall {
  0% {
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(0deg) rotateX(0deg);
    transform: translate(-50%, -50%) perspective(160px) rotateY(0deg) rotateX(0deg);
  }
  10% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(-65deg) rotateX(0deg);
    transform: translate(-50%, -50%) perspective(160px) rotateY(-65deg) rotateX(0deg);
  }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(1440deg) rotateX(0deg);
    transform: translate(-50%, -50%) perspective(160px) rotateY(1440deg) rotateX(0deg);
  }
  90%, 100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) perspective(160px) rotateY(2880deg) rotateX(90deg);
    transform: translate(-50%, -50%) perspective(160px) rotateY(2880deg) rotateX(90deg);
  }
}
.spinning-coin-fall:before {
  -webkit-animation: spinning-coin-fall 3s infinite;
  animation: spinning-coin-fall 3s infinite;
  background-color: var(--bs-brand-2);
  border-radius: 100%;
  content: "";
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 40px;
}

.page-loader {
  width: 30px;
  height: 30px;
  border: 3px solid #235922;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.page-loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #5edd5b transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*TYPOGRAPHY*/
body {
  color: var(--bs-color-1000);
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
}

.btn {
  font-family: "Urbanist", sans-serif;
  border-radius: 0px;
  padding: 16px 32px;
  font-size: 18px;
  line-height: 1;
  transition: 0.2s;
}
.btn:hover {
  color: var(--bs-brand-2);
}
.btn.btn-dropdown {
  padding: 8px 35px 8px 18px;
  border-radius: 50px;
  border: 1px solid var(--bs-neutral-200);
  color: var(--bs-neutral-1000);
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  background: var(--bs-neutral-200) url(../imgs/template/icons/arrow-down.svg) no-repeat right 15px center;
}
.btn.btn-dropdown:after {
  display: none;
}
.btn.btn-dropdown:hover {
  background-color: var(--bs-neutral-100);
}
.btn.btn-black {
  padding: 8px 18px !important;
  color: var(--bs-neutral-0) !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 700 !important;
  background-color: var(--bs-neutral-1000);
  border-radius: 12px;
  border: 0;
}
.btn.btn-black:hover {
  color: var(--bs-neutral-1000) !important;
  background-color: var(--bs-neutral-100);
}
.btn.btn-small {
  padding: 1px 10px !important;
  font-weight: 500 !important;
  border-radius: 20px !important;
  border: 0 !important;
}
.btn.btn-gray {
  padding: 8px 18px !important;
  color: var(--bs-neutral-1000) !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 700 !important;
  background-color: var(--bs-button-bg-2);
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
}
.btn.btn-gray svg {
  stroke: var(--bs-neutral-1000);
  margin-left: 13px;
}
.btn.btn-gray:hover {
  color: #000000 !important;
  background-color: var(--bs-brand-2);
  border: 1px solid var(--bs-brand-2);
}
.btn.btn-gray:hover svg {
  stroke: var(--bs-neutral-0);
}
.btn.btn-gray2 {
  padding: 12px 22px 12px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--bs-neutral-1000) !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 700 !important;
  background-color: var(--bs-button-bg-2);
  border-radius: 8px;
}
.btn.btn-gray2 svg {
  stroke: var(--bs-neutral-1000);
}
.btn.btn-gray2:hover {
  color: #000000 !important;
  background-color: var(--bs-brand-2);
  border: 1px solid var(--bs-brand-2);
}
.btn.btn-gray2:hover svg {
  stroke: #000;
}
.btn.btn-primary {
  padding: 12px 22px 12px 28px;
  color: #101010 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 700 !important;
  background-color: var(--bs-brand-2);
  border: 0;
  border-radius: 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.btn.btn-primary svg {
  stroke: #101010;
}
.btn.btn-primary:hover {
  color: #101010 !important;
  background-color: var(--bs-neutral-200);
}
.btn.btn-primary:hover svg {
  stroke: #101010;
}
.btn.btn-primary2 {
  padding: 12px 22px 12px 28px;
  color: var(--bs-neutral-1000) !important;
  font-size: 14px !important;
  line-height: 26px !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 700 !important;
  background-color: var(--bs-background-2);
  border: 1px solid var(--bs-brand-2);
  border-radius: 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.btn.btn-primary2 svg path {
  fill: var(--bs-neutral-1000);
}
.btn.btn-primary2:hover {
  color: #101010 !important;
  background-color: var(--bs-brand-2);
}
.btn.btn-primary2:hover svg path {
  fill: #000000;
}
.btn.btn-tag {
  padding: 12px 22px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  background-color: var(--bs-neutral-100);
  border-radius: 12px;
  color: var(--bs-neutral-1000);
}
.btn.btn-brand-2 {
  padding: 16px 32px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  background-color: var(--bs-brand-2);
  border-radius: 12px;
  color: var(--bs-color-1000) !important;
  display: flex;
  align-items: center;
  border: 1px solid var(--bs-brand-2-darken);
  width: fit-content;
}
.btn.btn-brand-2 .first {
  margin-left: 0px;
  margin-right: 8px;
}
.btn.btn-brand-2:hover {
  color: #000000 !important;
  background-color: var(--bs-brand-2-darken);
}
.btn.btn-mode {
  padding: 11px 18px;
  border-radius: 12px;
  background-color: var(--bs-neutral-100);
  color: var(--bs-neutral-1000);
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.btn.btn-mode img {
  display: block;
  vertical-align: middle;
}
.btn.btn-dropdown-search {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  border: 0px;
  color: var(--bs-neutral-1000);
  background-color: transparent;
  position: relative;
  width: auto;
  text-align: left;
  padding: 5px 40px 5px 5px;
  position: relative;
}
.btn.btn-dropdown-search:after {
  content: "";
  height: 10px;
  width: 14px;
  background-color: transparent;
  background-image: url(../imgs/page/homepage2/dropdown.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0px;
}
.btn.btn-white {
  background-color: var(--bs-neutral-0);
  border: 1px solid var(--bs-border-color);
  border-radius: 12px;
  min-width: 140px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  color: var(--bs-neutral-1000);
}
.btn.btn-white:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.btn.btn-white-md {
  padding: 10px 21px 10px 21px;
  background-color: var(--bs-neutral-0);
  border: 1px solid var(--bs-border-color);
  border-radius: 38px;
  min-width: auto;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: var(--bs-neutral-1000);
}
.btn.btn-white-md:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.btn.btn-white-md img {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 1px;
}
.btn.btn-signin {
  padding: 11px 18px;
  background-color: transparent;
  border: 0;
  border-radius: 12px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  color: #fff;
}
.btn.btn-signin:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
  background-color: var(--bs-brand-2-darken) !important;
  color: var(--bs-neutral-900) !important;
}
.btn.btn-signin:hover svg path {
  fill: var(--bs-neutral-900);
}
.btn.btn-label-tag {
  padding: 8px 18px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  background-color: var(--bs-background-3);
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
  color: var(--bs-neutral-1000);
}
.btn.btn-label-tag:hover {
  background-color: var(--bs-neutral-1000) !important;
  color: var(--bs-neutral-0);
}
.btn.btn-login {
  background-color: var(--bs-neutral-100);
  border-radius: 8px;
  padding: 14px;
  min-width: 56px;
  color: var(--bs-neutral-1000);
  font-size: 14px;
  line-height: 22px;
  border: 1px solid var(--bs-neutral-200);
  height: 56px;
}
.btn.btn-login img {
  display: block;
  margin: auto;
}
.btn.btn-login:hover {
  background-color: var(--bs-neutral-900);
  color: var(--bs-neutral-0);
  border-color: var(--bs-neutral-900);
}
.btn.btn-google {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}
.btn.btn-google img {
  margin-right: 10px;
  margin-left: 0px;
}
.btn.btn-sort {
  padding: 8px 10px;
}
.btn.btn-sort svg {
  stroke: var(--bs-neutral-1000);
}
.btn.btn-sort:hover svg {
  stroke: var(--bs-brand-2);
}
.btn.btn-share {
  padding: 9px 18px;
  border: 1px solid var(--bs-border-color);
  border-radius: 50px;
  background-color: var(--bs-background-card);
  color: var(--bs-button-text-2);
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}
.btn.btn-share svg {
  fill: var(--bs-neutral-1000);
  margin-right: 6px;
}
.btn.btn-wishlish {
  padding: 9px 18px;
  border-radius: 50px;
  background-color: var(--bs-button-bg-2);
  color: var(--bs-button-text-2);
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}
.btn.btn-wishlish svg {
  fill: var(--bs-neutral-1000);
  margin-right: 6px;
}
.btn.btn-book {
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  background-color: var(--bs-brand-2);
  color: var(--bs-neutral-1000);
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.btn-book svg {
  stroke: var(--bs-neutral-1000);
  margin-left: 10px;
}

@-webkit-keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
#scrollUp {
  position: relative;
  z-index: 10;
  display: inline-block;
  height: 40px;
  border-radius: 50%;
  padding: 0px;
  width: 40px;
  color: #ffffff;
  right: 30px;
  bottom: 30px;
  text-align: center;
  z-index: 999 !important;
  border: 0;
  background: var(--bs-neutral-900);
  line-height: 40px;
}
#scrollUp svg {
  fill: var(--bs-brand-2);
  height: 14px;
  position: relative;
  z-index: 123;
}

#scrollUp {
  -webkit-animation: scale-up-center 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  animation: scale-up-center 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
}

@keyframes pulse-border {
  0% {
    transform: translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*button switch*/
.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 30px;
  vertical-align: middle;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bs-neutral-1000);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 5px;
  bottom: 5px;
  background: linear-gradient(236deg, #22d1ee 0%, #c5ff41 100%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #70f46d;
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--bs-brand-1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/*End button Switch On Off*/
.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

.avatar-xs {
  width: 1.5rem;
  height: 1.5rem;
}

.avatar-sm {
  width: 2rem;
  height: 2rem;
}

.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xxl {
  width: 7rem;
  height: 7rem;
}

.icon-xl {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
}

.icon-shape {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

.icon-xxs {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
}

.icon-xs {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.icon-sm {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}

.icon-md {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.icon-lg {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.icon-xl {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
}

.icon-xxl {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}

.icon-xxxl {
  width: 7rem;
  height: 7rem;
  line-height: 7rem;
}

.icon_50 {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.icon_65 {
  width: 65px;
  height: 65px;
  line-height: 65px;
}

.icon_70 {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.icon_115 {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.icon_160 {
  width: 160px;
  height: 160px;
  line-height: 160px;
}

.icon_150 {
  width: 150px;
  height: 150px;
  line-height: 150px;
}

.icon_140 {
  width: 140px;
  height: 140px;
  line-height: 140px;
}

.icon_80 {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.cb-container {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  background-image: url(../imgs/template/icons/check.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.cb-container input:checked ~ .checkmark {
  background-image: url(../imgs/template/icons/checked.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.cb-container .text-small {
  color: var(--bs-neutral-500);
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}

.cb-container input:checked ~ .text-small {
  color: var(--bs-neutral-1000);
  font-weight: 500;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cb-container input:checked ~ .checkmark:after {
  display: block;
}

.cb-container input:checked ~ .text-lbl {
  color: neutral-500;
}

/*COMPONENTS -> FORM*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
}

input {
  border: 1px solid #ececec;
  border-radius: 10px;
  height: 50px;
  box-shadow: none;
  padding-left: 20px;
  font-size: 16px;
  width: 100%;
}
input:focus {
  background: transparent;
  border: 1px solid var(--bs-neutral-300);
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #ececec;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  color: var(--bs-neutral-1000);
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 16px;
}

textarea {
  border: 1px solid #ececec;
  border-radius: 10px;
  height: 50px;
  box-shadow: none;
  padding: 10px 10px 10px 20px;
  font-size: 16px;
  width: 100%;
  min-height: 250px;
}
textarea:focus {
  background: transparent;
  border: 1px solid var(--bs-neutral-300);
}

/*contact form*/
.contact-from-area .contact-form-style button {
  font-size: 18px;
  font-weight: 500;
  padding: 20px 40px;
  color: #ffffff;
  border: none;
  background-color: var(--bs-brand-1);
  border-radius: 10px;
  font-family: "Urbanist", sans-serif;
}
.contact-from-area .contact-form-style button:hover {
  background-color: #8acfff !important;
}

.form-group {
  margin-bottom: 12px;
}
.form-group label {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
  margin-bottom: 16px;
}
.form-group input {
  background: var(--bs-neutral-100);
  border: 1px solid var(--bs-neutral-200);
  height: 56px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 15px 15px 12px 15px;
  font-size: 16px;
  width: 100%;
}
.form-group input:focus {
  background: transparent;
  border-color: var(--bs-neutral-300);
}
.form-group input.form-icons {
  padding-left: 42px;
}

label {
  margin-bottom: 5px;
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radio-container input:checked ~ .checkmark {
  background-color: var(--bs-color-2);
}
.radio-container input:checked ~ .checkmark:after {
  display: block;
}
.radio-container:hover input ~ .checkmark {
  background-color: var(--bs-neutral-300);
}
.radio-container .checkmark:after {
  left: 7px;
  top: 5px;
  width: 8px;
  height: 10px;
  border: solid var(--bs-neutral-1000);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.shadow-2 {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}

.card-journey-big {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
  max-width: 560px;
  width: 100%;
}
.card-journey-big:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.card-journey-big .card-image {
  position: relative;
  overflow: hidden;
  height: 326px;
}
.card-journey-big .card-image .wish {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.8196078431);
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  z-index: 12;
}
.card-journey-big .card-image .wish svg {
  stroke: var(--bs-neutral-1000);
}
.card-journey-big .card-image .wish:hover svg {
  stroke: var(--bs-brand-2);
  fill: var(--bs-brand-2);
}
.card-journey-big .card-image img {
  display: block;
  width: 100%;
}
.card-journey-big .card-info {
  padding: 43px 32px 32px 32px;
  background-color: var(--bs-neutral-0);
  border-radius: 12px;
  position: relative;
  margin-top: -40px;
}
.card-journey-big .card-info .card-rating {
  position: absolute;
  top: -19px;
  z-index: 13;
  left: 32px;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-journey-big .card-info .card-rating .card-left .lightning {
  background-color: var(--bs-brand-2);
  display: inline-block;
  padding: 7px 19px 7px 39px;
  border-radius: 50px;
  color: var(--bs-color-1000);
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  background-image: url(../imgs/template/icons/lightning.svg);
  background-repeat: no-repeat;
  background-position: left 15px center;
}
.card-journey-big .card-info .card-rating .card-right .rating {
  display: inline-block;
  padding: 6px 16px 6px 30px;
  background-color: var(--bs-neutral-0);
  border-radius: 50px;
  color: var(--bs-neutral-1000);
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  border: 1px solid var(--bs-border-color);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  background-image: url(../imgs/template/icons/star.svg);
  background-repeat: no-repeat;
  background-position: left 12px center;
}
.card-journey-big .card-info .card-title a {
  color: var(--bs-neutral-1000);
}
.card-journey-big .card-info .card-program {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.card-journey-big .card-info .card-program .duration .card-price {
  display: flex;
  align-items: flex-end;
}
.card-journey-big .card-info .card-program .duration .card-price h6 {
  margin-right: 6px;
}

.card-journey-small {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
  margin-bottom: 24px;
}
.card-journey-small:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.card-journey-small .card-image {
  position: relative;
  overflow: hidden;
}
.card-journey-small .card-image .wish {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.8196078431);
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  z-index: 12;
}
.card-journey-small .card-image .wish svg {
  stroke: var(--bs-neutral-1000);
}
.card-journey-small .card-image .wish:hover svg {
  stroke: var(--bs-brand-2);
  fill: var(--bs-brand-2);
}
.card-journey-small .card-image .label {
  position: absolute;
  top: 22px;
  left: 25px;
  border-radius: 50px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: var(--bs-brand-2);
  z-index: 12;
  padding: 5px 22px;
  background-color: var(--bs-background-card);
}
.card-journey-small .card-image .label.bestsale {
  color: var(--bs-success);
}
.card-journey-small .card-image .label.saleoff {
  color: var(--bs-brand-2);
}
.card-journey-small .card-image img {
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
  min-width: 100%;
}
.card-journey-small .card-info {
  padding: 38px 30px;
  background-color: var(--bs-neutral-0);
  border-radius: 12px;
  position: relative;
  margin-top: -40px;
}
.card-journey-small .card-info .card-facilities {
  border-top: 1px solid var(--bs-border-color);
  padding-top: 25px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-journey-small .card-info .card-facilities .item-facilities {
  width: 50%;
}
.card-journey-small .card-info .card-facilities .item-facilities p {
  background-repeat: no-repeat;
  background-position: left center;
  padding: 0px 0px 0px 30px;
  margin-bottom: 13px;
}
.card-journey-small .card-info .card-facilities .item-facilities p.speed {
  background-image: url(../imgs/page/homepage8/speed.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.gear {
  background-image: url(../imgs/page/homepage8/engineering.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.fuel {
  background-image: url(../imgs/page/homepage8/fuel.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.seats {
  background-image: url(../imgs/page/homepage8/seat.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.room {
  background-image: url(../imgs/page/homepage9/room.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.pax {
  background-image: url(../imgs/page/hotel/pax.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.size {
  background-image: url(../imgs/page/homepage9/size.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.bed {
  background-image: url(../imgs/page/homepage9/bed.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.bathroom {
  background-image: url(../imgs/page/homepage9/bathroom.svg);
}
.card-journey-small .card-info .card-facilities .item-facilities p.parkin {
  background-image: url(../imgs/page/property/parking.png);
}
.card-journey-small .card-info .card-facilities .item-facilities p.pet {
  background-image: url(../imgs/page/property/pet.png);
}
.card-journey-small .card-info .card-rating {
  position: absolute;
  top: -14px;
  z-index: 13;
  left: 32px;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-journey-small .card-info .card-rating .card-left .lightning {
  background-color: var(--bs-brand-2);
  display: inline-block;
  padding: 7px 19px 7px 39px;
  border-radius: 50px;
  color: var(--bs-neutral-1000);
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  background-image: url(../imgs/template/icons/lightning.svg);
  background-repeat: no-repeat;
  background-position: left 15px center;
}
.card-journey-small .card-info .card-rating .card-right .rating {
  display: inline-block;
  padding: 2px 14px 2px 28px;
  background-color: var(--bs-neutral-0);
  border-radius: 8px;
  color: var(--bs-neutral-1000);
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  border: 1px solid var(--bs-border-color);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  background-image: url(../imgs/template/icons/star.svg);
  background-repeat: no-repeat;
  background-position: left 10px center;
}
.card-journey-small .card-info .card-title {
  margin-bottom: 8px;
}
.card-journey-small .card-info .card-title a {
  color: var(--bs-neutral-1000);
}
.card-journey-small .card-info .card-title a:hover {
  color: var(--bs-link-hover-color) !important;
}
.card-journey-small .card-info .card-program {
  position: relative;
}
.card-journey-small .card-info .card-program .card-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.card-journey-small .card-info .card-program .card-location .text-location {
  background: url(../imgs/template/icons/location.svg) no-repeat left center;
  padding-left: 20px;
}
.card-journey-small .card-info .card-program .card-location .text-star img {
  margin-left: 2px;
}
.card-journey-small .card-info .card-program .card-facitlities {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -15px;
  padding-top: 27px;
  border-top: 1px solid var(--bs-border-color);
  margin-bottom: 15px;
}
.card-journey-small .card-info .card-program .card-facitlities .card-miles,
.card-journey-small .card-info .card-program .card-facitlities .card-gear,
.card-journey-small .card-info .card-program .card-facitlities .card-fuel,
.card-journey-small .card-info .card-program .card-facitlities .card-seat {
  width: 50%;
  padding: 0px 0px 0px 30px;
  margin-bottom: 13px;
  color: #000000;
}
.card-journey-small .card-info .card-program .card-facitlities .card-miles {
  background: url(../imgs/template/icons/mile.svg) no-repeat left center;
}
.card-journey-small .card-info .card-program .card-facitlities .card-gear {
  background: url(../imgs/template/icons/automatic.svg) no-repeat left center;
}
.card-journey-small .card-info .card-program .card-facitlities .card-fuel {
  background: url(../imgs/template/icons/fuel.svg) no-repeat left center;
}
.card-journey-small .card-info .card-program .card-facitlities .card-seat {
  background: url(../imgs/template/icons/seat.svg) no-repeat left center;
}
.card-journey-small .card-info .card-program .duration {
  margin-bottom: 45px;
}
.card-journey-small .card-info .card-program .duration .card-price {
  display: flex;
  align-items: flex-end;
}
.card-journey-small .card-info .card-program .duration .card-price h6 {
  margin-right: 6px;
}
.card-journey-small .card-info .card-program .card-duration-tour {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.card-journey-small .card-info .card-program .icon-duration {
  padding: 0px 20px 0px 22px;
  background: url(../imgs/template/icons/duration.svg) no-repeat left center;
}
.card-journey-small .card-info .card-program .icon-guest {
  padding: 0px 0px 0px 22px;
  background: url(../imgs/template/icons/guest.svg) no-repeat left center;
}
.card-journey-small .card-info .card-program .endtime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-journey-small .card-info .card-program .endtime .card-price {
  display: flex;
  align-items: flex-end;
}
.card-journey-small .card-info .card-program .endtime .card-price h6 {
  margin-right: 6px;
}
.card-journey-small.card-journey-small-type-2 .card-info .card-rating .sign {
  background-image: url(../imgs/page/homepage5/sign.svg);
  background-position: left 15px center;
  padding-left: 35px;
}
.card-journey-small.card-journey-small-type-2 .card-image {
  height: 246px;
}
.card-journey-small.card-journey-small-type-2 .card-image img {
  min-width: 100%;
}
.card-journey-small.card-journey-small-type-2 .card-links {
  margin-top: 24px;
}
.card-journey-small.card-journey-small-type-2 .card-links ul {
  display: flex;
  align-items: center;
  margin: 0px -10px;
  flex-wrap: wrap;
  list-style: disc;
  padding-left: 30px;
}
.card-journey-small.card-journey-small-type-2 .card-links ul li {
  width: 50%;
  padding: 0px 10px 0px 0px;
  margin-bottom: 18px;
  color: var(--bs-neutral-500);
}
.card-journey-small.card-journey-small-type-2 .card-links ul li:hover {
  color: var(--bs-neutral-1000);
}
.card-journey-small.card-journey-small-type-2 .card-links ul li a {
  color: var(--bs-neutral-500);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
.card-journey-small.card-journey-small-type-2 .card-links ul li a:hover {
  color: var(--bs-neutral-1000);
}
.card-journey-small.card-journey-small-type-3 {
  border: 0px;
}
.card-journey-small.card-journey-small-type-3 .card-info {
  margin-top: 0px;
  padding: 25px 17px 10px 17px;
}
.card-journey-small.card-journey-small-type-3 .card-image {
  border-radius: 12px;
}
.card-journey-small.card-grid-real .card-info {
  border: 1px solid var(--bs-border-color);
  border-radius: 12px;
  margin: -40px -1px -1px -1px;
}
.card-journey-small.card-grid-real .card-info .card-location {
  margin-bottom: 27px;
}
.card-journey-small.card-grid-car {
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-neutral-0) !important;
}
.card-journey-small.card-grid-car .card-info {
  border: 1px solid var(--bs-border-color);
  border-radius: 12px;
  margin: 0px -1px -1px -1px;
}
.card-journey-small.card-grid-car .card-info .card-location {
  margin-bottom: 27px;
}
.card-journey-small.card-grid-car .card-image {
  line-height: 326px;
}
.card-journey-small.card-grid-car .card-image img {
  max-width: 100%;
  height: auto;
  min-width: auto;
  margin: auto;
  display: inline-block;
  vertical-align: middle;
}

.card-popular {
  padding: 15px 16px 19px 16px;
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
  margin-bottom: 24px;
}
.card-popular .card-image {
  position: relative;
  overflow: hidden;
  height: auto;
  border: 1px solid var(--bs-border-color);
  width: 100%;
  border-radius: 10px;
  margin-bottom: 18px;
}
.card-popular .card-image img {
  display: block;
  width: 100%;
}
.card-popular .card-info .card-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: var(--bs-neutral-1000);
  margin-bottom: 4px;
  display: block;
}
.card-popular .card-info .card-title:hover {
  color: var(--bs-link-hover-color) !important;
}
.card-popular .card-info .card-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-popular .card-info .card-meta .meta-links a {
  color: var(--bs-neutral-500);
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  padding: 4px 11px;
  border-radius: 18px;
  border: 0.6px solid var(--bs-border-color);
  background: var(--Button-button-bg-2, #f2f4f6);
}
.card-popular .card-info .card-meta .card-button a {
  display: inline-block;
  height: 27px;
  width: 27px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  background-color: var(--bs-neutral-100);
}
.card-popular .card-info .card-meta .card-button a svg {
  stroke: var(--bs-neutral-1000);
}
.card-popular .card-info .card-meta .card-button a:hover {
  background-color: var(--bs-brand-2);
}
.card-popular .card-info .card-meta .card-button a:hover svg {
  stroke: #000000;
}
.card-popular.card-top-destination {
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.card-popular.card-top-destination:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.card-popular.card-top-destination .card-image {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 18px;
  margin-bottom: 0px;
}
.card-popular.card-top-destination .card-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.card-popular.card-top-destination .card-info {
  position: relative;
  width: 100%;
}
.card-popular.card-top-destination .card-info .card-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}
.card-popular.card-top-destination .card-info .card-meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.card-popular.card-top-destination .card-info .card-meta .meta-links {
  margin-bottom: 10px;
}
.card-popular.card-top-destination .card-info .card-meta .meta-links .text-tour {
  display: inline-block;
  padding-left: 14px;
  background: url(../imgs/page/homepage6/flag.svg) no-repeat left center;
}
.card-popular.card-top-destination .card-info .card-meta .meta-links .text-property {
  display: inline-block;
  padding-left: 18px;
  background: url(../imgs/page/homepage9/property.svg) no-repeat left center;
}
.card-popular.card-top-destination .card-info .card-meta .meta-links .text-post {
  display: inline-block;
  padding-left: 18px;
  background: url(../imgs/page/blog/post.svg) no-repeat left center;
}

.card-popular-2 {
  padding: 18px;
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
  margin-bottom: 24px;
  background-color: var(--bs-button-bg-2);
}
.card-popular-2 .card-info h6 {
  padding: 10px;
  margin: 0px;
}
.card-popular-2 .card-meta {
  margin-top: 5px;
  padding: 12px 26px;
  background-color: var(--bs-button-bg);
  color: var(--bs-neutral-0);
  border-radius: 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-popular-2 .card-meta .meta-links {
  width: 75%;
  line-height: 22px;
  color: var(--bs-color-white);
}
.card-popular-2 .card-meta .card-button {
  width: 45%;
  text-align: right;
}
.card-popular-2 .card-meta .card-button a {
  display: inline-block;
  height: 27px;
  width: 27px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  background-color: var(--bs-neutral-0);
}
.card-popular-2 .card-meta .card-button a svg {
  stroke: var(--bs-neutral-1000);
}
.card-popular-2.card-popular-3 {
  background-color: var(--bs-brand-2);
}
.card-popular-2.card-popular-3 .card-info h6 {
  color: var(--bs-neutral-1000) !important;
}

.card-why-choose-us {
  text-align: center;
  position: relative;
  border: 1px solid var(--bs-border-color);
  border-radius: 12px;
  padding: 24px 32px;
  margin-bottom: 24px;
  background-color: var(--bs-brand-2);
}
.card-why-choose-us:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.card-why-choose-us .card-image {
  margin-bottom: 11px;
}
.card-why-choose-us .card-info h6 {
  margin-bottom: 8px;
  color: var(--bs-neutral-1000);
}
.card-why-choose-us .card-info p {
  color: var(--bs-neutral-600);
}
.card-why-choose-us.card-why-choose-us-type-2 {
  text-align: left;
  background-image: url(../imgs/page/homepage1/fast-booking.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-color: var(--bs-border-color);
  min-height: 268px;
}
.card-why-choose-us.card-why-choose-us-type-3 {
  text-align: left;
  background-image: url(../imgs/page/homepage1/best-price.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-color: var(--bs-border-color);
  min-height: 268px;
  padding-right: 80px;
  background-color: var(--bs-brand-2);
}

.card-flight {
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  max-width: 612px;
  width: 100%;
  min-width: 612px;
  margin-bottom: 24px;
}
.card-flight .card-image {
  max-width: 263px;
  width: 100%;
  height: 311px;
}
.card-flight .card-image .wish {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.8196078431);
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  z-index: 12;
}
.card-flight .card-image .wish svg {
  stroke: var(--bs-neutral-1000);
}
.card-flight .card-image .wish:hover svg {
  stroke: var(--bs-brand-2);
  fill: var(--bs-brand-2);
}
.card-flight .card-image img {
  display: block;
  height: auto;
  min-height: 100%;
  max-width: none;
  min-width: 100%;
  max-height: 100%;
}
.card-flight .card-info {
  padding: 38px 31px;
  width: calc(100% + 30px);
  margin-left: -30px;
  background-color: var(--bs-neutral-0);
  border-radius: 12px;
  height: 311px;
}
.card-flight .card-info .card-date {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.card-flight .card-info .card-date .date-1 {
  color: var(--bs-neutral-500);
  background: url(../imgs/template/icons/clock.svg) no-repeat left center;
  display: inline-block;
  padding: 0px 0px 0px 20px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
.card-flight .card-info .card-date .line {
  display: inline-block;
  margin: 0px 16px;
  height: 1px;
  width: 18px;
  background: url(../imgs/template/icons/line.png) no-repeat center;
}
.card-flight .card-info .card-route {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.card-flight .card-info .card-route .icon-route {
  display: inline-block;
  margin: 0px 18px;
  height: 24px;
  width: 24px;
  background: url(../imgs/template/icons/icon-route.svg) no-repeat center;
}
.card-flight .card-info .card-price {
  display: flex;
  align-items: center;
}
.card-flight .card-info .card-price .card-price-1 {
  position: relative;
  padding: 0px 42px 0px 42px;
}
.card-flight .card-info .card-price .card-price-1 p {
  color: var(--bs-neutral-600);
  margin-bottom: 8px;
}
.card-flight .card-info .card-price .card-price-1::before {
  content: "";
  height: 42px;
  width: 1px;
  background-color: var(--bs-neutral-200);
  position: absolute;
  top: 15px;
  right: 0px;
}
.card-flight .card-info .card-price .card-price-1:first-child {
  padding-left: 0px;
}
.card-flight .card-info .card-price .card-price-1:last-child {
  padding-right: 0px;
}
.card-flight .card-info .card-price .card-price-1:last-child::before {
  display: none;
}
.card-flight .card-info .card-meta {
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-flight.card-hotel .card-info {
  padding: 52px 48px;
  background-color: var(--bs-neutral-0);
  border-radius: 12px;
  position: relative;
  z-index: 2;
}
.card-flight.card-hotel .card-info .card-title {
  margin-bottom: 8px;
  margin-top: 15px;
}
.card-flight.card-hotel .card-info .card-title a {
  color: var(--bs-neutral-1000);
}
.card-flight.card-hotel .card-info .card-title a:hover {
  color: var(--bs-link-hover-color) !important;
}
.card-flight.card-hotel .card-info .card-program {
  position: relative;
}
.card-flight.card-hotel .card-info .card-program .card-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}
.card-flight.card-hotel .card-info .card-program .card-location .text-location {
  background: url(../imgs/template/icons/location.svg) no-repeat left center;
  padding-left: 20px;
}
.card-flight.card-hotel .card-info .card-program .card-location .text-star img {
  margin-left: 2px;
}
.card-flight.card-hotel .card-info .card-program .card-facitlities {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -15px;
  padding-top: 27px;
  border-top: 1px solid var(--bs-border-color);
  margin-bottom: 15px;
}
.card-flight.card-hotel .card-info .card-program .card-facitlities .card-miles,
.card-flight.card-hotel .card-info .card-program .card-facitlities .card-gear,
.card-flight.card-hotel .card-info .card-program .card-facitlities .card-fuel,
.card-flight.card-hotel .card-info .card-program .card-facitlities .card-seat {
  width: 50%;
  padding: 0px 0px 0px 30px;
  margin-bottom: 13px;
}
.card-flight.card-hotel .card-info .card-program .card-facitlities .card-miles {
  background: url(../imgs/page/homepage2/mile.svg) no-repeat left center;
}
.card-flight.card-hotel .card-info .card-program .card-facitlities .card-gear {
  background: url(../imgs/page/homepage2/automatic.svg) no-repeat left center;
}
.card-flight.card-hotel .card-info .card-program .card-facitlities .card-fuel {
  background: url(../imgs/page/homepage2/fuel.svg) no-repeat left center;
}
.card-flight.card-hotel .card-info .card-program .card-facitlities .card-seat {
  background: url(../imgs/page/homepage2/seat.svg) no-repeat left center;
}
.card-flight.card-hotel .card-info .card-program .duration {
  margin-bottom: 45px;
}
.card-flight.card-hotel .card-info .card-program .duration .card-price {
  display: flex;
  align-items: flex-end;
}
.card-flight.card-hotel .card-info .card-program .duration .card-price h6 {
  margin-right: 6px;
}
.card-flight.card-hotel .card-info .card-program .card-duration-tour {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.card-flight.card-hotel .card-info .card-program .icon-duration {
  padding: 0px 20px 0px 22px;
  background: url(../imgs/template/icons/duration.svg) no-repeat left center;
}
.card-flight.card-hotel .card-info .card-program .icon-guest {
  padding: 0px 0px 0px 22px;
  background: url(../imgs/template/icons/guest.svg) no-repeat left center;
}
.card-flight.card-hotel .card-info .card-program .endtime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-flight.card-hotel .card-info .card-program .endtime .card-price {
  display: flex;
  align-items: flex-end;
}
.card-flight.card-hotel .card-info .card-program .endtime .card-price h6 {
  margin-right: 6px;
}
.card-flight.card-hotel .card-info .card-hotel-2-col {
  display: flex;
  align-items: center;
}
.card-flight.card-hotel .card-info .card-hotel-2-col .card-program {
  padding-right: 30px;
}
.card-flight.card-hotel .card-info .card-hotel-2-col .card-hotel-price {
  min-width: 206px;
}
.card-flight.card-hotel .card-info .card-hotel-2-col .card-hotel-price .box-hotel-price {
  border: 1px solid var(--bs-border-color);
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  background-color: var(--bs-background-9);
}
.card-flight.card-hotel .card-info .card-hotel-2-col .card-hotel-price .box-hotel-price .price-throught {
  text-decoration: line-through;
  margin-bottom: 5px;
}
.card-flight.card-hotel .card-info .card-hotel-2-col .card-hotel-price .box-hotel-price .price-main {
  margin-bottom: 5px;
}
.card-flight.card-hotel .card-info .card-hotel-2-col .card-hotel-price .box-hotel-price .card-button {
  margin-top: 25px;
}

.card-walking {
  border-radius: 8px;
  padding: 55px;
  background-image: url(../imgs/page/homepage1/walking.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 506px;
  height: 406px;
}
.card-walking .card-info h4 {
  color: var(--bs-neutral-0);
  margin-bottom: 32px;
}

.card-advanture {
  border-radius: 8px;
  padding: 55px;
  background-image: url(../imgs/page/homepage1/advance.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 612px;
  height: 406px;
  text-align: right;
}
.card-advanture .card-info {
  display: inline-block;
  text-align: left;
  max-width: 220px;
}
.card-advanture .card-info h4 {
  color: var(--bs-neutral-0);
  margin-bottom: 32px;
}

.card-vacation {
  width: 426px;
  background-image: url(../imgs/page/homepage1/vacation.png);
}
.card-vacation .card-info {
  max-width: 180px;
}
.card-vacation .card-info p,
.card-vacation .card-info h5 {
  color: var(--bs-neutral-0);
  margin-bottom: 4px;
}

.card-walking-2 {
  background-image: url(../imgs/page/homepage1/walking2.png);
}

.card-news {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  width: 100%;
}
.card-news:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.card-news:hover .icon-shape-arrow {
  background-color: var(--bs-brand-2) !important;
  border: 0 !important;
}
.card-news .card-image {
  position: relative;
  overflow: hidden;
  height: auto;
}
.card-news .card-image .label {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 50px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: var(--bs-neutral-1000);
  z-index: 12;
  padding: 8px 18px;
  background-color: var(--bs-neutral-0);
}
.card-news .card-image .wish {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.8196078431);
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  z-index: 12;
}
.card-news .card-image .wish svg {
  stroke: var(--bs-neutral-1000);
}
.card-news .card-image .wish:hover svg {
  stroke: var(--bs-brand-2-darken);
  fill: var(--bs-brand-2-darken);
}
.card-news .card-image img {
  display: block;
  width: 100%;
}
.card-news .card-info {
  padding: 30px 31px;
  background-color: var(--bs-neutral-0);
  border-radius: 12px;
  position: relative;
  margin-top: -40px;
}
.card-news .card-info .card-meta {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-news .card-info .card-meta .post-date {
  display: inline-block;
  padding: 0px 16px 0px 20px;
  background: url(../imgs/template/icons/calendar.svg) no-repeat left center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
}
.card-news .card-info .card-meta .post-time {
  display: inline-block;
  padding: 0px 16px 0px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  background: url(../imgs/template/icons/time.svg) no-repeat left center;
}
.card-news .card-info .card-meta .post-comment {
  display: inline-block;
  padding: 0px 0px 0px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  background: url(../imgs/template/icons/comment.svg) no-repeat left center;
}
.card-news .card-info .card-title {
  margin-bottom: 34px;
}
.card-news .card-info .card-title a {
  color: var(--bs-neutral-1000);
}
.card-news .card-info .card-title a:hover {
  color: var(--bs-link-hover-color) !important;
}
.card-news .card-info .card-program {
  position: relative;
}
.card-news .card-info .card-program .card-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}
.card-news .card-info .card-program .card-location .text-location {
  background: url(../imgs/template/icons/location.svg) no-repeat left center;
  padding-left: 20px;
}
.card-news .card-info .card-program .card-location .text-star img {
  margin-left: 2px;
}
.card-news .card-info .card-program .endtime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-news .card-info .card-program .endtime .card-author {
  display: flex;
  align-items: center;
}
.card-news .card-info .card-program .endtime .card-author img {
  margin-right: 10px;
}

.card-testimonial {
  max-width: 470px;
  width: 100%;
  padding: 34px 40px;
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-neutral-0);
  border-radius: 12px;
}
.card-testimonial .card-top {
  border-bottom: 1px solid var(--bs-border-color);
  padding-bottom: 23px;
  margin-bottom: 31px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.card-testimonial .card-top .card-author {
  display: flex;
  align-items: center;
}
.card-testimonial .card-top .card-author .card-image {
  width: 64px;
  height: 64px;
  margin-right: 12px;
}
.card-testimonial .card-top .card-author .card-image img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: block;
}
.card-testimonial .card-top .card-rate img {
  margin-left: 2px;
}

.card-place {
  position: relative;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.card-place .card-image {
  min-width: 168px;
  height: 130px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 168px;
  border-radius: 16px 0 0 16px;
}
.card-place .card-image img {
  display: block;
  height: 100%;
}
.card-place .card-info {
  border-radius: 16px;
  border: 1px solid var(--bs-border-color);
  padding: 17px;
  background-color: var(--bs-neutral-0);
  width: calc(100% + 21px);
  margin-left: -21px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  min-height: 130px;
}
.card-place .card-info .card-info-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.card-place .card-info .card-info-top .card-rate {
  color: var(--bs-neutral-500);
}
.card-place .card-info .card-info-top .card-rate img {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
.card-place .card-info .card-info-top a {
  color: var(--bs-neutral-1000);
}
.card-place .card-info .card-info-top a:hover {
  color: var(--bs-brand-2);
}
.card-place .card-info .card-info-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 5px;
}
.card-place .card-info .card-info-bottom p {
  width: 70%;
  padding-right: 20px;
}
.card-place .card-info .card-info-bottom a {
  display: inline-block;
  height: 27px;
  width: 27px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  background-color: var(--bs-neutral-100);
}
.card-place .card-info .card-info-bottom a svg {
  stroke: var(--bs-neutral-1000);
}
.card-place .card-info .card-info-bottom a:hover {
  background-color: var(--bs-neutral-1000);
}
.card-place .card-info .card-info-bottom a:hover svg {
  stroke: var(--bs-neutral-0);
}

.card-home-link {
  background-color: #ffffff;
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}
.card-home-link .card-icon {
  width: 45px;
  height: 45px;
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  text-align: center;
  line-height: 41px !important;
  background-color: var(--bs-neutral-100);
  margin-right: 12px;
  min-width: 45px;
  padding: 0px !important;
}
.card-home-link .card-icon img {
  display: inline-block;
  vertical-align: middle;
}
.card-home-link .card-info a {
  padding: 0px !important;
}
.card-home-link .card-info a:hover {
  color: var(--bs-warning) !important;
}
.card-home-link:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.card-home-link:hover .card-icon {
  background-color: var(--bs-brand-2);
}

.card-banner {
  position: relative;
}
.card-banner .card-image {
  position: relative;
}
.card-banner .card-image img {
  display: block;
  border-radius: 16px;
}
.card-banner .card-info {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  z-index: 12;
  padding: 36px;
}
.card-banner .card-info h4 {
  margin-bottom: 29px;
}
.card-banner .card-info h5 {
  margin-bottom: 51px;
}

.card-why-travel {
  position: relative;
  background-color: var(--bs-background-2);
  border-radius: 16px;
  padding: 35px 29px;
  margin-bottom: 35px;
  text-align: center;
}
.card-why-travel .card-image {
  background-color: var(--bs-neutral-0);
  border-radius: 16px;
  height: 64px;
  width: 64px;
  text-align: center;
  line-height: 64px;
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
  margin: auto;
  margin-bottom: 11px;
}
.card-why-travel .card-image img {
  display: inline-block;
  vertical-align: middle;
}
.card-why-travel .card-info .card-title {
  display: block;
  margin-bottom: 11px;
  color: var(--bs-neutral-1000);
}
.card-why-travel .card-info .card-title:hover {
  color: var(--bs-brand-2);
}
.card-why-travel .card-info .card-desc {
  margin-bottom: 11px;
}
.card-why-travel .card-info .card-link {
  color: var(--bs-neutral-1000);
}
.card-why-travel .card-info .card-link svg {
  stroke: var(--bs-neutral-1000);
  margin-left: 5px;
}
.card-why-travel .card-info .card-link:hover {
  color: var(--bs-brand-2);
}
.card-why-travel .card-info .card-link:hover svg {
  stroke: var(--bs-brand-2);
}
.card-why-travel.card-why-travel-2 {
  text-align: left;
  display: flex;
  align-items: flex-start;
}
.card-why-travel.card-why-travel-2:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.card-why-travel.card-why-travel-2 .card-image {
  min-width: 64px;
  height: 64px;
  margin: 0px;
  margin-right: 16px;
}
.card-why-travel.card-why-travel-2 .card-info .card-link svg {
  stroke: #afafaf;
}

.card-popular-destination {
  position: relative;
  text-align: center;
}
.card-popular-destination .card-image {
  display: table;
  margin: auto;
  overflow: hidden;
  max-width: 100px;
  border-radius: 1350px;
  margin-bottom: 16px;
}
.card-popular-destination .card-image img {
  display: block;
  height: 100%;
}
.card-popular-destination .card-info a {
  color: var(--bs-neutral-1000);
}
.card-popular-destination .card-info a:hover {
  color: var(--bs-brand-2);
}
.card-popular-destination.card-popular-destination-type-2 .card-image {
  height: 100px;
  width: 100px;
  display: inline-block;
}
.card-popular-destination.card-popular-destination-type-2 .card-image img {
  height: auto;
}

.card-support {
  background-color: #a8baf9;
  border-radius: 12px;
  padding: 37px 31px;
  background-image: url(../imgs/page/homepage3/bg-support.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: 24px;
}
.card-support .card-info {
  position: relative;
  margin-bottom: 42px;
}
.card-support .card-info h6 {
  margin-bottom: 8px;
}
.card-support .card-image {
  position: relative;
}
.card-support .card-image img {
  display: block;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 12px;
  border: 1px solid var(--bs-neutral-0);
}

.card-bestprice {
  background-color: #d4f6c0;
  border-radius: 12px;
  padding: 43px 51px;
  background-image: url(../imgs/page/homepage3/bg-bestprice.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: 24px;
}
.card-bestprice .card-info {
  position: relative;
  margin-bottom: 10px;
}
.card-bestprice .card-info h6 {
  margin-bottom: 8px;
}
.card-bestprice .card-info p {
  margin-bottom: 32px;
}

.card-explore {
  background-color: #a8e2ef;
  border-radius: 12px;
  padding: 44px 40px;
  background-image: url(../imgs/page/homepage3/bg-explore.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: 24px;
}
.card-explore .card-info {
  position: relative;
}
.card-explore .card-info h6 {
  margin-bottom: 8px;
}
.card-explore .card-info p {
  margin-bottom: 32px;
}

.card-love-us {
  background-color: transparent;
  padding: 0px;
  display: flex;
  align-items: flex-start;
}
.card-love-us .card-image {
  min-width: 64px;
  margin: 0px;
  margin-right: 16px;
}
.card-love-us .card-info {
  text-align: left;
}

.btn-play {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 101px;
  width: 101px;
  background: url(../imgs/cta/cta-1/play.png) no-repeat center;
  background-size: cover;
}

.card-video {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 12px;
}
.card-video .card-image {
  position: relative;
}
.card-video .card-image .btn-play {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 101px;
  width: 101px;
  margin: auto;
  background: url(../imgs/cta/cta-1/play.png) no-repeat center;
  background-size: cover;
}
.card-video .card-image img {
  display: block;
  width: 100%;
}
.card-video.card-video-small .card-image .btn-play {
  height: 60px;
  width: 60px;
}
.card-video.card-video-type-2 {
  border-radius: 8px;
}

.card-spot {
  position: relative;
  background-color: var(--bs-neutral-0);
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 30px;
  border: 1px solid var(--bs-border-color);
}
.card-spot:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.card-spot .card-image {
  position: relative;
}
.card-spot .card-image img {
  display: block;
  width: 100%;
}
.card-spot .card-info {
  position: absolute;
  bottom: 24px;
  right: 24px;
  left: 24px;
  background-color: var(--bs-neutral-0);
  z-index: 12;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: flex-end;
}
.card-spot .card-info .card-left {
  width: 100%;
}
.card-spot .card-info .card-left .card-desc a {
  display: inline-block;
  margin-right: 10px;
}
.card-spot .card-info .card-right {
  min-width: 27px;
}
.card-spot .card-info .card-right .card-button a {
  display: inline-block;
  height: 27px;
  width: 27px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  background-color: #f2f4f6;
}
.card-spot .card-info .card-right .card-button a svg {
  stroke: var(--bs-color-black);
}
.card-spot .card-info .card-right .card-button a:hover {
  background-color: var(--bs-brand-2);
}
.card-spot .card-info .card-right .card-button a:hover svg {
  stroke: #000000;
}
.card-spot.card-spot-2 {
  padding: 0px;
  border-radius: 16px;
  overflow: hidden;
}
.card-spot.card-spot-2 .card-info {
  left: 14px;
  right: 14px;
  bottom: 14px;
}
.card-spot.card-spot-2 .card-info-two-col {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 14px 14px 14px;
}
.card-spot.card-spot-2 .card-info-two-col .card-info {
  position: relative;
  left: auto;
  right: auto;
  bottom: auto;
  max-width: 50%;
  min-width: 370px;
}

.card-banner-slide {
  position: relative;
}
.card-banner-slide .card-image {
  position: relative;
  min-height: 232px;
  overflow: hidden;
  border-radius: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../imgs/page/homepage4/bg-slider.png);
  width: 100%;
  background-size: cover;
}
.card-banner-slide .card-info {
  position: absolute;
  right: 35px;
  bottom: 30px;
  z-index: 12;
  text-align: right;
}
.card-banner-slide .card-info h6 {
  display: inline-block;
  text-align: left;
  margin-bottom: 35px;
  color: var(--bs-neutral-0);
  font-size: 24px;
  line-height: 32px;
}
.card-banner-slide .card-info .box-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.card-banner-slide .card-info .btn.btn-brand-2 {
  padding: 6px 22px;
  font-size: 12px;
  line-height: 18px;
}
.card-banner-slide.card-banner-slide-2 .card-info, .card-banner-slide.card-banner-slide-3 .card-info {
  text-align: left;
  right: auto;
  left: 30px;
}
.card-banner-slide.card-banner-slide-2 .card-info p, .card-banner-slide.card-banner-slide-3 .card-info p {
  margin-bottom: 5px;
}
.card-banner-slide.card-banner-slide-2 .card-info .box-button, .card-banner-slide.card-banner-slide-3 .card-info .box-button {
  justify-content: flex-start;
}
.card-banner-slide.card-banner-slide-2 .card-info h6, .card-banner-slide.card-banner-slide-3 .card-info h6 {
  color: var(--bs-color-1000);
  margin-bottom: 23px;
}
.card-banner-slide.card-banner-slide-3 .card-info .box-title-top p {
  display: inline-block;
  background-color: var(--bs-brand-2);
  padding: 2px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.card-banner-slide.card-banner-slide-3 .card-info .box-title-middle {
  margin-bottom: 23px;
}
.card-banner-slide.card-banner-slide-3 .card-info .box-title-middle h6 {
  display: block;
  background-color: var(--bs-brand-2);
  padding: 2px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.card-why {
  text-align: center;
  margin-bottom: 40px;
}
.card-why .card-image {
  margin-bottom: 12px;
}
.card-why .card-image svg path {
  fill: var(--bs-neutral-1000);
}
.card-why .card-info h6 {
  margin-bottom: 8px;
  text-align: center;
}
.card-why.card-why-2 {
  border: 1px solid var(--bs-border-color);
  border-radius: 16px;
  background-color: var(--bs-background-1);
  padding: 29px;
  margin-bottom: 24px;
}
.card-why.card-why-2 .card-image {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../imgs/page/homepage6/bg-image-why.png);
  height: 45px;
  line-height: 45px;
}
.card-why.card-why-2 .card-image img {
  display: inline-block;
  vertical-align: middle;
}

.card-activity {
  background-color: #8cd8e9;
  border-radius: 12px;
  padding: 40px 40px 20px 40px;
  background-image: url(../imgs/page/homepage5/bg-activity.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-bottom: 40px;
}
.card-activity .card-info h6 {
  margin-bottom: 22px;
}
.card-activity .card-image {
  margin-top: 60px;
  text-align: center;
}

.card-category {
  height: 316px;
  border-radius: 8px;
  width: 100%;
  background-image: url(../imgs/page/homepage5/waking-up.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.card-category::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-image: url(../imgs/page/homepage5/bg-trans.png);
  background-repeat: repeat;
  background-position: center;
  z-index: 2;
}
.card-category .card-image {
  position: relative;
  z-index: 1;
}
.card-category .card-info {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  z-index: 3;
  text-align: left;
  bottom: 0px;
}
.card-category .card-info .card-text {
  display: inline-block;
  text-align: left;
}
.card-category .card-info .card-text .box-img-activity {
  margin-top: 10px;
  margin-bottom: -15px;
}
.card-category.card-category-2 {
  height: 400px;
  max-width: 400px;
  margin: auto;
  margin-bottom: 30px;
}
.card-category.card-category-3 {
  height: 400px;
  max-width: 400px;
  margin: auto;
  margin-bottom: 30px;
  position: relative;
}
.card-category.card-category-3 .card-info {
  left: 25px;
}
.card-category.card-category-3 .card-info .phone-activity {
  position: absolute;
  bottom: 25px;
  left: 22px;
}

.btn-play-sm {
  position: absolute;
  top: 50%;
  height: 48px;
  width: 48px;
  left: 0px;
  right: 0px;
  margin: auto;
  background: url(../imgs/cta/cta-1/play.png) no-repeat center;
  background-size: cover;
  z-index: 12;
  margin-top: -30px;
}

.card-grid-video {
  position: relative;
  margin-bottom: 30px;
}
.card-grid-video .card-image {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}
.card-grid-video .card-image::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/page/homepage7/bg-trans-video.png);
  background-position: bottom center;
  background-size: contain;
}
.card-grid-video .card-image .btn-play {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 90px;
  width: 90px;
  margin: auto;
  background: url(../imgs/cta/cta-1/play.png) no-repeat center;
  background-size: cover;
  z-index: 12;
}
.card-grid-video .card-image img {
  display: block;
  width: 100%;
}
.card-grid-video .card-info {
  position: absolute;
  bottom: 40px;
  left: 0px;
  right: 0px;
  z-index: 12;
  text-align: center;
  max-width: 537px;
  margin: auto;
}
.card-grid-video .card-info h4 {
  margin-bottom: 8px;
  color: var(--bs-neutral-0);
}
.card-grid-video .card-info p {
  color: var(--bs-neutral-0);
}

.card-team {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}
.card-team .card-image {
  position: relative;
  width: 50%;
  padding: 23px 0px 0px 23px;
  margin-right: 32px;
}
.card-team .card-image::before {
  content: "";
  background-color: var(--bs-background-5);
  border-radius: 0px 16px 0px 16px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 23px;
  bottom: 23px;
  z-index: 1;
}
.card-team .card-image img {
  display: block;
  position: relative;
  z-index: 2;
  border-radius: 0px 16px 0px 16px;
}
.card-team .card-info {
  width: 50%;
}
.card-team .card-info h6 {
  margin-bottom: 7px;
}
.card-team .card-info p {
  margin-bottom: 24px;
}
.card-team.card-team-2 {
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-background-card);
  flex-wrap: wrap;
}
.card-team.card-team-2 .card-image {
  width: 100%;
  padding: 0px;
  margin-right: 0px;
}
.card-team.card-team-2 .card-image img {
  border-radius: 32px 32px 0px 0px;
}
.card-team.card-team-2 .card-image::before {
  display: none;
}
.card-team.card-team-2 .card-info {
  width: 100%;
  border-radius: 12px;
  padding: 28px 32px;
  background-color: var(--bs-background-card);
  margin-top: -40px;
  position: relative;
  z-index: 2;
}
.card-team.card-team-2 .card-info .card-team-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-team.card-team-2 .card-info .card-team-bottom .card-button a {
  display: inline-block;
  height: 27px;
  width: 27px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  background-color: var(--bs-button-bg-2);
}
.card-team.card-team-2 .card-info .card-team-bottom .card-button a svg {
  stroke: var(--bs-neutral-1000);
}
.card-team.card-team-2 .card-info .card-team-bottom .card-button a:hover {
  background-color: var(--bs-neutral-1000);
}
.card-team.card-team-2 .card-info .card-team-bottom .card-button a:hover svg {
  stroke: var(--bs-neutral-0);
}

.card-post {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.card-post .card-image {
  min-width: 70px;
  margin-right: 12px;
}
.card-post .card-image img {
  display: block;
  border-radius: 8px;
}
.card-post .card-info a {
  display: block;
  color: var(--bs-neutral-1000);
  margin-bottom: 9px;
}
.card-post .card-info .price-through {
  display: inline-block;
  text-decoration: line-through;
}
.card-post .card-info .price {
  display: inline-block;
  margin-right: 13px;
}

.card-journey-grid-3 {
  position: relative;
  max-width: 100%;
  height: 398px;
  border-radius: 12px;
}
.card-journey-grid-3 .card-image {
  height: 100% !important;
}
.card-journey-grid-3 .card-image::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  background-image: url(../imgs/page/tour/bg-trans-black.png);
  background-repeat: repeat-x;
  background-position: bottom center;
}
.card-journey-grid-3 .card-image img {
  height: 100%;
  width: auto;
  max-width: none;
  min-width: 100%;
  position: relative;
  z-index: 1;
}
.card-journey-grid-3 .card-info {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 12;
  background-color: transparent;
  padding: 10px 21px 32px 21px;
}
.card-journey-grid-3 .card-info .card-program .card-duration-tour {
  margin-bottom: 19px;
}
.card-journey-grid-3 .card-info .card-program .card-duration-tour p {
  color: var(--bs-color-white) !important;
}
.card-journey-grid-3 .card-info .card-program .endtime .card-rating {
  position: relative;
  top: auto;
  right: auto;
  left: auto;
}
.card-journey-grid-3 .card-info .card-program .endtime .card-rating .card-right .rating {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  border: 0px;
  font-weight: 400;
  color: var(--bs-color-white) !important;
  background-position: left 0px center;
  padding-left: 18px;
}
.card-journey-grid-3 .card-info .card-program .endtime .card-rating .card-right .rating span {
  color: var(--bs-color-white) !important;
}
.card-journey-grid-3 .card-info .card-program .endtime .card-prices {
  display: flex;
  align-items: center;
}
.card-journey-grid-3 .card-info .card-program .endtime .card-prices .card-price {
  margin-right: 18px;
}
.card-journey-grid-3 .card-info .card-program .endtime .card-prices .card-price h6 {
  color: var(--bs-color-white) !important;
}
.card-journey-grid-3 .card-info .card-title a {
  color: var(--bs-color-white) !important;
}
.card-journey-grid-3 .card-info .card-title a:hover {
  color: var(--bs-brand-2);
}

.card-blog {
  position: relative;
  margin-bottom: 30px;
}
.card-blog .card-image {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}
.card-blog .card-image img {
  display: block;
  width: 100%;
}
.card-blog .card-info {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  background: url(../imgs/page/blog/trans.png) repeat-x bottom center;
  top: 0px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.card-blog .card-info .card-info-blog {
  position: absolute;
  bottom: 0px;
  padding: 35px;
  left: 0px;
  right: 0px;
}
.card-blog .card-info .card-info-blog .btn {
  margin-bottom: 16px;
}
.card-blog .card-info .card-info-blog .card-title {
  margin-bottom: 22px;
  display: block;
  color: var(--bs-color-white);
}
.card-blog .card-info .card-info-blog .card-title:hover {
  color: var(--bs-brand-2);
}
.card-blog .card-info .card-info-blog .card-meta-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-blog .card-info .card-info-blog .card-meta-user .box-author-small {
  display: flex;
  align-items: center;
  padding-right: 25px;
}
.card-blog .card-info .card-info-blog .card-meta-user .box-author-small img {
  width: 32px;
  height: 32px;
  border: 1px solid var(--bs-brand-2);
  border-radius: 50%;
  margin-right: 10px;
}
.card-blog .card-info .card-info-blog .card-meta-user .box-author-small p {
  color: var(--bs-color-white);
}
.card-blog .card-info .card-info-blog .card-meta-user .date-post {
  position: relative;
  padding-left: 5px;
}
.card-blog .card-info .card-info-blog .card-meta-user .date-post::before {
  content: "";
  position: absolute;
  top: 2px;
  left: -10px;
  width: 1px;
  height: 18px;
  background-color: var(--bs-background-white);
}
.card-blog .card-info .card-info-blog .card-meta-user .date-post p {
  color: var(--bs-color-white);
}

.card-business {
  position: relative;
  width: 100%;
  padding: 0px;
  text-align: left;
  margin-bottom: 55px;
}
.card-business .card-image {
  margin-left: 0px;
  margin-bottom: 16px;
}

.card-dealer {
  min-height: 182px;
}

/* This line can be removed it was just for display on CodePen: */
.slider-labels {
  margin-top: 10px;
}

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
     its values is > 50%. */
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base, .noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -6px;
  top: -6px;
  background-color: #345DBB;
}

/* Styling;
 */
.noUi-background {
  background: #D6D7D9;
}

.noUi-connect {
  background: #345DBB;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin {
  border-radius: 2px;
}

.noUi-target {
  border-radius: 2px;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.noUi-handle:active {
  border: 8px solid #345DBB;
  border: 8px solid rgba(53, 93, 187, 0.38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -6px;
  top: -6px;
}

/* Disabled state;
 */
[disabled].noUi-connect, [disabled] .noUi-connect {
  background: var(--bs-neutral-1000);
}

[disabled].noUi-origin, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.ui-slider-handle {
  display: none;
}

.ui-widget.ui-widget-content {
  border: 0px solid #EBEBEB;
}

.noUi-target {
  border-radius: 4px;
}

.noUi-horizontal {
  height: 6px;
}

.noUi-background {
  background: var(--bs-neutral-200);
}

.noUi-origin {
  border-radius: 4px;
}

.noUi-connect {
  background: var(--bs-neutral-1000);
  transition: background 450ms;
  height: 6px;
  margin-top: 0px;
}

.noUi-horizontal .noUi-handle {
  background-color: var(--bs-neutral-1000);
  border: 3px solid var(--bs-neutral-0);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  left: -6px;
  top: -6px;
}

.noUi-handle:active {
  left: -6px;
  top: -6px;
}

.noUi-tooltip {
  position: absolute;
  top: -25px;
  left: -11px;
  right: 0px;
  color: var(--bs-neutral-1000);
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  width: 25px;
}

.banner-hero.hero-1 {
  background-image: url(../imgs/page/homepage6/bg-banner-main.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
}
.banner-hero.hero-1 .heading-banner, .banner-hero.hero-1 .banner-description {
  color: #ffffff;
}
.banner-hero.hero-1 .bg-icon-banner {
  position: absolute;
  bottom: 91px;
  z-index: 1;
  height: 132px;
  width: 140px;
  left: 50px;
  background: url(../imgs/page/homepage6/bg-banner.png) no-repeat right center;
  background-size: auto;
}
.banner-hero.hero-1 .banner-inner {
  padding-top: 200px;
  padding-bottom: 176px;
  position: relative;
}
.banner-hero.hero-1 .banner-inner span.bg-circle {
  position: absolute;
  top: 25%;
  z-index: 1;
  height: 490px;
  width: 35%;
  right: 120px;
  background: url(../imgs/page/homepage6/bg-banner2.png) no-repeat right center;
  background-size: contain;
}
.banner-hero.hero-1 .banner-inner .container {
  position: relative;
  z-index: 2;
}
.banner-hero.hero-2 {
  background-image: url(../imgs/page/homepage5/bg-banner.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
}
.banner-hero.hero-2 .banner-inner {
  padding-top: 200px;
  padding-bottom: 0px;
  position: relative;
}
.banner-hero.hero-2 .banner-inner h1 {
  color: var(--bs-neutral-0);
  margin-bottom: 100px;
}
.banner-hero.hero-2 .banner-inner .bg-banner {
  position: absolute;
  bottom: 0%;
  top: 150px;
  z-index: 1;
  width: 100%;
  max-width: 777px;
  right: 120px;
  background: url(../imgs/page/homepage5/banner.png) no-repeat bottom center;
  background-size: contain;
}
.banner-hero.hero-2 .banner-inner .block-banner {
  padding-bottom: 90px;
}
.banner-hero.hero-2 .banner-inner .container {
  position: relative;
  z-index: 2;
}

.cards-banner {
  display: flex;
  flex-wrap: wrap;
  max-width: 701px;
}
.cards-banner .card-banner-1 {
  width: calc(35% - 24px);
  padding: 27px;
  margin-right: 24px;
  border-radius: 34px;
  margin-bottom: 24px;
}
.cards-banner .card-banner-2 {
  width: 65%;
  padding: 27px;
  border-radius: 34px;
  margin-bottom: 24px;
}

@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes hero-thumb-sm-animation {
  0% {
    -webkit-transform: translateY(0px) translateX(50px);
    -moz-transform: translateY(0px) translateX(50px);
    -ms-transform: translateY(0px) translateX(50px);
    transform: translateY(0px) translateX(50px);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0px);
    -moz-transform: translateY(0px) translateX(0px);
    -ms-transform: translateY(0px) translateX(0px);
    transform: translateY(0px) translateX(0px);
  }
}
@keyframes hero-thumb-sm-2-animation {
  0% {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.shape-1 {
  -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
  -moz-animation: hero-thumb-animation 2s linear infinite alternate;
  -o-animation: hero-thumb-animation 2s linear infinite alternate;
  animation: hero-thumb-animation 2s linear infinite alternate;
}

.shape-2 {
  -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  animation: hero-thumb-sm-animation 4s linear infinite alternate;
}

.shape-3 {
  -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}

.text-primary {
  color: var(--bs-brand-2) !important;
}

.text-primary-dark {
  color: var(--bs-link-hover-color) !important;
}

.py-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.pt-96 {
  padding-top: 96px;
}

.pb-96 {
  padding-bottom: 96px;
}

.top-bar {
  background-color: #313131;
  padding: 10px 0px;
  color: var(--bs-color-white);
}
.top-bar .text-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.top-bar-2 {
  color: var(--bs-neutral-1000);
  padding: 0px;
}
.top-bar-2 .container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-bar-2 .btn.btn-mode {
  background-color: transparent;
  padding: 8px 18px;
}
.top-bar-2 .top-right-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top-bar-2 .text-header .text-unlock {
  color: var(--bs-neutral-0);
  background-image: url(../imgs/template/icons/car-front-fill-1.svg);
}
.top-bar-2 .text-header .link-secondary {
  color: var(--bs-neutral-0);
}
.top-bar-2 .text-header .link-secondary svg {
  stroke: var(--bs-neutral-0);
}
.top-bar-2 .text-header-info a {
  color: #fff;
}
.top-bar-2 .text-header-info a svg {
  margin-right: 3px;
}
.top-bar-2 .text-header-info .phone-head {
  margin-right: 34px;
}
.top-bar-2 .text-header-info .phone-head svg {
  fill: #fff;
}
.top-bar-2 .text-header-info .email-head svg {
  fill: #fff;
}
.top-bar-2 .head-lang,
.top-bar-2 .head-currency {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  height: 42px;
  line-height: 42px;
  padding-right: 15px;
}
.top-bar-2 .top-button-mode {
  position: relative;
}

.top-bar-3 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.header {
  padding: 0;
  float: left;
  width: 100%;
  position: relative;
  top: auto;
  left: auto;
  z-index: 123;
  border-bottom: 1px solid var(--bs-neutral-200);
}
.header .container-fluid {
  max-width: 100%;
  padding-left: 60px;
  padding-right: 60px;
}
.header .main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header .main-header .header-left {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.header .main-header .header-left .header-logo {
  margin-right: 0px;
  min-width: 170px;
}
.header .main-header .header-left .header-nav {
  width: 100%;
  text-align: center;
}
.header .main-header .header-right {
  width: 30%;
  min-width: 405px;
  text-align: right;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
}
.header .nav-main-menu {
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.header .main-menu li {
  float: left;
  position: relative;
  padding: 0px 7px;
}
.header .main-menu li.has-children > a::after {
  content: "";
  height: 10px;
  width: 10px;
  background: url(../imgs/template/icons/arrow-down.svg) no-repeat center;
  opacity: 1;
  margin-left: 0px;
  position: absolute;
  top: 28px;
  right: 0px;
  opacity: 0.6;
}
.header .main-menu li.has-children > a:hover::after {
  background: url(../imgs/template/icons/arrow-down.svg) no-repeat center;
}
.header .main-menu li.arrow-white > a::after {
  background: url(../imgs/template/icons/arrow-down-white.svg) no-repeat center;
}
.header .main-menu li.arrow-white > a:hover::after {
  background: url(../imgs/template/icons/arrow-down-white.svg) no-repeat center;
}
.header .main-menu li.hr {
  padding: 0px 22px;
}
.header .main-menu li.hr span {
  background-color: #ececec;
  height: 1px;
  width: 100%;
  display: block;
  margin: 5px 0;
}
.header .main-menu li a {
  font-family: "Urbanist", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--bs-neutral-1000);
  display: block;
  padding: 20px 18px 20px 18px;
  text-decoration: none;
  position: relative;
  transition-duration: 0.2s;
}
.header .main-menu li a:hover {
  color: var(--bs-brand-2) !important;
  transition-duration: 0.2s;
}
.header .main-menu li a i {
  font-size: 10px;
  opacity: 0.5;
  margin-left: 3px;
}
.header .main-menu li ul {
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.2s;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 999;
  min-width: 200px;
  border-radius: 8px;
  padding: 10px 0px 10px 0px;
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 100, 0.05);
}
.header .main-menu li ul.sub-menu {
  background-color: var(--bs-neutral-0);
}
.header .main-menu li ul::before {
  content: "";
  position: absolute;
  bottom: calc(100% - 6px);
  left: 35px;
  border-top: 10px solid var(--bs-neutral-0);
  border-right: 10px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header .main-menu li ul li {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.header .main-menu li ul li a {
  color: var(--bs-neutral-900);
  position: relative;
  padding: 8px 25px 8px 15px;
  transition: 0.3s;
  font-family: "Urbanist", sans-serif;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  min-width: 182px;
}
.header .main-menu li ul li a:hover {
  transition: 0.3s;
  color: var(--bs-link-hover-color) !important;
  padding-left: 20px;
}
.header .main-menu li ul li ul {
  top: 0px;
  left: 100%;
}
.header .main-menu li.mega-li {
  position: static;
}
.header .main-menu li.mega-li .mega-menu {
  position: absolute;
  top: 96%;
  left: 0px;
  width: 100%;
  display: none;
  z-index: 12345;
}
.header .main-menu li.mega-li .mega-menu::before {
  content: "";
  position: absolute;
  bottom: calc(100% - 6px);
  left: 35px;
  border-top: 10px solid var(--bs-neutral-0);
  border-right: 10px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner {
  max-width: 1255px;
  margin: auto;
  border: 0px solid var(--bs-neutral-0);
  border-radius: 8px;
  padding: 35px;
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
  background-color: var(--bs-neutral-0);
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-image: url(../imgs/template/bg-mega.png);
  border-radius: 12px 12px 12px 12px;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner h6 {
  margin-bottom: 4px;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .btn-brand-2 {
  padding: 12px 22px;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner ul {
  display: inline-block;
  box-shadow: none;
  padding: 0px;
  border: 0px;
  position: relative;
  top: auto;
  left: auto;
  opacity: 1;
  visibility: visible;
  margin-top: 16px;
  min-width: 100%;
  background-color: transparent;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner ul li {
  padding: 0px;
  margin-bottom: 12px;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner ul li a {
  padding: 0px;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner ul li a:after {
  display: none;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured {
  padding-left: 60px;
  margin-top: 0px;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li {
  margin-bottom: 0px;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  color: var(--bs-neutral-1000);
  background-repeat: no-repeat;
  background-position: left center;
  padding: 10px 10px 10px 45px;
  transition-duration: 0.2s;
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a.tours {
  background-image: url(../imgs/template/icons/tour.svg);
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a.activities {
  background-image: url(../imgs/template/icons/activities.svg);
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a.destinations {
  background-image: url(../imgs/template/icons/destination.svg);
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a.hotels {
  background-image: url(../imgs/template/icons/hotel.svg);
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a.rental-car {
  background-image: url(../imgs/template/icons/rental.svg);
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a.property {
  background-image: url(../imgs/template/icons/property.svg);
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a.tickets {
  background-image: url(../imgs/template/icons/ticket.svg);
}
.header .main-menu li.mega-li .mega-menu .mega-menu-inner .list-featured li a:hover {
  color: var(--bs-warning) !important;
  padding-left: 40px;
  transition-duration: 0.2s;
}
.header .main-menu li.mega-li-small .mega-menu {
  position: absolute;
  top: 96%;
  left: 0px;
  width: 100%;
  display: none;
  z-index: 12345;
}
.header .main-menu li.mega-li-small .mega-menu::before {
  content: "";
  position: absolute;
  bottom: calc(100% - 6px);
  left: 35px;
  border-top: 10px solid var(--bs-neutral-0);
  border-right: 10px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header .main-menu li.mega-li-small .mega-menu .mega-menu-inner-small {
  border: 0px solid var(--bs-neutral-0);
  border-radius: 8px;
  padding: 20px 25px 20px 25px;
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
  background-color: var(--bs-neutral-0);
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-image: none;
  border-radius: 12px 12px 12px 12px;
  max-width: 333px;
  min-width: 333px;
}
.header .main-menu li.mega-li-small .mega-menu .mega-menu-inner-small.mega-menu-inner-destinations {
  max-width: 220px;
  min-width: 220px;
}
.header .main-menu li.mega-li-small .mega-menu .mega-menu-inner-small h6 {
  margin-bottom: 4px;
}
.header .main-menu li.mega-li-small .mega-menu .mega-menu-inner-small ul {
  display: inline-block;
  box-shadow: none;
  padding: 0px;
  border: 0px;
  position: relative;
  top: auto;
  left: auto;
  opacity: 1;
  visibility: visible;
  margin-top: 8px;
  min-width: 100%;
  background-color: transparent;
}
.header .main-menu li.mega-li-small .mega-menu .mega-menu-inner-small ul li {
  padding: 0px;
  margin-bottom: 12px;
}
.header .main-menu li.mega-li-small .mega-menu .mega-menu-inner-small ul li a {
  padding: 0px;
}
.header .main-menu li.mega-li-small .mega-menu .mega-menu-inner-small ul li a:after {
  display: none;
  transition-duration: 0.2s;
}
.header .main-menu li.mega-li-small .mega-menu .mega-menu-inner-small ul li a:hover {
  padding-left: 5px;
  transition-duration: 0.2s;
}
.header .main-menu li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}
.header .main-menu li:hover > ul li {
  width: 100%;
}
.header .main-menu li:hover.mega-li .mega-menu, .header .main-menu li:hover.mega-li-small .mega-menu {
  display: block;
}
.header.stick {
  padding: 0px;
}
.header.stick .top-bar {
  display: none;
}
.header.header-fixed {
  position: fixed;
  top: 0px;
  left: 0px;
}
.header.header-fixed.header {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}
.header.header-fixed.header .icon-lang {
  color: #fff;
}
.header.header-fixed.header .icon-lang span {
  background: url(../imgs/template/icons/arrow-lang.svg) no-repeat right center;
}
.header.header-fixed.header .icon-cart {
  color: #fff;
}
.header.header-fixed.header .icon-cart span {
  background: url(../imgs/template/icons/arrow-lang.svg) no-repeat right center;
}
.header.header-fixed.sticky-bar.stick {
  background-color: rgba(45, 74, 44, 0.6);
  border-bottom: 0;
  backdrop-filter: blur(50px);
}
.header.header-commingsoon {
  background-color: var(--bs-background-card);
  padding: 23px 0px;
}
.header.header-commingsoon .main-header .header-left {
  width: 50%;
}
.header.header-commingsoon .main-header .header-left .header-logo {
  display: flex;
  align-items: center;
}
.header.header-commingsoon .main-header .header-left .header-logo .text-slogan {
  position: relative;
  padding-left: 25px;
}
.header.header-commingsoon .main-header .header-left .header-logo .text-slogan::before {
  content: "";
  width: 1px;
  height: 26px;
  background-color: var(--bs-border-color);
  position: absolute;
  top: 2px;
  left: 0px;
}
.header.header-commingsoon .main-header .header-right {
  width: 50%;
}

.icon-lang {
  display: inline-block;
  cursor: pointer;
  padding: 0px 0px 0px 26px;
  color: #fff;
}
.icon-lang:hover {
  color: var(--bs-brand-2) !important;
}
.icon-lang span {
  display: inline-block;
  padding: 0px 18px 0px 0px;
  background: url(../imgs/template/icons/arrow-down-sm.svg) no-repeat right center;
}

.icon-cart {
  cursor: pointer;
  color: #fff;
}
.icon-cart:hover {
  color: var(--bs-brand-2) !important;
}
.icon-cart span {
  display: inline-block;
  padding: 0px 18px 0px 0px;
  background: url(../imgs/template/icons/arrow-down-sm.svg) no-repeat right center;
}

.box-desc-menu {
  border-radius: 8px;
  background-color: var(--bs-neutral-900);
  padding: 24px;
  margin-top: 40px;
}
.box-desc-menu p {
  color: var(--bs-neutral-200);
}

.sticky-bar.stick {
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid var(--bs-neutral-100);
  background: var(--bs-neutral-0);
}
.sticky-bar.stick .main-header {
  padding: 0px;
}

.card-author {
  display: flex;
  align-items: center;
}
.card-author .card-image {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}
.card-author .card-image img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
}

.user-account {
  display: flex;
  align-items: center;
}
.user-account img {
  max-width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-account .user-name {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.perfect-scroll {
  height: 100vh;
  width: 100%;
  position: relative;
}

.body-overlay-1 {
  background: transparent;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  cursor: crosshair;
  z-index: 997;
}

.mobile-menu-active .body-overlay-1,
.canvas-menu-active .body-overlay-1 {
  opacity: 1;
  visibility: visible;
}

.form-search {
  position: absolute;
  top: 100%;
  right: 50%;
  width: 100%;
  max-width: 350px;
  z-index: 1234;
  padding: 5px 5px;
  display: none;
  border-radius: 8px;
  border: 1px solid var(--bs-neutral-100);
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}
.form-search.dark {
  background-color: var(--bs-neutral-950);
  border: 1px solid var(--bs-neutral-900);
}
.form-search.dark .form-control {
  border: 1px solid var(--bs-neutral-800);
  border-radius: 8px;
  background-color: var(--bs-neutral-950);
  padding: 11px 15px 13px 15px;
  width: 100%;
  color: #727373;
}
.form-search.dark .btn-search-2 {
  background-color: transparent;
}
.form-search.dark p.color-white {
  color: var(--bs-neutral-200);
}
.form-search .form-control {
  height: 46px;
  line-height: 1;
  padding: 10px 15px 10px 20px;
}
.form-search .btn-search-2 {
  position: absolute;
  top: 20px;
  right: 21px;
  width: 56px;
  height: 46px;
  background: url(../imgs/template/icons/search.svg) no-repeat center;
  background-color: var(--bs-neutral-950);
  border: 0px;
  border-radius: 0px 8px 8px 0;
}

.popular-keywords a {
  color: var(--bs-neutral-500);
}
.popular-keywords a:hover {
  color: var(--bs-color-2);
}

.box-button-logins {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.mobile-header-wrapper-style,
.sidebar-canvas-wrapper {
  position: fixed;
  top: 0;
  max-width: 385px;
  width: 100%;
  min-height: 100vh;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 1006;
}
.mobile-header-wrapper-style.sidebar-visible, .mobile-header-wrapper-style.sidebar-canvas-visible,
.sidebar-canvas-wrapper.sidebar-visible,
.sidebar-canvas-wrapper.sidebar-canvas-visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner {
  padding: 0px 0px 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .burger-icon,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .burger-icon {
  top: 25px;
  right: 30px;
  position: absolute;
  height: 34px;
  width: 34px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-logo,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-logo {
  padding: 7px 10px;
  border-bottom: 1px solid var(--bs-border-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-logo .burger-icon,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-logo .burger-icon {
  display: inline-block;
  position: relative;
  height: 42px;
  width: 42px;
  background-color: var(--bs-neutral-100);
  background-image: url(../imgs/template/icons/close.png);
  background-position: center;
  background-repeat: no-repeat;
  top: auto;
  right: auto;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px 10px 13px 10px;
  background-color: var(--bs-neutral-0);
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .box-author-profile,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-top .box-author-profile {
  margin-bottom: 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a {
  display: block;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
  width: 100px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area {
  padding: 0px 30px 0px 30px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
  display: block;
  position: relative;
  padding: 0px 0;
  margin-bottom: 20px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.hr,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.hr {
  display: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child {
  border-bottom: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand {
  right: 0;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  text-align: center;
  font-size: 12px;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 46px;
  top: 0px;
  color: var(--bs-neutral-1000);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand i,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand i {
  height: 24px;
  width: 24px;
  display: inline-block;
  opacity: 1;
  background: url(../imgs/template/icons/arrow-right-big.svg) no-repeat center;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand {
  background: rgba(255, 255, 255, 0.2);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand i,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand i {
  background: url(../imgs/template/icons/arrow-down-big.svg) no-repeat center;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  display: inline-block;
  color: var(--bs-neutral-1000);
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
  margin-right: 5px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 10px 0 0 0px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 0px 0;
  margin-bottom: 12px;
  border-bottom: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children .menu-expand,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children .menu-expand {
  top: 0px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li:hover a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li:hover a {
  padding-left: 13px;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li:hover a::before,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li:hover a::before {
  opacity: 1;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
  font-size: 16px;
  line-height: 22px;
  display: block;
  font-weight: 500;
  color: var(--bs-neutral-800);
  padding-left: 0px;
  position: relative;
  transition-duration: 0.2s;
  padding-left: 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a:hover,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a:hover {
  padding-left: 13px;
  transition-duration: 0.2s;
  color: var(--bs-link-hover-color) !important;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul {
  margin-top: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children.active,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children.active {
  padding-bottom: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: var(--bs-link-hover-color);
  padding-left: 3px;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .categories-dropdown-wrap ul li a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .categories-dropdown-wrap ul li a {
  padding: 5px 15px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ececec;
  margin: 17px 0 30px 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info {
  position: relative;
  margin-bottom: 13px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child {
  margin-bottom: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a {
  font-size: 14px;
  display: block;
  font-weight: 500;
  color: var(--bs-neutral-1000);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a:hover,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a:hover {
  color: var(--bs-brand-2);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a i,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a i {
  font-size: 14px;
  color: var(--bs-brand-2);
  margin-right: 8px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 0 0 0;
  width: 100%;
  z-index: 11;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
  color: var(--bs-brand-2);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a {
  color: var(--bs-neutral-1000);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-border,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .site-copyright,
.sidebar-canvas-wrapper .mobile-header-wrapper-inner .mobile-header-content-area .site-copyright {
  font-size: 12px;
  color: var(--bs-neutral-800);
}

.sidebar-canvas-wrapper {
  max-width: 385px;
}
.sidebar-canvas-wrapper .sidebar-canvas-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar-canvas-wrapper .sidebar-canvas-container .sidebar-canvas-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid var(--bs-border-color);
}
.sidebar-canvas-wrapper .sidebar-canvas-container .sidebar-canvas-content {
  padding: 38px;
}

.box-author-profile {
  border-radius: 8px;
  border: 1px solid var(--bs-border-color);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  width: 100%;
}

.close-canvas {
  height: 42px;
  width: 42px;
  border-radius: 4px;
  line-height: 42px;
  background-color: var(--bs-brand-2);
  text-align: center;
}
.close-canvas img {
  display: inline-block;
  vertical-align: middle;
}

.sidebar-canvas-lang {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.title-quicklinks {
  background: url(../imgs/template/icons/quick-link.svg) no-repeat left center;
  padding: 0px 0px 0px 32px;
  margin-bottom: 32px;
}

.title-eventsdate {
  background: url(../imgs/template/icons/events.svg) no-repeat left center;
  padding: 0px 0px 0px 32px;
  margin-bottom: 32px;
}

.title-savedplaces {
  background: url(../imgs/template/icons/saved-place.svg) no-repeat left center;
  padding: 0px 0px 0px 32px;
  margin-bottom: 32px;
}

.title-contactus {
  background: url(../imgs/template/icons/contact.svg) no-repeat left center;
  padding: 0px 0px 0px 32px;
  margin-bottom: 32px;
}

.box-list-quicklinks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px -13px 48px -13px;
}
.box-list-quicklinks .item-quicklinks {
  width: 50%;
  margin-bottom: 16px;
  padding: 0px 13px;
  display: flex;
  align-items: center;
}
.box-list-quicklinks .item-quicklinks .item-icon {
  height: 58px;
  width: 58px;
  line-height: 54px;
  min-width: 58px;
  text-align: center;
  border: 1px solid var(--bs-neutral-200);
  border-radius: 8px;
  background-color: var(--bs-neutral-100);
  margin-right: 16px;
}
.box-list-quicklinks .item-quicklinks .item-icon img {
  display: inline-block;
  vertical-align: middle;
}
.box-list-quicklinks .item-quicklinks:hover .item-icon {
  background-color: var(--bs-brand-2);
}
.box-list-quicklinks .item-quicklinks .item-info a {
  color: var(--bs-neutral-1000);
}
.box-list-quicklinks .item-quicklinks .item-info a:hover {
  color: var(--bs-brand-2);
}
.box-list-quicklinks .item-quicklinks .item-info .online {
  padding: 0px 0px 0px 15px;
  background: url(../imgs/template/icons/online.svg) no-repeat left center;
}
.box-list-quicklinks .item-quicklinks .item-info .resolved {
  padding: 0px 0px 0px 15px;
  background: url(../imgs/template/icons/resolved.svg) no-repeat left center;
}

.box-eventsdate,
.box-savedplaces,
.box-contactus {
  margin-bottom: 48px;
}

.contact-info .address-2 {
  padding: 0px 0px 0px 23px;
  background: url(../imgs/template/icons/address2.svg) no-repeat left top 4px;
  color: var(--bs-neutral-400);
  margin-bottom: 19px;
}
.contact-info .hour-work-2 {
  padding: 0px 0px 0px 23px;
  background: url(../imgs/template/icons/hour-work2.svg) no-repeat left top 5px;
  color: var(--bs-neutral-400);
  margin-bottom: 19px;
}
.contact-info .email-2 {
  padding: 0px 0px 0px 23px;
  background: url(../imgs/template/icons/mail2.svg) no-repeat left top 5px;
  color: var(--bs-neutral-400);
  margin-bottom: 19px;
}

#calendar-events {
  background-color: var(--bs-neutral-100);
  border-radius: 16px;
  border: 1px solid var(--bs-border-color);
  padding: 31px;
}
#calendar-events .datepicker-inline {
  width: 100%;
}
#calendar-events .datepicker-inline .table-condensed {
  width: 100%;
}
#calendar-events .datepicker-inline .table-condensed thead tr th {
  color: var(--bs-neutral-500);
}
#calendar-events .datepicker-inline .table-condensed thead tr th:hover {
  color: var(--bs-neutral-500);
}
#calendar-events .datepicker-inline .table-condensed tbody tr td {
  vertical-align: middle;
  font-size: 16px;
  line-height: 40px;
  height: 40px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
}
#calendar-events .datepicker-inline .table-condensed tbody tr td.day.active {
  background-color: var(--bs-brand-2);
  border-color: var(--bs-brand-2);
  background-image: none;
  color: var(--bs-neutral-1000);
}
#calendar-events .datepicker-inline .table-condensed tbody tr td:hover {
  color: var(--bs-neutral-500);
}

.burger-icon-2 {
  position: relative;
  width: 42px;
  height: 42px;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: auto;
  right: auto;
  z-index: 999999;
  display: block;
  background-color: var(--bs-brand-2);
  border-radius: 12px;
  line-height: 40px;
  text-align: center;
}
.burger-icon-2 img {
  display: inline-block;
  vertical-align: middle;
}

.burger-icon {
  position: absolute;
  width: 42px;
  height: 42px;
  background-color: var(--bs-neutral-100);
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 0px;
  right: 0px;
  z-index: 999999;
  display: none;
}
.burger-icon > span {
  display: block;
  position: absolute;
  left: 8px;
  width: 24px;
  height: 1px;
}
.burger-icon > span.burger-icon-top {
  top: 14px;
}
.burger-icon > span.burger-icon-bottom {
  bottom: 14px;
}
.burger-icon > span.burger-icon-mid {
  top: 20px;
}
.burger-icon img {
  display: inline-block;
  vertical-align: middle;
}
.burger-icon.burger-close > span.burger-icon-top {
  display: none;
  opacity: 0;
}
.burger-icon.burger-close > span.burger-icon-mid {
  top: 20px;
  transform: rotate(45deg);
}
.burger-icon.burger-close > span.burger-icon-bottom {
  bottom: 21px;
  transform: rotate(-45deg);
}

.burger-icon > span::before,
.burger-icon > span::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #333;
}

.sidebar-visible .burger-icon.burger-icon-white > span::before,
.sidebar-visible .burger-icon.burger-icon-white > span::after {
  background-color: var(--bs-neutral-950);
}

.burger-icon.burger-icon-white > span::before,
.burger-icon.burger-icon-white > span::after {
  background-color: var(--bs-neutral-1000);
}

.mobile-search form {
  background-color: var(--bs-neutral-100);
  border-radius: 8px;
  height: 44px;
  padding: 8px 15px 11px 30px;
  position: relative;
}
.mobile-search form input {
  border: 0;
  background-color: transparent;
  width: 100%;
  height: auto;
}
.mobile-search form i {
  position: absolute;
  left: 18px;
  top: 14px;
  font-size: 18px;
  color: var(--bs-neutral-700);
}

.mobile-social-icon h6 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 30px;
}
.mobile-social-icon a.icon-socials {
  background-color: var(--bs-neutral-100);
}
.mobile-social-icon a {
  text-align: center;
  font-size: 14px;
  margin-right: 5px;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  height: 30px;
  width: 30px;
  display: inline-flex;
  background: #efeaff;
  border-radius: 30px;
  line-height: 1;
  align-content: center;
  justify-content: center;
}
.mobile-social-icon a img {
  max-width: 18px;
}
.mobile-social-icon a img:hover {
  opacity: 0.8;
}
.mobile-social-icon a:hover {
  transform: translateY(-2px);
  transition-duration: 0.5s;
  margin-top: -2px;
}
.mobile-social-icon a:last-child {
  margin-right: 0;
}

.mobile-account {
  margin: 15px 0;
  padding: 30px 0 20px 0;
  border-top: thin solid #ececec;
}
.mobile-account .mobile-menu {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
.mobile-account h6 {
  font-weight: 600;
  font-size: 18px;
}
.mobile-account ul li {
  padding: 13px 0;
}
.mobile-account ul li a {
  font-size: 15px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: var(--bs-neutral-800);
  transition-duration: 0.2s;
}
.mobile-account ul li:hover a {
  padding-left: 3px;
  transition-duration: 0.2s;
  color: var(--bs-brand-2);
}

.mw-65 {
  min-width: 65px;
}

.sidebar-title {
  border-bottom: thin solid #ececec;
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.search-form form {
  position: relative;
}
.search-form form input {
  border: 1px solid #ececec;
  border-radius: 10px;
  height: 64px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 16px;
  width: 100%;
}
.search-form form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border: none;
  font-size: 20px;
  height: 100%;
  padding: 0 24px;
  background-color: transparent;
  color: #242424;
}

.box-info-contact p {
  margin-bottom: 19px;
}

.icon-address {
  padding: 0px 0px 0px 25px;
  background: url(../imgs/template/icons/address.svg) no-repeat left top;
}

.icon-worktime {
  padding: 0px 0px 0px 25px;
  background: url(../imgs/template/icons/hour-work.svg) no-repeat left top 4px;
}

.icon-email {
  padding: 0px 0px 0px 25px;
  background: url(../imgs/template/icons/mail.svg) no-repeat left top 6px;
}

.footer-top {
  border-bottom: 1px solid rgba(87, 86, 86, 0.4901960784);
  padding-bottom: 35px;
  margin-bottom: 49px;
}
.footer-top img {
  display: block;
}

.need-help {
  background: url(../imgs/template/icons/phone.svg) no-repeat left center;
  display: inline-block;
  padding: 0px 19px 0px 35px;
  color: var(--bs-color-white);
}

a.icon-socials {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition-duration: 0.2s;
  margin-right: 7px;
  line-height: 40px;
  text-align: center;
}
a.icon-socials img {
  vertical-align: middle;
}
a.icon-socials svg path {
  fill: var(--bs-neutral-0);
}
a.icon-socials:last-child {
  margin-right: 0px;
}
a.icon-socials:hover {
  transform: translateY(-3px);
  transition-duration: 0.2s;
}
a.icon-socials:hover svg path {
  fill: #70f46d;
}

.footer-1 {
  width: 30%;
}

.footer-2 {
  width: 17.5%;
}

.footer-3 {
  width: 17.5%;
}

.footer-4 {
  width: 17.5%;
}

.footer-5 {
  width: 17.5%;
}

.footer {
  background-color: var(--bs-background-1000);
  padding: 40px 0px 0px 0px;
}
.footer .phone-support {
  color: var(--bs-brand-2);
}
.footer h6 {
  color: var(--bs-color-white);
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.footer .menu-footer {
  display: inline-block;
}
.footer .menu-footer li {
  list-style: none;
  margin-bottom: 18px;
}
.footer .menu-footer li:last-child {
  padding-right: 0px;
}
.footer .menu-footer li a {
  color: var(--bs-neutral-400);
  font-size: 16px;
  text-decoration: none;
  transition-duration: 0.2s;
  line-height: 26px;
}
.footer .menu-footer li a:hover {
  color: var(--bs-neutral-0);
  transition-duration: 0.2s;
  padding-left: 3px;
  transition-duration: 0.2s;
}
.footer .footer-bottom {
  padding: 30px 0px 10px 0px;
  color: var(--bs-neutral-700);
  font-size: 14px;
  border-top: 1px solid rgba(87, 86, 86, 0.4901960784);
}
.footer .footer-bottom strong {
  color: var(--bs-brand-1);
  font-weight: bold;
}
.footer .footer-bottom a {
  text-decoration: none;
}

/*LAYOUT -> SPACING**/
.section-padding-30 {
  padding: 30px 0;
}

.section-padding-50 {
  padding: 50px 0;
}

.section-padding-60 {
  padding: 60px 0;
}

.section-padding {
  padding: 25px 0;
}

.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pt-260 {
  padding-top: 260px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pl-155 {
  padding-left: 155px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

.pl-175 {
  padding-left: 175px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.pl-185 {
  padding-left: 185px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

.pl-195 {
  padding-left: 195px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pr-155 {
  padding-right: 155px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

.pr-175 {
  padding-right: 175px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pr-185 {
  padding-right: 185px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

.pr-195 {
  padding-right: 195px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

.plr-5-percent {
  padding: 0 5%;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mt-135 {
  margin-top: 135px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mt-145 {
  margin-top: 145px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-155 {
  margin-top: 155px !important;
}

.mt-160 {
  margin-top: 160px !important;
}

.mt-165 {
  margin-top: 165px !important;
}

.mt-170 {
  margin-top: 170px !important;
}

.mt-175 {
  margin-top: 175px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mt-185 {
  margin-top: 185px !important;
}

.mt-190 {
  margin-top: 190px !important;
}

.mt-195 {
  margin-top: 195px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.mb-135 {
  margin-bottom: 135px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-145 {
  margin-bottom: 145px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-155 {
  margin-bottom: 155px !important;
}

.mb-160 {
  margin-bottom: 160px !important;
}

.mb-165 {
  margin-bottom: 165px !important;
}

.mb-170 {
  margin-bottom: 170px !important;
}

.mb-175 {
  margin-bottom: 175px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.mb-185 {
  margin-bottom: 185px !important;
}

.mb-190 {
  margin-bottom: 190px !important;
}

.mb-195 {
  margin-bottom: 195px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-105 {
  margin-left: 105px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.ml-125 {
  margin-left: 125px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.ml-135 {
  margin-left: 135px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.ml-145 {
  margin-left: 145px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.ml-155 {
  margin-left: 155px !important;
}

.ml-160 {
  margin-left: 160px !important;
}

.ml-165 {
  margin-left: 165px !important;
}

.ml-170 {
  margin-left: 170px !important;
}

.ml-175 {
  margin-left: 175px !important;
}

.ml-180 {
  margin-left: 180px !important;
}

.ml-185 {
  margin-left: 185px !important;
}

.ml-190 {
  margin-left: 190px !important;
}

.ml-195 {
  margin-left: 195px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mr-105 {
  margin-right: 105px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mr-125 {
  margin-right: 125px !important;
}

.mr-130 {
  margin-right: 130px !important;
}

.mr-135 {
  margin-right: 135px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.mr-145 {
  margin-right: 145px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mr-155 {
  margin-right: 155px !important;
}

.mr-160 {
  margin-right: 160px !important;
}

.mr-165 {
  margin-right: 165px !important;
}

.mr-170 {
  margin-right: 170px !important;
}

.mr-175 {
  margin-right: 175px !important;
}

.mr-180 {
  margin-right: 180px !important;
}

.mr-185 {
  margin-right: 185px !important;
}

.mr-190 {
  margin-right: 190px !important;
}

.mr-195 {
  margin-right: 195px !important;
}

.mr-200 {
  margin-right: 200px !important;
}

.mask-image {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0) 20%, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0));
}

.box-cta-2 {
  position: relative;
  padding-top: 65px;
  padding-bottom: 80px;
}
.box-cta-2 .bg-shape {
  content: "";
  position: absolute;
  z-index: 0;
  right: 0px;
  width: 1457px;
  max-width: 101%;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  background: url(../imgs/cta/cta-2/background.png) no-repeat center;
  background-size: cover;
}
.box-cta-2 .bg-shape::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  width: 1457px;
  max-width: 100%;
  height: 100%;
  border-radius: 12px;
  opacity: 0.6;
  background: #000;
}

.box-cta-5 {
  position: relative;
}
.box-cta-5 .bg-shape {
  content: "";
  position: absolute;
  z-index: 0;
  right: 0px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  background: url(../imgs/cta/cta-2/background-2.png) no-repeat center;
  background-size: cover;
}
.box-cta-5 .bg-shape::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  max-width: 100%;
  height: 100%;
  opacity: 0.6;
  background: #000000;
}

.box-cta-7 {
  position: relative;
}
.box-cta-7 .bg-shape {
  content: "";
  position: absolute;
  z-index: 0;
  right: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  background: url(../imgs/cta/cta-7/background.png) no-repeat center;
  background-size: cover;
}

.box-list-featured {
  margin-top: 12px;
}

.box-cta-3 {
  max-width: 1457px;
  width: 100%;
}
.box-cta-3 .list-ticks-green li {
  background-image: url(../imgs/template/icons/tick-list-w.svg);
}
.box-cta-3 .imgae {
  border-radius: 56px;
}
.box-cta-3 .bg-overlay {
  width: 650px;
}

.box-app::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/app/app-1/background.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.box-flights {
  padding-top: 90px;
  padding-bottom: 51px;
}

.swiper-group-journey {
  padding-bottom: 8px;
}
.swiper-group-journey .swiper-slide {
  width: auto;
}

.block-banner-home1 {
  min-height: 892px;
  padding-top: 300px;
}
.block-banner-home1 .bg-shape::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/hero/hero-1/banner.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.block-banner-home1 .bg-shape::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: #000;
}

.block-banner-home3 {
  position: relative;
}
.block-banner-home3 .swiper-group-1 {
  max-width: 1860px;
  border-radius: 12px;
}
.block-banner-home3 .item-banner-slide {
  min-height: 600px;
  position: relative;
  padding: 120px 0px 180px 0px;
}
.block-banner-home3 .item-banner-slide.banner-1 {
  background: url(../imgs/hero/hero-3/banner.png) lightgray 50%/cover no-repeat;
}
.block-banner-home3 .item-banner-slide.banner-2 {
  background: url(../imgs/hero/hero-3/banner-2.png) lightgray 50%/cover no-repeat;
}
.block-banner-home3 .item-banner-slide::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.49;
  background: #000000;
  z-index: 0;
}

.container-search-advance {
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
  z-index: 12;
}
.container-search-advance .box-search-advance {
  top: 0px;
}

.block-banner-home2 {
  min-height: 892px;
  padding-top: 300px;
}
.block-banner-home2 .bg-shape::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/hero/hero-2/banner.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.block-banner-home2 .bg-shape::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: #000000;
}

.section-box-banner-2 .box-banner-1 {
  position: relative;
}
.section-box-banner-2 .box-banner-1::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/banners/banners-2/img-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.section-box-banner-2 .box-banner-1::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.39;
  border-radius: 12px;
  background: #000000;
}
.section-box-banner-2 .box-banner-2 {
  position: relative;
}
.section-box-banner-2 .box-banner-2::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/banners/banners-2/img-2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.section-box-banner-2 .box-banner-2::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.39;
  border-radius: 12px;
  background: #000000;
}

.box-search-category a svg path {
  fill: var(--bs-neutral-1000);
}

.box-list-brand-car .item-brand {
  padding: 30px;
  background-color: var(--bs-neutral-0);
  border-radius: 12px;
  border-radius: 12px;
  border: 1px solid var(--bs-border-color, #dde1de);
  background: var(--bs-neutral-0, #fff);
  margin-right: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.box-list-brand-car .item-brand img {
  display: block;
  max-height: 28px;
}
.box-list-brand-car .item-brand:last-child {
  margin-right: 0px;
}
.box-list-brand-car .item-brand-2 {
  background-color: var(--bs-neutral-0);
  border-radius: 12px;
  border-radius: 12px;
  border: 1px solid var(--bs-border-color, #dde1de);
  background: var(--bs-neutral-0, #fff);
  display: flex;
  max-width: 128px;
  height: 128px;
  min-width: 150px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.box-list-brand-car .item-brand-2 img {
  display: block;
  max-height: 28px;
}
.box-list-brand-car .item-brand-2:last-child {
  margin-right: 0px;
}

.list-ticks-green {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.list-ticks-green li {
  margin-bottom: 18px;
  padding: 3px 25px 3px 36px;
  background-image: url(../imgs/template/icons/tick-list.svg);
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  font-weight: bold;
}
.list-ticks-green.list-ticks-green-2 li {
  background-image: url(../imgs/template/icons/tick-list-2.svg);
}

.header-home-2 .btn-signin svg path {
  fill: var(--bs-neutral-1000);
}

.section-cta-4 {
  padding-top: 130px;
  padding-bottom: 115px;
}
.section-cta-4 .bg-shape::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.26;
  background: url(../imgs/cta/cta-4/banner.png) lightgray 50%/cover no-repeat;
}
.section-cta-4 .bg-shape::after {
  content: "";
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  width: 1472px;
  height: 1472px;
  border-radius: 1472px;
  background: var(--bs-background-2);
}

.rounded-12 {
  border-radius: 12px !important;
}

.item-banner-slide-review {
  background-image: url(../imgs/blog/blog-grid/img-1.png);
  min-height: 504px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.item-banner-slide-review::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 70%;
  border-radius: 12px;
  height: 100%;
  opacity: 0.69;
  background: linear-gradient(270deg, rgba(17, 17, 17, 0) -0.95%, rgba(17, 17, 17, 0.69) 100%);
}
.item-banner-slide-review.banner2 {
  background-image: url(../imgs/blog/blog-grid/img-2.png);
}
.item-banner-slide-review.banner-3 {
  background-image: url(../imgs/blog/blog-grid/img-1-1.png);
}

.custom-container {
  max-width: 1412px;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.page-header {
  vertical-align: middle;
}
.page-header .bg-overlay {
  position: relative;
}
.page-header .custom-container {
  min-height: 430px;
}
.page-header .custom-container .bg-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.page-header .custom-container .bg-overlay::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 70%;
  height: 100%;
  opacity: 0.6;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 99.43%);
}
.page-header .custom-container .bg-overlay .img-banner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.page-header-2 {
  vertical-align: middle;
}
.page-header-2 .custom-container {
  min-height: 430px;
}
.page-header-2 .custom-container .bg-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.page-header-2 .custom-container .bg-overlay::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: #000;
}
.page-header-2 .custom-container .bg-overlay .img-banner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.section-1 .fs-72 {
  font-size: 72px;
  line-height: 72px;
  font-weight: 700;
}
.section-1 .fs-96 {
  font-size: 96px;
  line-height: 96px;
  font-weight: 700;
}

.section-box-banner-3 {
  background: url(../imgs/banners/banners-3/banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.section-box-banner-3::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.52;
  background: #000;
}
.section-box-banner-3.banner-2 {
  background: url(../imgs/banners/banners-3/banner2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.accordion .card {
  border: 0;
}
.accordion .card .card-header {
  border: 0;
  background: transparent;
}
.accordion .card .card-header a.collapsed .arrow {
  transition: 0.3s;
  transform: rotate(180deg);
}
.accordion .card .card-header a .arrow {
  transition: 0.3s;
  transform: rotate(0deg);
}

.card-meta-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-meta-user .box-author-small {
  display: flex;
  align-items: center;
  padding-right: 25px;
}
.card-meta-user .box-author-small img {
  width: 32px;
  height: 32px;
  border: 1px solid var(--bs-brand-2);
  border-radius: 50%;
  margin-right: 10px;
}
.card-meta-user .box-author-small p {
  color: var(--bs-color-white);
}
.card-meta-user .date-post {
  position: relative;
  padding-left: 5px;
}
.card-meta-user .date-post::before {
  content: "";
  position: absolute;
  top: 2px;
  left: -10px;
  width: 1px;
  height: 18px;
  background-color: var(--bs-background-white);
}
.card-meta-user .date-post p {
  color: var(--bs-color-white);
}

.pagination {
  justify-content: flex-start;
  margin-top: 30px;
  flex-wrap: wrap;
  list-style: none !important;
  padding-left: 0px !important;
}
.pagination li {
  margin-bottom: 8px;
}
.pagination li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 0px solid var(--bs-neutral-100);
  border-radius: 4px;
  text-align: center;
  padding: 0px;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--bs-button-text-2);
  margin: 2px 4px;
  background-color: var(--bs-button-bg-2);
}
.pagination li a:hover, .pagination li a.active {
  background-color: var(--bs-button-bg);
  color: var(--bs-button-text);
}
.pagination .page-item:not(:first-child) .page-link {
  margin-left: 2px;
}
.pagination .page-item:last-child .page-link {
  border: 0px;
  line-height: 30px;
  border-radius: 4px;
}
.pagination .page-item:last-child .page-link svg {
  stroke: var(--bs-button-text-2);
}
.pagination .page-item:last-child .page-link:hover svg {
  stroke: #101010;
}
.pagination .page-item:first-child .page-link {
  border: 0px;
  line-height: 35px;
  border-radius: 4px;
}
.pagination .page-item:first-child .page-link svg {
  stroke: var(--bs-button-text-2);
}
.pagination .page-item:first-child .page-link:hover svg {
  stroke: #101010;
}

.block-subscriber {
  display: flex;
  align-items: center;
  background-color: var(--bs-background-2);
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
}
.block-subscriber .subscriber-left {
  width: 50%;
  padding: 0px 76px;
}
.block-subscriber .subscriber-left .form-control {
  background-image: url(../imgs/template/icons/email.png);
  padding: 19px 24px 19px 50px;
  background-repeat: no-repeat;
  background-position: left 24px center;
}
.block-subscriber .subscriber-right {
  width: 50%;
  background: url(../imgs/template/subscriber.png) no-repeat center;
  height: 484px;
  background-size: cover;
  border-radius: 0px 12px 12px 0;
}

.box-banner-ads-recent {
  position: relative;
  padding: 30px 30px 190px 30px;
  background-color: var(--bs-background-12);
  background-image: url(../imgs/page/blog/banner-explorer.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: auto;
  border-radius: 8px;
}
.box-banner-ads-recent h6 {
  margin-bottom: 22px;
}
.box-banner-ads-recent .btn-brand-secondary {
  color: var(--Button-button-text-2, #000);
  /* Text/Font MD/Bold */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
  display: inline-flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: #fefa17;
}

.post-date {
  display: inline-block;
  padding: 0px 16px 0px 20px;
  background: url(../imgs/template/icons/calendar.svg) no-repeat left center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
}

.post-time {
  display: inline-block;
  padding: 0px 16px 0px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  background: url(../imgs/template/icons/time.svg) no-repeat left center;
}

.post-comment {
  display: inline-block;
  padding: 0px 0px 0px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  background: url(../imgs/template/icons/comment.svg) no-repeat left center;
}

.content-detail-post {
  max-width: 824px;
  margin: auto;
}
.content-detail-post p {
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: var(--bs-neutral-700);
}
.content-detail-post h6 {
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  color: var(--bs-neutral-1000);
  margin-bottom: 12px;
}

a.icon-socials {
  display: inline-block;
  height: 40px;
  width: 40px;
  transition-duration: 0.2s;
  margin-right: 7px;
  line-height: 40px;
  text-align: center;
}
a.icon-socials img {
  vertical-align: middle;
}
a.icon-socials svg path {
  fill: var(--bs-neutral-0);
}
a.icon-socials:last-child {
  margin-right: 0px;
}
a.icon-socials:hover {
  transform: translateY(-3px);
  transition-duration: 0.2s;
}
a.icon-socials:hover svg path {
  fill: #70f46d;
}

.box-socials svg path {
  fill: var(--bs-neutral-1000) !important;
}

.card-contact {
  width: 100%;
  padding: 22px 25px;
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  background-color: var(--bs-background-body);
  margin-bottom: 25px;
}
.card-contact .card-image {
  margin-bottom: 18px;
}
.card-contact .card-image .card-icon {
  border-radius: 8px;
  background-color: var(--bs-neutral-200);
  width: 53px;
  height: 53px;
  line-height: 53px;
  text-align: center;
}
.card-contact .card-image .card-icon svg path {
  fill: var(--bs-neutral-1000);
}
.card-contact .card-image .card-icon img {
  display: inline-block;
  vertical-align: middle;
}
.card-contact .card-info .card-title {
  margin-bottom: 18px;
}
.card-contact .card-info .card-title .title {
  display: block;
  margin-bottom: 10px;
  color: var(--bs-neutral-1000);
}
.card-contact .card-info .card-title a {
  color: var(--bs-neutral-1000);
}
.card-contact .card-info .card-title a:hover {
  color: var(--bs-brand-2);
}
.card-contact .card-info .card-title p {
  min-height: 36px;
}
.card-contact .card-info .card-method-contact a {
  color: var(--bs-neutral-1000);
}
.card-contact .card-info .card-method-contact a svg path {
  fill: var(--bs-neutral-1000);
}
.card-contact .card-info .card-method-contact a:hover {
  color: var(--bs-brand-2);
}
.card-contact .card-info .card-method-contact a:hover svg {
  stroke: var(--bs-brand-2);
}

.h-520 {
  max-height: 520px;
}

.box-section-term .sidebar-menu a {
  transition: all 0.3s ease-in-out;
}
.box-section-term .sidebar-menu a:hover, .box-section-term .sidebar-menu a.active {
  color: var(--bs-neutral-1000) !important;
  font-weight: 700 !important;
}
.box-section-term .content ul {
  list-style: inherit;
}
.box-section-term .content ul li {
  margin-left: 20px;
}

.sale-lbl {
  position: absolute;
  top: 0px;
  right: 30px;
  z-index: 5;
  background-image: url(../imgs/cars-listing/cars-listing-9/sale.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 44px;
  width: 44px;
  color: var(--bs-neutral-1000);
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
}

.card-property.card-flight.card-hotel .card-info .card-title {
  margin-bottom: 12px;
}
.card-property.card-flight.card-hotel .card-facilities {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities {
  width: 33.33%;
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p {
  background-repeat: no-repeat;
  background-position: left center;
  padding: 0px 0px 0px 30px;
  margin-bottom: 13px;
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.speed {
  background-image: url(../imgs/page/homepage8/speed.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.gear {
  background-image: url(../imgs/page/homepage8/engineering.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.fuel {
  background-image: url(../imgs/page/homepage8/fuel.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.seats {
  background-image: url(../imgs/page/homepage8/seat.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.room {
  background-image: url(../imgs/template/icons/mile.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.pax {
  background-image: url(../imgs/page/hotel/pax.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.size {
  background-image: url(../imgs/template/icons/automatic.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.bed {
  background-image: url(../imgs/template/icons/fuel.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.bathroom {
  background-image: url(../imgs/template/icons/seat.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.parking {
  background-image: url(../imgs/template/icons/suitcase.svg);
}
.card-property.card-flight.card-hotel .card-facilities .item-facilities p.pet {
  background-image: url(../imgs/template/icons/car.svg);
}

.card-method-contact2 a {
  color: var(--bs-neutral-1000);
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  padding: 4px 11px;
  border-radius: 18px;
  border: 0.6px solid var(--bs-border-color);
  background: var(--Button-button-bg-2, #f2f4f6);
}

.box-search-advance-home10 .box-search-advance {
  top: -124px;
}

.form-contact .form-group .cb-remember {
  margin-top: 3px;
}

.box-content-room-detail {
  padding-bottom: 90px;
}

.box-sidebar-content {
  padding-top: 25px;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.list-style-disc {
  list-style: disc;
}

.w-100px {
  width: 100px;
}

.add-to-cart .detail-qty {
  position: relative;
}
.add-to-cart .detail-qty .qty-down {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  z-index: 5;
}
.add-to-cart .detail-qty .qty-up {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  z-index: 5;
}

.text-success {
  color: var(--bs-success) !important;
}

.box-feature-car {
  padding: 45px 45px 31px 45px;
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-background-card);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;
  border-radius: 8px;
}

.list-feature-car {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px -7px;
}
.list-feature-car .item-feature-car {
  padding: 0px 7px;
  margin-bottom: 14px;
}
.list-feature-car .item-feature-car .item-feature-car-inner {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-background-2);
  border-radius: 4px;
}
.list-feature-car .item-feature-car .item-feature-car-inner .feature-image {
  margin-right: 5px;
}
.list-feature-car .item-feature-car .item-feature-car-inner .feature-image img {
  display: block;
}

.list-checked-green {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px !important;
  margin-bottom: 10px;
}
.list-checked-green li {
  list-style: none !important;
  width: 100%;
  background-image: url(../imgs/page/hotel/tick-green.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding: 0px 30px 0px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  margin-bottom: 5px;
}

.list-checked-green-2 {
  list-style: none;
  padding: 0px !important;
  margin-bottom: 12px;
}
.list-checked-green-2 li {
  list-style: none !important;
  background-image: url(../imgs/page/hotel/tick-green.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding: 0px 30px 0px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  margin-bottom: 12px;
}

[data-bs-theme=dark] .box-button-book .btn.btn-book {
  color: var(--bs-neutral-0);
}
[data-bs-theme=dark] .box-button-book .btn.btn-book svg {
  stroke: var(--bs-neutral-0);
}

.box-grid-news .card-news {
  margin-bottom: 32px;
}
.box-grid-news .card-news .card-info {
  margin-top: 0px;
  height: 360px;
}
.box-grid-news .card-news .card-info .card-title {
  margin-bottom: 15px;
  margin-top: 12px;
}
.box-grid-news .card-news .card-info .card-meta {
  margin-top: 0px;
}
.box-grid-news .card-news .card-info .card-desc {
  margin-bottom: 35px;
}
.box-grid-news .card-news .card-image {
  height: 360px;
  max-width: 350px;
}
.box-grid-news .card-news .card-image img {
  width: auto;
}

.list-posts li {
  list-style: none;
  margin-bottom: 22px;
}

.card-post .card-image {
  min-width: 85px;
  max-width: 85px;
  margin-right: 13px;
}
.card-post .card-image img {
  display: block;
  border-radius: 8px;
  width: 100%;
}
.card-post .card-info a {
  display: block;
  margin-bottom: 12px;
}
.card-post .card-info .date-post {
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 24px;
}

.box-sidebar-border {
  padding: 30px;
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  background-color: var(--bs-background-card);
  margin-bottom: 32px;
}
.box-sidebar-border .box-head-sidebar {
  padding: 0px 0px 16px 0px;
  border-bottom: 1px solid var(--bs-border-color);
  margin-bottom: 35px;
}

.box-search-style-2 {
  margin-bottom: 32px;
}

.list-photo-col-3 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px -9px;
}
.list-photo-col-3 li {
  padding: 0px 9px;
  width: 33.33%;
  margin-bottom: 18px;
}
.list-photo-col-3 li img {
  display: block;
  border-radius: 4px;
  width: 100%;
}

.box-search-style-2 {
  position: relative;
  margin-bottom: 32px;
}
.box-search-style-2 form input {
  background-color: var(--bs-neutral-100);
  height: 64px;
  border: 1px solid var(--bs-border-color);
}
.box-search-style-2 .btn-search-submit {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px;
}

.list-posts-md .card-post {
  margin-bottom: 24px;
}
.list-posts-md .card-post .card-image {
  min-width: 131px;
  max-width: 131px;
  margin-right: 20px;
}

.box-posts-grid-2 {
  padding: 90px 0px;
}

.meta-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.meta-user .box-author-small {
  display: flex;
  align-items: center;
  padding-left: 0px;
  margin-bottom: 12px;
  padding-right: 20px;
}
.meta-user .box-author-small img {
  width: 32px;
  height: 32px;
  border: 1px solid var(--bs-brand-2);
  border-radius: 50%;
  margin-right: 10px;
}

.box-content-detail-blog {
  max-width: 1248px;
  margin: auto;
}

.box-content-info-detail {
  background-color: var(--bs-background-body);
  border-radius: 16px;
  padding: 80px 0px 0px 0px;
  position: relative;
  margin-top: -260px;
}

.footer-post-tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 48px;
  padding-top: 25px;
  margin-bottom: 115px;
  border-top: 1px solid var(--bs-border-color);
}
.footer-post-tags .box-tags .btn {
  margin: 0px 16px 16px 0px;
}
.footer-post-tags .box-share {
  margin-bottom: 16px;
}

.box-leave-comment {
  padding: 32px;
  border: 1px solid var(--bs-border-color);
  margin-bottom: 38px;
  border-radius: 8px;
}

.box-list-comment {
  padding: 32px;
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  margin-bottom: 38px;
  display: inline-block;
  width: 100%;
}
.box-list-comment .list-reviews .item-review:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}

.form-contact input[type=text],
.form-contact input[type=email] {
  background-color: var(--bs-background-card);
}
.form-contact .btn-book {
  color: #000000;
}
.form-contact .btn-book svg {
  stroke: #000000;
}

.box-contact-form {
  padding: 90px 0px;
}

.swiper-button-prev-style-1 {
  height: 40px;
  width: 40px;
  line-height: 38px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--bs-neutral-200);
}
.swiper-button-prev-style-1 svg {
  stroke: #000000;
}
.swiper-button-prev-style-1:after {
  display: none;
}
.swiper-button-prev-style-1:hover {
  background-color: var(--bs-brand-2);
}
.swiper-button-prev-style-1:hover svg {
  stroke: #000000;
}

.swiper-button-next-style-1 {
  height: 40px;
  width: 40px;
  line-height: 38px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--bs-neutral-200);
}
.swiper-button-next-style-1 svg {
  stroke: #000000;
}
.swiper-button-next-style-1:after {
  display: none;
}
.swiper-button-next-style-1:hover {
  background-color: var(--bs-brand-2);
}
.swiper-button-next-style-1:hover svg {
  stroke: #000000;
}

.box-button-slider-team {
  text-align: right;
}
.box-button-slider-team .swiper-button-prev-style-1 {
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  margin: 0px 4px 0px 0px;
  display: inline-block;
}
.box-button-slider-team .swiper-button-next-style-1 {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  margin: 0px;
  display: inline-block;
}

.container-slider {
  padding-left: 30px;
}

.popular-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.popular-categories .dropdown-filter {
  margin-left: 8px;
  margin-bottom: 8px;
}

.block-testimonials {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-top: 0px;
}
.block-testimonials .testimonials-left {
  max-width: 688px;
  width: 40%;
  margin-right: 80px;
  position: relative;
}
.block-testimonials .testimonials-left::before {
  content: "";
  position: absolute;
  top: -100px;
  right: -100px;
  width: 264px;
  height: 140px;
  background: url(../imgs/page/homepage1/plane.png) no-repeat center;
  background-size: cover;
  z-index: 1;
}
.block-testimonials .testimonials-right {
  width: 60%;
}

.box-news {
  padding: 91px 0px 110px 0px;
}

.box-list-news {
  margin-top: 54px;
}

.form-subscriber {
  display: flex;
  align-items: center;
  position: relative;
}
.form-subscriber .form-control {
  height: 60px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--bs-border-color);
  padding: 21px 31px;
}
.form-subscriber .btn-submit {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 60px;
  background-color: var(--bs-brand-2);
  border-radius: 12px;
  padding: 19px 35px;
  color: var(--bs-neutral-1000);
  width: auto;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.form-subscriber .btn-submit:hover {
  background-color: var(--bs-neutral-200);
  color: var(--bs-neutral-1000);
}

.box-author-testimonials {
  display: inline-block;
  padding: 10px 12px;
  border-radius: 50px;
  background-color: var(--bs-neutral-0);
  color: var(--bs-neutral-1000);
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: fit-content;
  z-index: 123;
  position: relative;
  border: 1px solid var(--bs-border-color);
}
.box-author-testimonials img {
  border: 1px solid var(--bs-neutral-0);
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin-right: -13px;
}
.box-author-testimonials img:last-child {
  margin-right: 8px;
}

.mt-8 {
  margin-top: 8px;
}

#dropdownSort {
  padding-right: 40px;
  background: url(../imgs/template/icons/sort.svg) no-repeat right 16px center;
}

.box-list-populars {
  margin-top: 25px;
}

.download-apps img {
  margin-right: 8px;
}

.box-subscriber {
  padding-bottom: 100px;
}

.download-apps {
  margin-top: 64px;
}

.text-unlock {
  margin-right: 13px;
  display: inline-block;
  padding: 2px 0px 2px 20px;
  background: url(../imgs/template/icons/icon1.svg) no-repeat left center;
}

.category-link {
  display: inline-block;
  padding: 8px 18px;
  border-radius: 12px;
  color: var(--bs-neutral-1000);
  margin: 0px 4px 0px 0px;
}
.category-link:hover, .category-link.active {
  background-color: var(--bs-brand-2);
  color: #000000;
}

.container-banner {
  position: relative;
  width: 100%;
}

.form-newsletter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 505px;
  width: 100%;
}
.form-newsletter .form-control {
  border: 1px solid var(--bs-neutral-500);
  background-color: var(--bs-background-1000);
  color: var(--bs-neutral-500);
  padding: 19px 24px 19px 50px;
  height: 60px;
  width: 100%;
  max-width: 360px;
  margin-right: 8px;
  background-image: url(../imgs/page/homepage2/email.svg);
  background-repeat: no-repeat;
  background-position: left 24px center;
}
.form-newsletter .form-control::placeholder {
  color: var(--bs-neutral-500);
}
.form-newsletter .btn-brand-2 {
  height: 60px;
  color: var(--bs-background-1000);
}

.slick-prev,
.slick-next {
  position: absolute;
  top: auto;
  bottom: 163px;
  transform: translateY(-50%);
  left: 15px;
  z-index: 123;
  height: 40px;
  width: 40px;
  border: 0px;
  border-radius: 50%;
  background-color: var(--bs-neutral-200);
}
.slick-prev svg,
.slick-next svg {
  stroke: var(--bs-neutral-1000);
}
.slick-prev:hover,
.slick-next:hover {
  background-color: var(--bs-neutral-1000);
}
.slick-prev:hover svg,
.slick-next:hover svg {
  stroke: var(--bs-neutral-0);
}

.slick-next {
  right: auto;
  left: 60px;
}

.slider-thumnail {
  top: 60px;
  position: absolute;
  width: 247px;
  z-index: 110;
  right: 0px;
}
.slider-thumnail .slick-next,
.slider-thumnail .slick-prev {
  display: none !important;
}

.slider-nav-thumbnails .banner-slide {
  margin-bottom: 16px;
  border: 3px solid var(--bs-neutral-0);
  border-radius: 16px;
  overflow: hidden;
}
.slider-nav-thumbnails .banner-slide.slick-current {
  border-color: var(--bs-brand-2);
}

.box-search-advance {
  border: 1px solid var(--bs-border-color);
  border-radius: 16px;
  background-color: var(--bs-neutral-0);
  padding: 28px;
  position: relative;
  top: -143px;
  z-index: 12;
  margin-bottom: -112px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}
.box-search-advance .box-top-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-search-advance .box-bottom-search {
  border: 1px solid var(--bs-border-color);
  border-radius: 16px;
  background-color: var(--bs-neutral-0);
  padding: 21px 0px;
  margin-top: 28px;
  display: flex;
  align-items: flex-end;
}
.box-search-advance .box-bottom-search .item-search {
  width: 20%;
  position: relative;
  padding: 0px 27px;
}
.box-search-advance .box-bottom-search .item-search::before {
  content: "";
  height: 58px;
  right: 10px;
  width: 1px;
  background-color: var(--bs-border-color);
  position: absolute;
  top: 1px;
}
.box-search-advance .box-bottom-search .item-search.bd-none::before {
  display: none;
}
.box-search-advance .box-bottom-search .btn-black-lg {
  padding: 15px 32px !important;
}
.box-search-advance .box-bottom-search .btn-black-lg svg {
  margin: 0px 10px 0px 0px;
}

.need-some-help {
  background: url(../imgs/page/homepage2/user.svg) no-repeat left center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-500);
  display: inline-block;
  padding: 0px 0px 0px 20px;
}

.location-search {
  padding-left: 22px !important;
  background: url(../imgs/page/homepage2/location.svg) no-repeat left center;
}

.box-image-payment-2 img {
  border-radius: 56px;
}

.box-search-category {
  margin-top: 35px;
}

.box-list-brand-car {
  margin-top: 32px;
}

.passenger-search {
  padding-left: 22px !important;
  background: url(../imgs/page/homepage2/user.svg) no-repeat left center;
}

.accordion-button {
  border-bottom: 0px;
  padding: 48px 90px 48px 48px;
  padding-left: 0px;
  border: 0px;
  border-bottom: 1p solid var(--bs-border-color);
  border-color: var(--bs-border-color);
  position: relative;
}
.accordion-button h3 {
  color: var(--bs-neutral-1000);
  padding: 0px 45px;
  min-width: 146px;
}
.accordion-button p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: var(--bs-neutral-1000);
}

.accordion-button.collapsed {
  border-bottom: 1px solid var(--bs-border-color);
}

.accordion-button:focus {
  border-color: var(--bs-border-color);
}

.accordion-collapse {
  border: 0px;
  background-color: var(--bs-neutral-100);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-bottom: 1px solid var(--bs-border-color);
}
.accordion-collapse .accordion-body {
  padding: 0px;
  padding-left: 146px;
  padding-right: 120px;
  margin-bottom: 48px;
}

.accordion-button:not(.collapsed) {
  background-color: var(--bs-neutral-100);
  padding-bottom: 15px;
}

.box-faqs {
  background-image: url(../imgs/page/homepage3/bg-faq.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  padding-bottom: 128px;
  padding-top: 90px;
  border-bottom: 1px solid var(--bs-border-color);
}

.box-faqs-inner {
  background-image: url(../imgs/page/homepage3/bg-faq2.png);
  background-position: right top 90px;
  background-repeat: no-repeat;
}

.block-faqs {
  max-width: 1036px;
  margin: auto;
  margin-top: 65px;
}

.accordion-button::after {
  background-image: url(../imgs/template/icons/open-faq.png);
  transform: rotate(0deg);
  background-position: center;
  background-color: var(--bs-neutral-100);
  border-radius: 4px;
  width: 42px;
  height: 42px;
  top: 48px;
  right: 48px;
  position: absolute;
  z-index: 12;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../imgs/template/icons/close-faq.png);
  background-color: var(--bs-neutral-1000);
}

.accordion-collapse .accordion-body {
  color: var(--bs-neutral-500);
}

.list-check {
  padding: 0px;
  margin: 0px 0px 60px 0px;
  list-style: none;
}
.list-check li {
  font-size: 18px;
  line-height: 42px;
  color: var(--bs-neutral-1000);
  padding: 0px 0px 0px 30px;
  background: url(../imgs/page/homepage4/check.svg) no-repeat left center;
}

.box-authors-partner {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border: 1px solid var(--bs-border-color);
  border-radius: 16px;
}
.box-authors-partner .authors-partner-left {
  display: flex;
  align-items: center;
  min-width: 160px;
}
.box-authors-partner .authors-partner-left img {
  width: 51px;
  height: 51px;
  border: 1px solid var(--bs-border-color);
  margin-right: -20px;
  border-radius: 50%;
}
.box-authors-partner .authors-partner-left .item-author {
  display: inline-block;
  height: 51px;
  width: 51px;
  border: 1px solid var(--bs-border-color);
  border-radius: 50%;
  background-color: var(--bs-brand-2);
  line-height: 46px;
  text-align: center;
  min-width: 51px;
}
.box-authors-partner .authors-partner-right {
  position: relative;
}

.box-why-book-22 {
  padding-bottom: 100px;
}

.box-banner-home7 {
  position: relative;
}
.box-banner-home7 .item-banner-slide {
  min-height: 764px;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 120px 0px 180px 0px;
  background-size: cover;
}
.box-banner-home7 .item-banner-slide::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url(../imgs/page/homepage7/bg-trans.png);
}
.box-banner-home7 .item-banner-slide.banner-1 {
  background: url(../imgs/hero/hero-2/banner.png) lightgray 50%/cover no-repeat;
}
.box-banner-home7 .item-banner-slide.banner-2 {
  background: url(../imgs/hero/hero-2/banner-2.png) lightgray 50%/cover no-repeat;
}
.box-banner-home7 .item-banner-slide .container {
  position: relative;
  z-index: 2;
}
.box-banner-home7 .container-search-advance {
  z-index: 2;
}

.list-videos-small {
  position: relative;
  padding-right: 20px;
}
.list-videos-small .item-video-small {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}
.list-videos-small .item-video-small .item-image {
  width: 188px;
  min-width: 188px;
  height: auto;
  margin-right: 25px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}
.list-videos-small .item-video-small .item-image img {
  display: block;
  width: 100%;
}
.list-videos-small .item-video-small .item-info {
  position: relative;
}
.list-videos-small .item-video-small .item-info .heading-6 {
  margin-bottom: 8px;
  color: var(--bs-neutral-1000);
}
.list-videos-small .item-video-small .item-info .heading-6:hover {
  color: var(--bs-neutral-1000);
}

.box-picked {
  position: relative;
}

.box-videos-small {
  padding: 49px 0px 19px 0px;
  position: relative;
  margin-top: 59px;
}
.box-videos-small .bg-video {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 65%;
  background-color: var(--bs-background-2);
  z-index: 1;
  border-radius: 16px;
}
.box-videos-small .row {
  position: relative;
  z-index: 2;
}

.box-banner-ads {
  position: relative;
}

.card-testimonial-2 {
  max-width: 100%;
  border: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}
.card-testimonial-2 .card-top {
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0px;
}
.card-testimonial-2 .card-top .card-author {
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  width: 100%;
}
.card-testimonial-2 .card-top .card-author .card-image {
  margin-bottom: 10px;
}
.card-testimonial-2 .card-top .card-author > .card-info {
  margin-bottom: 5px;
  width: 100%;
}
.card-testimonial-2 .card-top .card-rate {
  margin-bottom: 10px;
}
.card-testimonial-2 > .card-info {
  text-align: center;
}
.card-testimonial-2 .card-bottom {
  text-align: center;
  margin-top: 15px;
}

.box-grid-hotels .card-flight {
  max-width: 100%;
}
.box-grid-hotels .card-flight .card-info {
  height: 360px;
}
.box-grid-hotels .card-flight .card-image {
  height: 360px;
  max-width: 350px;
}
.box-grid-hotels .card-flight .tour-rate {
  margin-bottom: 4px;
}
.box-grid-hotels .card-flight .card-tags {
  margin-bottom: 24px;
}
.box-grid-hotels .card-flight .card-tags .btn-tag-border {
  margin-right: 5px;
  margin-bottom: 5px;
}
.box-grid-hotels .card-flight .card-tags .btn-tag-border:hover {
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
}
.box-grid-hotels .card-flight .card-facilities {
  margin-bottom: 24px;
}
.box-grid-hotels .card-flight .card-facilities .list-tick-green {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.box-grid-hotels .card-flight .card-facilities .list-tick-green li {
  width: 33.33%;
  background-image: url(../imgs/page/hotel/tick-green.svg);
  background-repeat: no-repeat;
  background-position: left top;
  padding: 0px 30px 0px 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  margin-bottom: 5px;
}

.box-grid-property .card-journey-small .card-info {
  padding: 28px 15px 20px 15px;
}
.box-grid-property .card-journey-small .card-image {
  height: 235px;
}

.block-banner-property {
  padding: 113px 0px;
  background: url(../imgs/page/property/banner.png) no-repeat top center;
  background-size: cover;
}

.list-number-choose {
  display: flex;
  align-items: center;
  margin-top: 9px;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 19px;
}
.list-number-choose a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  font-weight: bold;
  color: var(--bs-neutral-1000);
  background-color: var(--bs-button-bg-2);
  margin: 0px 4px;
}

.box-info-contact .mobile-phone {
  padding: 0px 0px 0px 30px;
  background-image: url(../imgs/page/property/phone.png);
  background-position: left center;
  background-repeat: no-repeat;
}
.box-info-contact .email {
  padding: 0px 0px 0px 30px;
  background-image: url(../imgs/page/property/email.png);
  background-position: left center;
  background-repeat: no-repeat;
}
.box-info-contact .whatsapp {
  padding: 0px 0px 0px 30px;
  background-image: url(../imgs/page/property/whatsapp.png);
  background-position: left center;
  background-repeat: no-repeat;
}
.box-info-contact .fax {
  padding: 0px 0px 0px 30px;
  background-image: url(../imgs/page/property/fax.png);
  background-position: left center;
  background-repeat: no-repeat;
}

.card-author .card-author-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 12px;
}
.card-author .card-author-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.card-author .card-author-info {
  position: relative;
}

.box-info-contact {
  margin-top: 25px;
  margin-bottom: 35px;
}
.box-info-contact p {
  margin-bottom: 16px;
}

.box-link-bottom {
  margin-bottom: 35px;
}

.breadcrumbs {
  padding: 34px 0px;
}
.breadcrumbs li {
  display: inline-block;
  padding-right: 37px;
  position: relative;
}
.breadcrumbs li a {
  font-size: 16px;
  line-height: 26px;
  color: var(--bs-neutral-700);
  font-weight: 500;
  text-decoration: none;
}
.breadcrumbs li a:hover {
  color: var(--bs-neutral-1000);
}
.breadcrumbs li span.arrow-right {
  position: absolute;
  top: 0px;
  right: 15px;
}
.breadcrumbs li span.arrow-right svg {
  stroke: var(--bs-neutral-500);
}
.breadcrumbs li .text-breadcrumb {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  color: var(--bs-neutral-1000);
}

.box-filters-sidebar .title-filter {
  margin-bottom: 24px;
  margin-top: 8px;
}
.box-filters-sidebar .block-filter {
  border-top: 1px solid var(--bs-border-color);
  padding: 42px 0px 10px 0px;
}
.box-filters-sidebar .block-filter .head-filter {
  margin-bottom: 20px;
}
.box-filters-sidebar .block-filter:first-child {
  padding-top: 0px;
  border-top: 0px;
}
.box-filters-sidebar .block-filter .item-collapse {
  margin-bottom: 20px;
  background-image: url(../imgs/template/icons/arrow-up.svg);
  background-position: right center;
  background-repeat: no-repeat;
  padding-right: 20px;
  cursor: pointer;
}
.box-filters-sidebar .block-filter .item-collapse.collapsed-item {
  background-image: url(../imgs/template/icons/arrow-down-filter.svg);
}
.box-filters-sidebar .block-filter .list-sizes .item-size.active {
  font-weight: 500;
}

.box-collapse2 {
  overflow: hidden;
}

.box-collapse {
  margin-bottom: 35px;
}

.box-value-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.list-filter-checkbox li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.list-filter-checkbox li .cb-container {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  color: var(--bs-neutral-1000);
}
.list-filter-checkbox li .cb-container img {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 1px;
}
.list-filter-checkbox li .number-item {
  min-width: 27px;
  max-width: 27px;
  border-radius: 7px;
  padding: 3px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  background-color: var(--bs-border-color);
  text-align: center;
}

.sidebar-left {
  padding: 29px 25px 0px 25px;
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  margin-bottom: 24px;
}

.box-content-main {
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
}
.box-content-main .content-left {
  width: 100%;
  padding: 0px 12px;
  min-width: 295px;
  max-width: 295px;
}
.box-content-main .content-right {
  width: 100%;
  padding: 0px 12px;
}

.box-content-main {
  padding: 56px 0px 90px 0px;
}
.box-content-main #dropdownSort {
  padding-right: 0px;
  background-image: none;
}

.box-item-sort {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.item-sort {
  display: flex;
  align-items: center;
  border: 1px solid var(--bs-border-color);
  border-radius: 4px;
  padding: 7px;
  margin-left: 4px;
}

.dropdown-sort {
  top: -1px;
}
.dropdown-sort .dropdown-toggle,
.dropdown-sort #dropdownSort {
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  background-image: url(../imgs/template/icons/dropdown.svg);
  background-position: right 1px center;
  padding-right: 19px;
  background-repeat: no-repeat;
  border: 0;
}
.dropdown-sort .dropdown-toggle::after,
.dropdown-sort #dropdownSort::after {
  display: none;
}

.box-view-type {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.display-type {
  display: inline-block;
  margin-right: 10px;
}
.display-type svg {
  fill: var(--bs-neutral-500);
}
.display-type.active svg {
  fill: var(--bs-neutral-1000);
}

.number-found {
  display: inline-block;
  margin-left: 8px;
}

.box-see-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.link-see-more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: var(--bs-neutral-1000);
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
}
.link-see-more svg {
  margin-left: 5px;
  fill: var(--bs-neutral-1000);
}

.rate-element {
  border: 1px solid var(--bs-border-color);
  border-radius: 33px;
  padding: 6px 16px 6px 30px;
  background-image: url(../imgs/template/icons/star.svg);
  background-repeat: no-repeat;
  background-position: left 12px center;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  display: inline-block;
  background-color: var(--bs-background-card);
  color: var(--bs-neutral-1000);
}
.rate-element .rating {
  font-weight: bold;
}

.tour-location,
.tour-code {
  display: flex;
  align-items: center;
  width: fit-content;
}
.tour-location svg,
.tour-code svg {
  fill: var(--bs-neutral-300);
  margin-right: 7px;
}

.tour-metas {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.tour-metas .tour-meta-left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.tour-metas .tour-meta-left a {
  text-decoration: underline;
  font-weight: 700;
}
.tour-metas .tour-meta-right {
  min-width: 230px;
  margin-bottom: 15px;
}

.box-content-tour-detail {
  padding-top: 45px;
}

.tour-rate {
  margin-bottom: 22px;
}

.tour-title-main {
  margin-bottom: 20px;
}

.tour-meta-right .btn-share {
  margin-right: 8px;
}

.group-collapse-expand {
  position: relative;
  background-color: var(--bs-background-card);
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  padding: 32px 32px 12px 32px;
  margin-bottom: 20px;
}
.group-collapse-expand .btn-collapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 18px 0px;
  outline: 0;
}
.group-collapse-expand .btn-collapse svg {
  stroke: var(--bs-neutral-1000);
}
.group-collapse-expand .card.card-body {
  padding: 0px !important;
  border: 0px !important;
  background-color: var(--bs-background-card);
}
.group-collapse-expand .card.card-body p {
  font-size: 14px;
  line-height: 22px;
  color: var(--bs-neutral-800);
  margin-bottom: 20px;
}
.group-collapse-expand .card.card-body ul {
  color: var(--bs-neutral-800);
  padding: 0px 0px 0px 20px;
}
.group-collapse-expand .card.card-body ul.list-number {
  list-style: decimal;
  color: var(--bs-neutral-800);
  padding: 0px 0px 0px 20px;
  font-weight: bold;
  margin-bottom: 24px;
}
.group-collapse-expand .card.card-body ul.list-number li span {
  font-weight: 400;
}
.group-collapse-expand .collapsed svg {
  transform: rotate(-180deg);
}

.btn:first-child:active {
  border-color: transparent;
}

.list-questions {
  position: relative;
}
.list-questions .item-question {
  position: relative;
  padding: 14px 20px;
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  background-color: var(--bs-background-body);
  margin-bottom: 8px;
}
.list-questions .item-question.active {
  background-color: var(--bs-neutral-100);
}
.list-questions .item-question .head-question {
  margin-bottom: 9px;
}
.list-questions .item-question .head-question p {
  background-image: url(../imgs/page/tour-detail/question.png);
  background-position: left center;
  background-repeat: no-repeat;
  padding: 0px 0px 0px 25px;
  margin-bottom: 0px;
}

.head-reviews {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.head-reviews .review-left {
  background-color: var(--bs-background-card);
  border: 1px solid var(--bs-border-color);
  border-radius: 8px;
  width: 100%;
  height: 186px;
  min-width: 174px;
  margin-right: 34px;
  max-width: 174px;
  text-align: center;
  line-height: 186px;
}
.head-reviews .review-left .review-info-inner {
  display: inline-block;
  line-height: 22px;
  vertical-align: middle;
}
.head-reviews .review-left .review-info-inner p {
  margin-bottom: 0px;
}
.head-reviews .review-right {
  width: 100%;
}

.item-review-progress,
.group-collapse-expand .item-review-progress {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.item-review-progress .text-rv-progress,
.group-collapse-expand .item-review-progress .text-rv-progress {
  min-width: 108px;
  padding-right: 8px;
}
.item-review-progress .text-rv-progress p,
.group-collapse-expand .item-review-progress .text-rv-progress p {
  margin-bottom: 0px;
}
.item-review-progress .bar-rv-progress,
.group-collapse-expand .item-review-progress .bar-rv-progress {
  display: inline-block;
  width: 100%;
  max-width: 232px;
}
.item-review-progress .bar-rv-progress .progress,
.group-collapse-expand .item-review-progress .bar-rv-progress .progress {
  background-color: var(--bs-neutral-200);
  border-radius: 2px;
  height: 10px;
}
.item-review-progress .bar-rv-progress .progress .progress-bar,
.group-collapse-expand .item-review-progress .bar-rv-progress .progress .progress-bar {
  height: 10px;
  border-radius: 2px;
  background-color: var(--bs-warning);
}
.item-review-progress .text-avarage,
.group-collapse-expand .item-review-progress .text-avarage {
  min-width: 35px;
  padding-left: 8px;
}
.item-review-progress .text-avarage p,
.group-collapse-expand .item-review-progress .text-avarage p {
  margin-bottom: 0px;
}

.list-reviews .item-review {
  border-radius: 8px;
  border: 1px solid var(--bs-border-color);
  padding: 21px 25px;
  background-color: var(--bs-background-body);
  margin-bottom: 17px;
}
.list-reviews .item-review .head-review {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--bs-border-color);
}
.list-reviews .item-review .head-review .author-review {
  display: flex;
  align-items: center;
}
.list-reviews .item-review .head-review .author-review img {
  margin-right: 12px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.list-reviews .item-review .head-review .author-review .author-info p {
  margin-bottom: 0px;
}

.box-type-reviews {
  margin-top: 15px;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bs-border-color);
}

.box-type-review {
  display: flex;
  align-items: center;
}
.box-type-review .text-type-rv {
  margin-bottom: 0px;
  min-width: 101px;
  padding-right: 5px;
}
.box-type-review .rate-type-review {
  margin-bottom: 0px;
}

.box-form-reviews {
  margin-bottom: 15px;
}
.box-form-reviews .form-control {
  background-color: var(--bs-background-card);
}
.box-form-reviews textarea.form-control {
  min-height: 170px;
}

.sidebar-banner {
  margin-bottom: 32px;
}

.content-booking-form {
  position: relative;
  padding: 28px 35px;
}
.content-booking-form .item-line-booking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--bs-border-color);
}
.content-booking-form .item-line-booking.last-item {
  border-bottom: 0px;
  margin-bottom: 0px;
}
.content-booking-form .item-line-booking .line-booking-right {
  display: flex;
  align-items: center;
}
.content-booking-form .item-line-booking .line-booking-right label {
  display: flex;
  margin-left: 18px;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
}
.content-booking-form .item-line-booking .line-booking-right label input {
  height: auto;
  width: 16px;
  margin-right: 5px;
}

.input-calendar {
  position: relative;
}
.input-calendar svg {
  position: absolute;
  right: 15px;
  top: 14px;
  fill: var(--bs-neutral-1000);
}

.line-booking-tickets {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.line-booking-tickets .item-ticket {
  display: flex;
  align-items: center;
}
.line-booking-tickets .item-ticket .list-filter-checkbox li {
  margin-bottom: 0px;
  margin-top: 10px;
}
.line-booking-tickets .include-price {
  margin-top: 10px;
}

.box-tickets {
  width: 100%;
}

.booking-form {
  border-radius: 8px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  position: relative;
  border: 1px solid var(--bs-border-color);
  margin-bottom: 32px;
}
.booking-form .head-booking-form {
  background-color: var(--bs-neutral-100);
  padding: 25px 35px;
}

.booking-form .box-need-help {
  text-align: center;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.booking-form .box-need-help a {
  display: flex;
  align-items: center;
  width: fit-content;
  color: var(--bs-neutral-500);
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
.booking-form .box-need-help a svg {
  stroke: var(--bs-neutral-500);
  margin-right: 10px;
}

.tour-header {
  margin-bottom: 36px;
}

.slider-nav-thumbnails-activities-detail .banner-slide {
  padding: 12px;
}
.slider-nav-thumbnails-activities-detail .banner-slide img {
  border-radius: 8px;
  display: block;
  width: 100%;
}

.slider-thumnail-activities {
  margin: 12px -12px 0px -12px;
  padding-bottom: 12px;
}
.slider-thumnail-activities .slick-next,
.slider-thumnail-activities .slick-prev {
  display: none !important;
}

.box-banner-activities {
  position: relative;
}
.box-banner-activities .slick-prev {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 12;
  left: 38px;
}
.box-banner-activities .slick-next {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 12;
  right: 38px;
  left: auto;
}
.box-banner-activities .banner-slide-activity img {
  border-radius: 8px;
}

.container-banner-activities .box-button-abs {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.container-banner-activities .box-button-abs .btn {
  margin: 0px 10px 20px 10px;
}
.container-banner-activities .box-button-abs .btn svg,
.container-banner-activities .box-button-abs .btn img {
  margin-left: 0px;
  margin-right: 8px;
  fill: var(--bs-neutral-1000);
  display: inline-block;
  vertical-align: middle;
  width: auto;
  min-width: auto;
  min-height: auto;
}

.box-grid-property .card-journey-small .card-info {
  padding: 28px 15px 20px 15px;
}
.box-grid-property .card-journey-small .card-image {
  height: 235px;
}

.block-banner-property {
  padding: 113px 0px;
  background: url(../imgs/page/property/banner.png) no-repeat top center;
  background-size: cover;
}

.list-number-choose {
  display: flex;
  align-items: center;
  margin-top: 9px;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 19px;
}
.list-number-choose a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  font-weight: bold;
  color: var(--bs-neutral-1000);
  background-color: var(--bs-button-bg-2);
  margin: 0px 4px;
}

.box-info-contact .mobile-phone {
  padding: 0px 0px 0px 30px;
  background-image: url(../imgs/page/property/phone.png);
  background-position: left center;
  background-repeat: no-repeat;
}
.box-info-contact .email {
  padding: 0px 0px 0px 30px;
  background-image: url(../imgs/page/property/email.png);
  background-position: left center;
  background-repeat: no-repeat;
}
.box-info-contact .whatsapp {
  padding: 0px 0px 0px 30px;
  background-image: url(../imgs/page/property/whatsapp.png);
  background-position: left center;
  background-repeat: no-repeat;
}
.box-info-contact .fax {
  padding: 0px 0px 0px 30px;
  background-image: url(../imgs/page/property/fax.png);
  background-position: left center;
  background-repeat: no-repeat;
}

.card-author .card-author-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 12px;
}
.card-author .card-author-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.card-author .card-author-info {
  position: relative;
}

.box-info-contact {
  margin-top: 25px;
  margin-bottom: 35px;
}
.box-info-contact p {
  margin-bottom: 16px;
}

.box-link-bottom {
  margin-bottom: 35px;
}

.box-logos-tickets {
  padding: 50px 0px;
  border-bottom: 1px solid var(--bs-border-color);
}

.box-apply-filter {
  padding-top: 25px;
  margin-top: 35px;
  border-top: 1px solid var(--bs-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-clear-filter {
  background: url(../imgs/template/icons/close-filter.svg) no-repeat left center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  padding: 0px 0px 0px 25px;
}

.block-banner-tickets {
  padding: 113px 0px;
  background: url(../imgs/page/tickets/banner.png) no-repeat top center;
  background-size: cover;
}

.box-list-tickets .card-flight {
  min-width: 100%;
}

.box-image-book {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 41px;
  display: inline-block;
  width: 100%;
}
.box-image-book img {
  display: block;
}

.btn-logo {
  padding: 25px 22px;
  border-radius: 8px;
  background-color: var(--bs-background-card);
  position: absolute;
  bottom: 25px;
  left: 25px;
  z-index: 12;
}

.input-calendar label {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: var(--bs-neutral-1000);
  margin-left: 20px;
}
.input-calendar label input {
  width: auto;
  margin: 0px 5px 0px 0px;
}

@media (max-width: 1599.98px) {
  .image-banner-top-left {
    top: 30px;
    left: 20px;
  }
}
@media (max-width: 1399.98px) {
  .custom-class {
    display: block;
  }
  .header .main-menu li {
    padding: 0px 10px;
  }
  .box-banner-home4 .box-image-banner-home4 .shape-phone {
    right: -30px;
  }
  .card-journey-small .card-info {
    padding: 20px 16px;
  }
  .box-grid-hotels .card-flight .card-image {
    max-width: 280px;
  }
  .box-content-info-detail {
    margin-top: -90px;
  }
}
@media (max-width: 1199.98px) {
  .burger-icon {
    display: block;
  }
  .custom-class {
    display: block;
  }
  .header .main-header .header-right {
    min-width: 290px;
  }
  .header-right {
    padding-right: 50px;
  }
  .header .nav-main-menu {
    display: none;
  }
  .header .main-header {
    padding: 10px 0px;
  }
  .sticky-bar.stick .main-header {
    padding: 10px 0px;
  }
  .popup-firstload .popup-container {
    max-width: 95%;
  }
  .box-search-advance .box-bottom-search {
    flex-wrap: wrap;
  }
  .box-search-advance .box-bottom-search .item-search {
    width: 25%;
  }
  .box-search-advance .box-bottom-search .item-search:last-child {
    width: 100%;
    justify-content: center !important;
    padding-top: 20px;
  }
  .box-search-advance .box-bottom-search .item-search:last-child .btn {
    width: 100%;
    justify-content: center;
  }
  .banner-main h1 {
    font-size: 50px;
    line-height: 60px;
  }
  .box-list-numbers {
    padding: 80px 30px;
    flex-wrap: wrap;
  }
  .box-search-advance-2 {
    position: relative;
    top: auto;
    right: auto;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .box-search-advance-2 .box-bottom-search .item-search {
    width: 25%;
  }
  .box-search-advance-2 .box-bottom-search .item-search:last-child {
    width: 25%;
  }
  .box-search-advance-3-col .box-bottom-search .item-search {
    width: 50%;
  }
  .box-banner-home4 .box-image-banner-home4 .shape-phone {
    right: -50px;
  }
  .banner-plus,
  .banner-marker {
    display: none;
  }
  .box-banner-home4 .box-image-banner-home4 .shape-rate {
    left: 130px;
  }
  .box-image-banner-home5 {
    max-width: 50%;
  }
  .block-banner-home5 {
    background-size: 70% auto;
  }
  .block-banner-home5 h1 {
    font-size: 54px;
    line-height: 66px;
  }
  .box-footer-col-3 .footer-col-1,
  .box-footer-col-3 .footer-col-2,
  .box-footer-col-3 .footer-col-3 {
    padding: 15px;
  }
  .top-bar-2 .text-header-info .phone-head,
  .top-bar-2 .text-header-info a {
    margin-right: 14px;
    width: 17px;
    display: inline-block;
    overflow: hidden;
    height: 20px;
    line-height: 17px;
    vertical-align: middle;
  }
  .top-bar-2 .text-header-info a {
    margin-right: 0px;
  }
  .image-banner-bottom {
    max-width: 400px;
  }
  .box-why-home9-inner-inner {
    padding-left: 460px;
  }
  .box-img-test-1 {
    left: 10px;
  }
  .box-img-test-2 {
    right: 10px;
    top: 70px;
  }
  .box-img-test-3 {
    right: -10px;
    bottom: 0px;
  }
  .box-list-tours.list-tours .card-journey-small .card-image {
    min-width: 281px;
  }
  .destination-banner-1 {
    height: 110px;
  }
  .destination-banner-2 {
    height: 290px;
  }
  .destination-banner-3 {
    height: 242px;
  }
  .destination-banner-4,
  .destination-banner-5 {
    height: 155px;
  }
  .destination-banner-6 {
    height: 194px;
  }
  .destination-banner-7 {
    height: 203px;
  }
  .box-header-on-top {
    left: 110px;
    bottom: -20px;
    right: 110px;
  }
  .block-room-features {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    margin-top: 35px;
  }
  .item-room-feature {
    max-width: 16.28%;
  }
  .box-grid-hotels .card-flight {
    flex-wrap: wrap;
  }
  .box-grid-hotels .card-flight .card-image {
    height: auto;
    max-width: 100%;
  }
  .box-grid-hotels .card-flight .card-info {
    margin-left: 0px;
    margin-top: -40px;
  }
  .box-image-vision .image-vision-2 {
    max-width: 160px;
    top: -10px;
    right: 10px;
  }
  .box-image-vision .image-vision-1 {
    max-width: 160px;
    bottom: -30px;
    left: 10px;
  }
  .box-list-hotels-detail .card-flight:nth-of-type(even) .card-info {
    order: 1 !important;
  }
  .box-content-info-detail {
    margin-top: -60px;
  }
  .header.header-commingsoon .main-header .header-right {
    width: 60%;
    padding-right: 0px;
  }
  .header.header-commingsoon .main-header .header-left {
    width: 40%;
  }
  .box-video-comming .box-banner-abs {
    bottom: -90px;
    right: -20px;
  }
  .box-list-flights-2 .item-flight {
    flex-wrap: wrap;
  }
  .box-list-flights-2 .item-flight .flight-price {
    width: 100%;
    padding-left: 0px;
  }
  .box-list-flights-2 .item-flight .flight-price .flight-price-1 {
    width: 50%;
    padding-left: 0px;
    border-left: 0px;
  }
  .box-list-flights-2 .item-flight .flight-price .flight-price-2 {
    width: 50%;
  }
  .box-list-flights-2 .item-flight .flight-route.flight-route-type-2 {
    max-width: 100%;
  }
  .box-list-flights-2 .item-flight .flight-route.flight-route-type-2 .flight-route-1,
  .box-list-flights-2 .item-flight .flight-route.flight-route-type-2 .flight-route-2 {
    justify-content: space-between;
  }
}
@media (max-width: 991.98px) {
  .page-header .custom-container {
    min-height: 280px;
    max-width: 98%;
  }
  .w-md-75 {
    width: 75%;
  }
  .btn.btn-dropdown-search {
    width: 100% !important;
  }
  .footer-1,
  .footer-2,
  .footer-3,
  .footer-4,
  .footer-5 {
    width: 50%;
    margin-bottom: 40px;
  }
  .box-banner-home4 .box-image-banner-home4 .shape-rate {
    left: 0px;
  }
  .block-dropdown-filter,
  .left-dropdown-filter {
    flex-wrap: wrap;
  }
  .left-dropdown-filter {
    margin-bottom: 15px;
  }
  .box-left-payment {
    padding-left: 0px;
  }
  .block-testimonials {
    flex-wrap: wrap;
  }
  .block-testimonials .testimonials-left {
    max-width: 100%;
    width: 100%;
  }
  .block-testimonials .testimonials-left::before {
    right: 10px;
  }
  .block-testimonials .testimonials-right {
    width: 100%;
  }
  .container-slider {
    padding-left: 0px;
  }
  .block-subscriber .subscriber-left {
    width: 60%;
    padding: 0px 16px;
  }
  .block-subscriber .subscriber-right {
    width: 40%;
  }
  .box-search-advance .box-bottom-search {
    flex-wrap: wrap;
  }
  .box-search-advance .box-bottom-search .item-search {
    width: 50%;
    position: relative;
    padding: 0px 27px;
    margin-bottom: 15px;
  }
  .box-search-advance .box-bottom-search .item-search:last-child {
    width: 100%;
    justify-content: center !important;
  }
  .box-search-advance .box-bottom-search .item-search:last-child::before {
    display: none;
  }
  .box-search-advance .box-bottom-search .item-search.item-search-2::before {
    display: none;
  }
  .box-list-numbers {
    padding: 30px 10px;
    flex-wrap: wrap;
  }
  .bg-contact-form-2 {
    position: relative;
    height: 500px;
    max-width: 100%;
    margin-bottom: 35px;
  }
  .box-list-numbers .item-numbers {
    width: 50%;
    padding: 0px 20px;
  }
  .box-list-numbers .item-numbers::before {
    right: 10px;
  }
  .box-list-numbers .item-numbers.item-numbers-2::before {
    display: none;
  }
  .box-search-advance-2 .box-bottom-search .item-search {
    width: 50%;
  }
  .box-search-advance-2 .box-bottom-search .item-search:last-child {
    width: 50%;
  }
  .box-search-advance-3-col {
    margin-bottom: 30px;
  }
  .box-banner-home4 .box-image-banner-home4 {
    position: relative;
    bottom: auto;
    right: auto;
    text-align: center;
  }
  .box-image-how {
    margin-bottom: 40px;
  }
  .box-images-apps {
    margin-top: 0px;
  }
  .img-phone {
    top: 0px;
  }
  .img-laptop {
    top: 0px;
  }
  .box-banner-home4 .box-image-banner-home4 .shape-phone {
    right: -20px;
  }
  .card-journey-small.card-journey-small-type-2 .card-links ul {
    flex-wrap: wrap;
  }
  .card-journey-small.card-journey-small-type-2 .card-links ul li {
    width: 100%;
    margin-bottom: 7px;
  }
  .card-journey-small.card-journey-small-type-2 .card-links {
    margin-top: 15px;
  }
  .block-banner-home5 {
    padding-bottom: 400px;
  }
  .box-item-download {
    padding: 30px 20px 30px 20px;
  }
  .box-footer-col-3 {
    flex-wrap: wrap;
  }
  .box-footer-col-3 .footer-col-1,
  .box-footer-col-3 .footer-col-2 {
    width: 100%;
    padding: 25px 35px;
    border-bottom: 1px solid #313131;
  }
  .box-footer-col-3 .footer-col-1::before,
  .box-footer-col-3 .footer-col-2::before {
    display: none;
  }
  .box-footer-col-3 .footer-col-3 {
    width: 100%;
    padding: 25px 35px;
  }
  .box-videos-small .bg-video {
    width: 85%;
  }
  .container-search-advance {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .item-banner-slide {
    min-height: 543px;
    padding: 120px 0px 145px 0px;
  }
  .item-banner-slide h1 {
    font-size: 50px;
    line-height: 60px;
  }
  .item-banner-slide h5 {
    font-size: 20px;
    line-height: 30px;
  }
  .item-banner-slide.banner-1 {
    min-height: unset !important;
  }
  .item-banner-slide.banner-2 {
    min-height: unset !important;
  }
  .box-numbers-home7 {
    padding-left: 0px;
  }
  .box-testimonials-4 {
    background-position: top 50% left 0%;
  }
  .dark .box-testimonials-4 {
    background-position: top 50% left 0%;
  }
  .swiper-pagination-style-2 {
    right: 15px;
  }
  .box-image-banner-home8 {
    display: none;
  }
  .image-banner-bottom {
    display: none;
  }
  .image-banner-top-left {
    display: none;
  }
  .block-right-banner {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    height: 500px;
    max-width: 100%;
    margin-top: 40px;
  }
  .block-banner-9-inner {
    padding-top: 66px;
    padding-bottom: 167px;
  }
  .item-people-used {
    bottom: 78px;
    left: auto;
    right: 60px;
  }
  .box-why-home9-inner-inner {
    padding: 30px 30px 30px 26px;
  }
  .box-why-home9-inner {
    background-image: none;
  }
  .box-list-flights .item-flight {
    flex-wrap: wrap;
  }
  .box-list-flights .item-flight .flight-route {
    width: 100%;
    margin-bottom: 20px;
  }
  .box-list-flights .item-flight .flight-price .flight-price-1 {
    padding-left: 0px;
    border-left: 0px;
    padding-right: 10px;
    text-align: center;
  }
  .box-list-flights .item-flight .flight-price .flight-price-2 {
    padding: 0px 0px 0px 20px;
    text-align: center;
  }
  .box-install-app-3 .block-install-apps {
    background-image: none;
    margin: 0px;
    padding: 50px 40px 10px 40px;
  }
  .box-install-app-3 .block-install-apps::before {
    display: none;
  }
  .box-right-skyward {
    padding-left: 0px;
  }
  .block-banner-home10 {
    min-height: 720px;
    padding-top: 220px;
    padding-bottom: 220px;
  }
  .block-banner-home10 h1 {
    font-size: 44px;
    line-height: 56px;
  }
  .block-banner-home10 .box-buttons-banner {
    margin-top: 15px !important;
  }
  .box-content-main {
    flex-wrap: wrap;
  }
  .box-content-main .content-left {
    min-width: 100%;
  }
  .box-content-main .content-right {
    padding-bottom: 50px;
  }
  .box-search-advance-4 {
    top: 30px;
  }
  .block-banner-destination {
    padding: 0px 15px;
  }
  .box-banner-tour-detail-4 {
    padding: 0px 10px;
  }
  .box-header-on-top {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    margin-top: 30px;
  }
  .tour-title-main .color-white {
    color: var(--bs-color-black) !important;
  }
  .tour-metas .tour-meta-left .color-white {
    color: var(--bs-color-black) !important;
  }
  .item-room-feature {
    max-width: 21.28%;
    padding: 10px 10px;
    margin: 0px 6px 20px 6px;
  }
  .box-buttons-room-detail-2 .box-button-faq {
    padding-left: 10px;
    padding-right: 10px;
  }
  .box-content-info-detail {
    margin-top: 0px;
  }
  .header-commingsoon .main-menu li {
    padding: 0px 5px;
    float: none;
    display: inline-block;
  }
  .header-commingsoon .main-menu li a {
    padding: 15px 5px 15px 5px;
  }
  .header-commingsoon .main-header {
    flex-wrap: wrap;
  }
  .header.header-commingsoon .main-header .header-left {
    width: 100%;
    justify-content: center;
  }
  .header.header-commingsoon .main-header .header-right {
    width: 100%;
    padding-right: 0px;
    justify-content: center;
    text-align: center;
  }
  .box-video-comming {
    margin-bottom: 150px;
  }
  .header.header-commingsoon .main-header .header-left .header-logo .text-slogan::before {
    left: -12px;
  }
  .header.header-commingsoon .main-header .header-left .header-logo .text-slogan {
    padding-left: 5px;
  }
}
@media (max-width: 767.98px) {
  .page-header .custom-container {
    min-height: 220px;
  }
  .category-link {
    padding: 8px 15px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
    padding: 15px 30px 13px 30px;
    border-bottom: 1px solid var(--bs-border-color);
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
    width: 140px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
    padding: 30px;
  }
  .btn-play-video {
    width: 71px;
    height: 71px;
  }
  .box-banner-left-how {
    display: none;
  }
  .block-subscriber {
    flex-wrap: wrap;
  }
  .block-subscriber .subscriber-left {
    width: 100%;
    padding: 35px;
  }
  .block-subscriber .subscriber-right {
    display: none;
  }
  .card-popular-2 .card-meta .meta-links {
    width: 85%;
  }
  .card-popular-2 .card-meta {
    padding: 12px 10px;
  }
  .card-flight {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    flex-wrap: wrap;
  }
  .card-flight .card-image {
    max-width: 100%;
  }
  .card-flight .card-info {
    padding: 38px 31px;
    width: calc(100% + 0px);
    margin-left: 0px;
    margin-top: -30px;
  }
  .text-unlock {
    display: none;
  }
  .block-testimonials .testimonials-left::before {
    display: none;
  }
  .popup-firstload .popup-container .popup-content {
    flex-wrap: wrap;
  }
  .popup-firstload .popup-container .popup-left {
    padding-left: 22px;
    width: 100%;
  }
  .popup-firstload .popup-container .popup-right {
    width: 100%;
    text-align: center;
  }
  .popup-firstload .popup-container .popup-right img {
    max-height: 250px;
  }
  .slider-thumnail {
    display: none;
  }
  .banner-main .banner-image .container {
    padding-right: 0px;
    padding-top: 30px;
  }
  .banner-image {
    height: auto;
    min-height: 669px;
  }
  .box-search-advance .box-top-search {
    flex-wrap: wrap;
  }
  .left-top-search {
    margin-bottom: 5px;
  }
  .box-search-advance .box-bottom-search .btn-black-lg {
    width: 100%;
    justify-content: center;
  }
  .card-journey-small {
    max-width: 100%;
  }
  .box-flights-type-2 .card-flight .card-image {
    max-width: 100%;
  }
  .image-bottom-how {
    display: flex;
  }
  .image-bottom-how img {
    width: 47%;
  }
  .box-testimonials-3::before {
    right: 0px;
    top: 10px;
  }
  .img-phone {
    max-width: 180px;
    left: -30px;
  }
  .block-install-apps {
    padding: 30px 10px 0px 10px;
  }
  .box-banner-home4 .box-image-banner-home4 .shape-phone {
    right: 10px;
  }
  .box-image-3-col .img-col img {
    border-radius: 32px;
  }
  .card-popular.card-top-destination .card-image {
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .card-popular.card-top-destination .card-image img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .box-img-test-1,
  .box-img-test-2,
  .box-img-test-3,
  .box-img-test-4 {
    display: none;
  }
  .box-title-bestsell {
    background-image: none;
    padding-right: 0px;
  }
  .list-steps-2-col li {
    width: 100%;
  }
  .box-view-type,
  .box-item-sort {
    justify-content: center;
  }
  .box-banner-2-col {
    flex-wrap: wrap;
  }
  .box-banner-2-col .banner-destination-left {
    width: 100%;
  }
  .box-banner-2-col .banner-destination-right {
    position: relative;
    top: auto;
    right: auto;
    bottom: 0px;
    width: 100%;
  }
  .map-main {
    height: 500px;
  }
  .block-banner-destination .box-swiper .swiper-button-prev-style-1 {
    left: 14px;
  }
  .block-banner-destination .box-swiper .swiper-button-next-style-1 {
    right: 14px;
  }
  .destination-banner-1,
  .destination-banner-2,
  .destination-banner-3,
  .destination-banner-4,
  .destination-banner-5,
  .destination-banner-6,
  .destination-banner-7 {
    height: auto;
  }
  .banner-detail-1,
  .banner-detail-2,
  .banner-detail-4,
  .banner-detail-5,
  .banner-detail-6,
  .banner-detail-3 {
    height: auto;
  }
  .box-info-tour {
    flex-wrap: wrap;
    padding-bottom: 1px;
  }
  .box-info-tour .tour-info-group {
    width: 50%;
    margin-bottom: 15px;
  }
  .tour-metas {
    flex-wrap: wrap;
  }
  .head-reviews {
    flex-wrap: wrap;
    justify-content: center;
  }
  .head-reviews .review-left {
    margin-right: 0px;
    margin-bottom: 24px;
  }
  .head-reviews .review-right {
    margin-bottom: 24px;
  }
  .list-reviews .item-review .head-review {
    flex-wrap: wrap;
  }
  .list-reviews .item-review .rate-review,
  .list-reviews .item-review .author-review {
    margin-bottom: 15px;
  }
  .group-collapse-expand {
    padding: 20px 20px 12px 20px;
  }
  .box-banner-tour-detail-2 .box-banner-tour-detail-2-inner .banner-image {
    min-height: 300px;
  }
  .top-bar .text-header {
    display: none;
  }
  .top-bar {
    display: none;
  }
  .item-room-feature {
    max-width: 29.28%;
  }
  .box-banner-room {
    padding: 0px 10px 30px 10px;
  }
  .box-grid-hotels .card-flight .card-image {
    max-width: 100%;
  }
  .box-grid-hotels .card-flight .card-info {
    height: auto;
  }
  .card-flight.card-hotel .card-info .card-hotel-2-col {
    flex-wrap: wrap;
  }
  .card-flight.card-hotel .card-info .card-hotel-2-col .card-program {
    width: 100%;
    margin-bottom: 35px;
  }
  .card-flight.card-hotel .card-info .card-hotel-2-col .card-hotel-price {
    max-width: 100%;
    min-width: auto;
    width: 100%;
  }
  .text-86-bold {
    font-size: 66px;
    line-height: 76px;
  }
  .text-64-medium {
    font-size: 44px;
    line-height: 56px;
  }
  .box-video-comming .box-banner-abs {
    bottom: -50px;
    right: -10px;
    max-width: 250px;
  }
  .box-video-comming {
    margin-bottom: 90px;
  }
  .box-content-tickets-detail {
    padding: 15px 10px 15px 10px;
  }
  .btn-logo {
    padding: 15px 15px;
    bottom: 4px;
    left: 4px;
  }
  .container-banner-activities .box-button-abs-right {
    justify-content: center;
  }
  .box-button-abs {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .footer-1,
  .footer-2,
  .footer-3,
  .footer-4,
  .footer-5 {
    width: 100%;
  }
  .header .main-header .header-right {
    min-width: 150px;
  }
  .header .main-header .header-right .btn-green-linear {
    display: none;
  }
  .burger-icon {
    top: 0px;
  }
  .box-grid-hotels .card-flight .card-facilities .list-tick-green li {
    width: 50%;
  }
  .sticky-bar.stick .burger-icon {
    top: 0px;
  }
  .card-journey-small {
    max-width: 100%;
  }
  .header .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
  h1,
  .heading-1 {
    font-size: 54px;
    line-height: 66px;
  }
  h2,
  .heading-2 {
    font-size: 42px;
    line-height: 60px;
  }
  .logo-area {
    padding: 21px 21px 21px 22px;
    margin-left: -22px;
  }
  .payment-method .btn {
    min-width: 100%;
  }
  .box-search-advance .box-bottom-search .item-search {
    width: 100%;
    border-bottom: 1px solid var(--bs-border-color);
    padding-bottom: 10px;
  }
  .box-search-advance .box-bottom-search .item-search:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
  }
  .dark .box-search-advance .box-bottom-search .item-search {
    border-color: #313131;
  }
  .dark .box-search-advance .box-bottom-search .item-search::before {
    display: none;
  }
  .box-list-numbers .item-numbers {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .box-list-numbers .item-numbers h2 {
    font-size: 32px;
    line-height: 48px;
  }
  .box-search-advance-2 .box-bottom-search .item-search {
    width: 100%;
  }
  .box-search-advance-2 .box-bottom-search .item-search:last-child {
    width: 100%;
  }
  .box-search-advance-2 .box-bottom-search .item-search:last-child .btn {
    width: auto;
  }
  .item-banner-box .item-banner-box-inner {
    padding: 30px;
  }
  .dot-payment {
    top: -15px;
    right: -10px;
  }
  .box-search-advance .box-bottom-search .item-search::before {
    display: none;
  }
  .container-footer {
    padding: 2px 20px 0px 20px;
  }
  .box-slide-banner .swiper-button-prev-style-1,
  .box-slide-banner .swiper-button-next-style-1 {
    display: none;
  }
  .box-images-apps {
    padding-bottom: 20px;
  }
  .review-payment {
    bottom: -70px;
    left: -30px;
    max-width: 290px;
  }
  .img-sm-payment {
    left: 260px;
  }
  .box-swiper-padding.container-slider {
    padding-right: 15px;
  }
  .box-swiper-padding .card-news {
    max-width: 100%;
  }
  .btn.btn-border-1 svg {
    height: 18px;
    margin-right: 8px;
  }
  .box-left-payment-destination .box-button-faq .btn,
  .box-buttons-destination .box-button-faq .btn {
    padding: 8px;
    font-size: 14px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .item-room-feature {
    max-width: 45%;
  }
  .box-commingsoon-1 {
    padding: 30px 30px 30px 20px;
  }
  .box-countdown-comming .box-count .deals-countdown .countdown-section .countdown-amount {
    width: 70px;
    height: 82px;
    font-size: 32px;
    line-height: 82px;
  }
  .banne-activities-3 {
    flex-wrap: wrap;
  }
  .banne-activities-3 .banner-col-1,
  .banne-activities-3 .banner-col-2,
  .banne-activities-3 .banner-col-3 {
    width: 100%;
  }
}
@media (max-width: 499.98px) {
  .box-cta-3 .bg-overlay {
    width: 300px;
    height: 60%;
  }
  .box-banner-1 .banner-images img {
    position: relative !important;
    margin-bottom: 20px;
  }
  .download-apps img {
    max-height: 44px;
  }
  .box-list-quicklinks .item-quicklinks {
    width: 100%;
  }
  .sidebar-canvas-wrapper .sidebar-canvas-container .sidebar-canvas-content {
    padding: 18px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
    padding: 15px 15px 13px 15px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
    padding: 15px;
  }
  h1,
  .heading-1 {
    font-size: 44px;
    line-height: 56px;
  }
  .banner-main h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .box-banner-home4 .box-image-banner-home4 .shape-phone {
    bottom: 5%;
  }
  .block-banner-home5 {
    padding-bottom: 300px;
  }
  .list-numbers {
    flex-wrap: wrap;
  }
  .list-numbers .item-number {
    width: 50%;
  }
  .list-check-2-col {
    flex-wrap: wrap;
  }
  .list-check-2-col li {
    width: 100%;
  }
  .card-grid-video .card-info {
    bottom: 20px;
  }
  .card-grid-video .card-image .btn-play {
    margin-top: 20%;
  }
  .card-grid-video .card-info h4 {
    font-size: 25px;
    line-height: 35px;
  }
  .list-videos-small .item-video-small .item-info .heading-6 {
    font-size: 16px;
    line-height: 23px;
  }
  .list-videos-small .item-video-small .item-image {
    width: 108px;
    min-width: 108px;
    margin-right: 15px;
  }
  .accordion-collapse .accordion-body {
    padding-left: 76px;
    padding-right: 20px;
    margin-bottom: 28px;
  }
  .accordion-button h3 {
    font-size: 30px;
    line-height: 40px;
    padding: 0px 15px;
    min-width: 76px;
  }
  .accordion-button::after {
    right: 18px;
  }
  .accordion-button {
    padding-right: 60px;
  }
  .box-swiper-home7 {
    padding: 0px;
  }
  .box-swiper-home7 .swiper-button-prev-style-1,
  .box-swiper-home7 .swiper-button-next-style-1 {
    display: none;
  }
  .box-swiper-home7 .swiper-group-journey {
    padding-left: 0px;
    padding-right: 0px;
  }
  .box-list-flights .item-flight .flight-route {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0px;
  }
  .box-list-flights .item-flight .flight-price,
  .box-list-flights .item-flight .flight-name {
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
  }
  .box-list-flights .item-flight .flight-route .flight-route-icon {
    margin-bottom: 15px;
  }
  .flight-button {
    width: 100%;
    text-align: center;
  }
  .card-journey-grid-3 .card-info .card-program .endtime .card-prices {
    justify-content: space-between;
    width: 100%;
  }
  .card-journey-grid-3 .card-info .card-program .endtime .card-rating {
    margin-bottom: 15px;
  }
  .card-journey-small .card-info .card-program .endtime {
    flex-wrap: wrap;
  }
  .map-main {
    height: 400px;
  }
  .box-hotel-facilities-list .list-numbers .item-number {
    width: 33.33%;
    margin-bottom: 25px;
  }
  .list-flight-facilities li {
    width: 100%;
  }
  .box-timeline .item-timeline {
    padding-left: 65px;
  }
  .item-info-flight {
    padding: 20px;
  }
  .box-telephone-booking {
    flex-wrap: wrap;
  }
  .box-telephone-booking .box-tel-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .box-list-flights-2 .item-flight .flight-route.flight-route-type-2 .flight-route-1,
  .box-list-flights-2 .item-flight .flight-route.flight-route-type-2 .flight-route-2 {
    flex-wrap: wrap;
  }
  .box-list-flights-2 .item-flight .flight-route .flight-route-icon {
    margin: auto auto 15px auto;
  }
  .datepicker table tr td,
  .datepicker table tr th {
    padding: 8px 12px;
  }
}
@media (max-width: 449.98px) {
  .card-flight .card-info {
    padding: 16px 12px;
  }
  .card-news .card-info {
    padding: 25px;
  }
  .block-subscriber .subscriber-left {
    padding: 20px 15px;
  }
  .card-news .card-info .card-meta .post-date,
  .card-news .card-info .card-meta .post-time {
    padding-right: 10px;
  }
  .box-list-numbers .item-numbers {
    width: 100%;
  }
  .box-list-numbers .item-numbers::before {
    display: none;
  }
  .card-testimonial {
    max-width: 380px;
  }
  .img-phone {
    max-width: 120px;
  }
  .download-apps {
    display: flex;
    align-items: center;
  }
  .card-banner-slide .card-info {
    right: 10px;
  }
  .card-banner-slide.card-banner-slide-2 .card-info,
  .card-banner-slide.card-banner-slide-3 .card-info {
    left: 10px;
  }
  .card-journey-small .card-info {
    padding: 33px 10px;
  }
  .img-sm-payment {
    bottom: -50px;
    left: 180px;
    max-width: 120px;
  }
  .review-payment {
    bottom: -60px;
    left: -20px;
    max-width: 200px;
  }
  .box-buttons-banner {
    flex-wrap: wrap;
  }
  h3,
  .heading-3 {
    font-size: 34px;
    line-height: 48px;
  }
  .popup-signin .popup-container .popup-content {
    padding: 29px 15px;
  }
  .btn.btn-google img {
    margin-right: 5px;
    margin-left: 0px;
  }
  .btn.btn-login {
    padding-left: 9px;
    padding-right: 9px;
  }
  .item-review-progress,
  .group-collapse-expand .item-review-progress {
    flex-wrap: wrap;
  }
  .item-review-progress .text-rv-progress,
  .group-collapse-expand .item-review-progress .text-rv-progress {
    width: 100%;
  }
  .item-review-progress .bar-rv-progress,
  .group-collapse-expand .item-review-progress .bar-rv-progress {
    width: calc(100% - 42px);
  }
  .item-review-progress .text-avarage,
  .group-collapse-expand .item-review-progress .text-avarage {
    min-width: 42px;
  }
  .item-room-feature {
    max-width: 100%;
  }
  .box-countdown-comming .box-count .deals-countdown .countdown-section .countdown-amount {
    width: 60px;
    height: 72px;
    font-size: 28px;
    line-height: 72px;
  }
  .box-countdown-comming .box-count .deals-countdown .countdown-section .countdown-period {
    font-size: 18px;
    line-height: 28px;
  }
  .box-button-top-right {
    top: 10px;
    right: 10px;
  }
  .box-button-top-right .btn {
    margin-left: 5px;
  }
  h4,
  .heading-4 {
    font-size: 24px;
    line-height: 34px;
  }
  .box-banner-activities-detail-4 .container-banner-activities .box-button-abs-right .btn-brand-2 {
    bottom: 0px;
    right: 5px;
  }
  .block-banner-property-detail .banner-property-detail-1 .box-button-abs {
    flex-wrap: wrap;
  }
}
@media (max-width: 374.98px) {
  .card-news .card-info {
    padding: 15px 15px;
  }
  .box-footer-col-3 .footer-col-1,
  .box-footer-col-3 .footer-col-2,
  .box-footer-col-3 .footer-col-3 {
    padding: 15px;
  }
  .card-grid-video .card-image .btn-play {
    margin-top: 10%;
  }
  .item-sort > span {
    display: none;
  }
  .box-info-tour .tour-info-group {
    width: 100%;
  }
  .box-grid-hotels .card-flight .card-facilities .list-tick-green li {
    width: 100%;
  }
  .card-flight.card-hotel .card-info .card-program .endtime {
    flex-wrap: wrap;
  }
  .card-flight.card-hotel .card-info .card-program .endtime .card-price {
    width: 100%;
    margin-bottom: 15px;
  }
}
@media (max-width: 339.98px) {
  .box-numbers .item-number {
    width: 100%;
  }
  .box-buttons-feature-4 .btn-black {
    margin-bottom: 15px;
  }
  .box-buttons-feature-4 .btn.btn-learmore-2 {
    margin-bottom: 15px;
  }
}
@media (min-width: 1400px) {
  .container.wide {
    max-width: 1544px;
  }
  .pt-lg-60 {
    padding-top: 60px;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    max-width: 75%;
  }
  .w-lg-55 {
    max-width: 55%;
  }
  .w-lg-25 {
    max-width: 25%;
  }
  .cars-details4 .slick-list {
    overflow: visible;
  }
}
/*OTHER SCREEN*/
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .header-right {
    display: none;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1610px) {
  .header .container-fluid {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .header .main-menu li a {
    padding: 20px 14px 20px 8px;
  }
  .btn.btn-mode {
    padding: 11px 11px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1399.98px) {
  .burger-icon {
    display: block;
  }
  .header .nav-main-menu {
    display: none;
  }
  .header .main-header {
    padding: 10px 0;
  }
  .sticky-bar.stick .main-header {
    padding: 10px 0;
  }
}
@media (min-width: 768px) {
  .card-journey-small-listing-3 .card-image {
    width: auto !important;
  }
  .card-journey-small-listing-3 .card-info {
    width: 61% !important;
    position: absolute !important;
  }
  .section-cta-6 .mt-md-50 {
    margin-top: 50px;
  }
  .w-md-25 {
    width: 25%;
  }
  .p-md-40 {
    padding: 40px !important;
  }
  .list-checked-green li {
    width: 50%;
  }
  .pagination li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}