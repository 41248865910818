/* Variables (you'll manually replace them later in your CSS) */
:root {
  --bs-brand-2: #70f46d;
  --background-color: #333;
  --border-radius-big: 6px;
  --font-size-header: 16px;
  --car-color: var(--bs-brand-2);
  --spin-deg: 36deg;
}

/* Keyframes */
@keyframes spin {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(36deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(72deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(108deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(144deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(180deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(216deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(252deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(288deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(324deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(360deg);
  }
}

@keyframes speed {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes strikes {
  from {
    left: 25px;
  }
  to {
    left: -80px;
    opacity: 0;
  }
}

@keyframes dots {
  from {
    width: 0px;
  }
  to {
    width: 15px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* CSS Styles */
.fadeIn {
  animation: fadeIn 0.4s both;
}

.loading-window {
  border-radius: var(--border-radius-big);
  color: var(--bs-brand-2);
  height: 200px;
  left: 50%;
  margin-left: -150px;
  margin-top: -100px;
  position: fixed;
  top: 50%;
  width: 300px;
  z-index: 99;
}

.loading-window .text {
  font-size: var(--font-size-header);
  position: absolute;
  width: auto;
  top: 66%;
  left: 27%;
  margin: 0 auto;
}

.loading-window .dots {
  display: inline-block;
  width: 5px;
  overflow: hidden;
  vertical-align: bottom;
  animation: dots 1.5s linear infinite;
}

.car {
  position: absolute;
  width: 117px;
  height: 42px;
  left: 92px;
  top: 70px;
}

.car .strike {
  position: absolute;
  width: 11px;
  height: 1px;
  background: var(--bs-brand-2);
  animation: strikes 0.2s linear infinite;
}

.car .strike2 {
  top: 11px;
  animation-delay: 0.05s;
}

.car .strike3 {
  top: 22px;
  animation-delay: 0.1s;
}

.car .strike4 {
  top: 33px;
  animation-delay: 0.15s;
}

.car .strike5 {
  top: 44px;
  animation-delay: 0.2s;
}

.car-detail {
  position: absolute;
  display: block;
  background: var(--bs-brand-2);
  animation: speed 0.5s linear infinite;
}

.car-detail.spoiler {
  width: 0;
  height: 0;
  top: 7px;
  background: none;
  border: 20px solid transparent;
  border-bottom: 8px solid var(--bs-brand-2);
  border-left: 20px solid var(--bs-brand-2);
}

.car-detail.back {
  height: 20px;
  width: 92px;
  top: 15px;
}

.car-detail.center {
  height: 35px;
  width: 75px;
  left: 12px;
  border-top-left-radius: 30px;
  border-top-right-radius: 45px 40px;
  border: 4px solid var(--bs-brand-2);
  background: none;
  box-sizing: border-box;
}

.car-detail.front {
  height: 20px;
  width: 50px;
  top: 15px;
  left: 67px;
  border-top-right-radius: 50px 40px;
  border-bottom-right-radius: 10px;
}

.car-detail.wheel {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 20px;
  left: 12px;
  border: 3px solid var(--background-color);
  background: linear-gradient(
      45deg,
      transparent 45%,
      var(--bs-brand-2) 46%,
      var(--bs-brand-2) 54%,
      transparent 55%
    ),
    linear-gradient(
      -45deg,
      transparent 45%,
      var(--bs-brand-2) 46%,
      var(--bs-brand-2) 54%,
      transparent 55%
    ),
    linear-gradient(
      90deg,
      transparent 45%,
      var(--bs-brand-2) 46%,
      var(--bs-brand-2) 54%,
      transparent 55%
    ),
    linear-gradient(
      0deg,
      transparent 45%,
      var(--bs-brand-2) 46%,
      var(--bs-brand-2) 54%,
      transparent 55%
    ),
    radial-gradient(
      var(--bs-brand-2) 29%,
      transparent 30%,
      transparent 50%,
      var(--bs-brand-2) 51%
    ),
    var(--bs-brand-2);
  animation-name: spin;
}

.car-detail.wheel2 {
  left: 82px;
}
