:root {
  --bs-border-color: #{$border};
  --bs-color-white: #{$neutral-0};
  --bs-color-black: #{$neutral-1000};
  --bs-color-700: #{$neutral-700};
  --bs-background-1000: #{$neutral-1000};
  --bs-background-white: #{$neutral-0};
  --bs-color-1000: #{$neutral-1000};
  --bs-background-body: #{$background-body};
  --bs-background-1: #{$background-1};
  --bs-background-2: #{$background-2};
  --bs-background-3: #{$background-3};
  --bs-background-4: #{$background-4};
  --bs-background-5: #{$background-5};
  --bs-background-6: #{$background-6};
  --bs-background-7: #{$background-7};
  --bs-background-8: #{$background-8};
  --bs-background-9: #{$background-9};
  --bs-background-12: #{$background-12};
  --bs-background-card: #{$background-card};
  --bs-neutral-0: #{$neutral-0};
  --bs-neutral-100: #{$neutral-100};
  --bs-neutral-200: #{$neutral-200};
  --bs-neutral-300: #{$neutral-300};
  --bs-neutral-400: #{$neutral-400};
  --bs-neutral-500: #{$neutral-500};
  --bs-neutral-600: #{$neutral-600};
  --bs-neutral-700: #{$neutral-700};
  --bs-neutral-800: #{$neutral-800};
  --bs-neutral-900: #{$neutral-900};
  --bs-neutral-1000: #{$neutral-1000};
  --bs-button-bg: #{$button-bg};
  --bs-button-bg-2: #{$button-bg-2};
  --bs-button-text: #{$button-text};
  --bs-button-text-2: #{$button-text-2};
  --bs-brand-2: #{$brand-2};
  --bs-brand-2-dark: #{$brand-2-dark};
  --bs-brand-2-darken: #{$brand-2-darken};
  --bs-brand-2-light: #{$brand-2-light};
  --bs-link-hover-color: #{$link-hover-color};
  --bs-brand-1: #{$brand-1};
  --bs-warning: #{$warning};
  --bs-success: #{$success};
  --bs-danger: #{$danger};
  --bs-color-grey: #{$color-grey};
  --bs-color-2: #{$color-2};
  --bs-color-grey-2: #{$color-grey-2};
  --bs-neutral-950: #{$neutral-950};
  --bs-primary-rgb: 112, 244, 109;
}
