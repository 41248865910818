/*import vendors*/
@import url(../css/vendors/normalize.css);
@import url(../css/vendors/bootstrap.min.css);
@import url(../css/vendors/uicons-regular-rounded.css);
/*import plugins*/
@import url(../css/plugins/swiper-bundle.min.css);
@import url(../css/plugins/magnific-popup.css);
@import url(../css/plugins/animate.css);
@import url(../css/plugins/select2.min.css);
@import url(../css/plugins/perfect-scrollbar.css);
@import url(../css/plugins/carouselTicker.css);
@import url(../css/plugins/bootstrap-datepicker.css);
@import url(../css/plugins/bootstrap-datepicker3.css);
@import url(../css/plugins/slick.css);
@import url(../css/plugins/odometer.css);
