.noUi-tooltip {
    position: absolute;
    top: -25px;
    left: -11px;
    right: 0px;
    color: var(--bs-neutral-1000);
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    width: 25px;
}