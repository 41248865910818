.text-primary {
  color: var(--bs-brand-2) !important;
}
.text-primary-dark {
  color: var(--bs-link-hover-color) !important;
}
.py-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}
.pt-96 {
  padding-top: 96px;
}
.pb-96 {
  padding-bottom: 96px;
}
