/* This line can be removed it was just for display on CodePen: */

.slider-labels {
  margin-top: 10px;
}

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
/* Fix 401 */
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s,top .3s;
  transition: left 0.3s,top .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,.noUi-handle {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -6px;
  top: -6px;
  background-color: #345DBB;
}

/* Styling;
 */
.noUi-background {
  background: #D6D7D9;
}

.noUi-connect {
  background: #345DBB;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin {
  border-radius: 2px;
}

.noUi-target {
  border-radius: 2px;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.noUi-handle:active {
  border: 8px solid #345DBB;
  border: 8px solid rgba(53,93,187,0.38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -6px;
  top: -6px;
}

/* Disabled state;
 */
[disabled].noUi-connect,[disabled] .noUi-connect {
  background: var(--bs-neutral-1000);
}

[disabled].noUi-origin,[disabled] .noUi-handle {
  cursor: not-allowed;
}

.ui-slider-handle
{
    display: none;
}
.ui-widget.ui-widget-content
{
    border: 0px solid #EBEBEB;
}
.noUi-target {
    border-radius: 4px;
}
.noUi-horizontal {
    height: 6px;
}
.noUi-background {
    background: var(--bs-neutral-200);
}
.noUi-origin {
    border-radius: 4px;
}
.noUi-connect {
    background: var(--bs-neutral-1000);
    transition: background 450ms;
        height: 6px;
    margin-top: 0px;
}
.noUi-horizontal .noUi-handle
{
    background-color: var(--bs-neutral-1000);
    border: 3px solid var(--bs-neutral-0);
    box-shadow: $box-shadow-3;
    left: -6px;
    top: -6px;
}
.noUi-handle:active {
    left: -6px;
    top: -6px;
}