.sidebar-title {
    border-bottom: thin solid $border-color;
    font-weight: 600;
    margin-bottom: 30px;
    padding-bottom: 10px;
}
.search-form {
    form {
        position: relative;
        input {
            border: 1px solid #ececec;
            border-radius: 10px;
            height: 64px;
            -webkit-box-shadow: none;
            box-shadow: none;
            padding-left: 20px;
            font-size: 16px;
            width: 100%;
        }
        button {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 0;
            border: none;
            font-size: 20px;
            height: 100%;
            padding: 0 24px;
            background-color: transparent;
            color: #242424;
        }
    }
}