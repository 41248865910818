[data-bs-theme='dark'] {
  --bs-border-color: #{$border-dark};
  --bs-color-white: #{$neutral-0};
  --bs-background-1000: #{$neutral-1000};
  --bs-background-body: #{$background-body-dark};
  --bs-background-1: #{$background-1-dark};
  --bs-background-2: #{$background-2-dark};
  --bs-background-3: #{$background-3-dark};
  --bs-background-4: #{$background-4-dark};
  --bs-background-5: #{$background-5-dark};
  --bs-background-6: #{$background-6-dark};
  --bs-background-7: #{$background-7-dark};
  --bs-background-8: #{$background-8-dark};
  --bs-background-9: #{$background-9-dark};
  --bs-background-card: #{$background-card-dark};
  --bs-neutral-0: #{$neutral-0-dark};
  --bs-neutral-100: #{$neutral-100-dark};
  --bs-neutral-200: #{$neutral-200-dark};
  --bs-neutral-300: #{$neutral-300-dark};
  --bs-neutral-400: #{$neutral-400-dark};
  --bs-neutral-500: #{$neutral-500-dark};
  --bs-neutral-600: #{$neutral-600-dark};
  --bs-neutral-700: #{$neutral-700-dark};
  --bs-neutral-800: #{$neutral-800-dark};
  --bs-neutral-900: #{$neutral-900-dark};
  --bs-neutral-1000: #{$neutral-1000-dark};
  --bs-button-bg: #{$button-bg-dark};
  --bs-button-bg-2: #{$button-bg-2-dark};
  --bs-button-text: #{$button-text-dark};
  --bs-button-text-2: #{$button-text-2-dark};
  .dark-invert {
    filter: invert(1) !important;
  }
  .card-popular .card-info .card-meta .meta-links a {
    border: 0 !important;
  }
}
