.cookie-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.cookie-consent {
  position: fixed;
  z-index: 1050;
  padding: 1rem;
  width: 50%;
}

.cookie-consent-bottom {
  bottom: 0;

  right: 0;
}

.cookie-consent-top {
  top: 0;
  left: 0;
  right: 0;
}

.cookie-consent-bottom-left {
  bottom: 20px;
  left: 20px;
  max-width: 400px;
  border-radius: 8px;
}

.cookie-consent-bottom-right {
  bottom: 20px;
  right: 20px;
  max-width: 400px;
  border-radius: 8px;
}

.cookie-consent .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: #fff;
  border-radius: 15px;
}

.cookie-consent .card-body {
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
}

.cookie-consent .card-title {
  padding: 0.5rem 0;
  margin: 0;
  line-height: 1.5;
  font-size: 26px;
  font-weight: 500;
  color: green;
}
.cookie-consent .card-subtitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
.cookie-consent .card-text {
  margin-bottom: 1rem;
  font-size: 15px;
  font-weight: 500;
}
.cookie-consent .subpara {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.cookie-consent .btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.cookie-consent .btn {
  margin-right: 0.5rem;
}

.cookie-preferences {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.preference-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.preference-item h3 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.preference-item p {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.form-check-input {
  width: 2.5em;
  height: 1.25em;
}
.Cookie-product-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.Cookie-product-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Cookie-product-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: grey;
  transition: 0.4s;
  border-radius: 20px;
}

.Cookie-product-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .Cookie-product-slider {
  background-color: green;
}
.cookie-btn button {
  font-size: 14px;
  padding: 0.6rem 1rem !important;
}

input:checked + .Cookie-product-slider:before {
  transform: translateX(18px);
}
@media (max-width: 768px) {
  .cookie-consent-bottom-left,
  .cookie-consent-bottom-right {
    left: 10px;
    right: 10px;
    max-width: calc(100% - 20px);
  }

  .preference-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-check.form-switch {
    margin-top: 0.5rem;
  }
  .cookie-consent .card-text {
    font-size: 14px;
    font-weight: 500;
  }
  .cookie-consent .card-title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }
  .cookie-consent .card-text {
    font-size: 13px;
  }

  .cookie-btn .btn {
    font-size: 13px !important;
  }
}
@media (max-width: 568px) {
  .cookie-consent-bottom-left,
  .cookie-consent-bottom-right {
    left: 10px;
    right: 10px;
    max-width: calc(100% - 40px);
  }
  .cookie-consent .card-body {
    margin: 0;
  }

  .cookie-consent .card-text {
    font-size: 14px;
    font-weight: 500;
  }
  .cookie-consent .card-title {
    margin: 0;

    font-size: 20px;
    font-weight: 500;
  }
}

@media (max-width: 831px) {
  .cookie-consent {
    width: 100%;
  }
}
