//1.FONT
@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@100..900&display=swap);
$font-heading: 'Urbanist', sans-serif;
$font-text: 'Urbanist', sans-serif;

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
//2.COLOR
// New
$brand-1: #101010;
$brand-2: #70f46d;
$brand-2-darken: #5edd5b;
$brand-2-light: #2d4a2c;
$link-hover-color: #235922;
$neutral-0: #ffffff;
$neutral-100: #f2f4f6;
$neutral-200: #e4e6e8;
$neutral-300: #d6d7d8;
$neutral-400: #8e8e8e;
$neutral-500: #737373;
$neutral-600: #575756;
$neutral-700: #4d4d4d;
$neutral-800: #454545;
$neutral-900: #313131;
$neutral-1000: #000000;
$button-bg: #70f46d;
$button-bg-2: #f2f4f6;
$button-text: #101010;
$button-text-2: #000000;
$button-bg-dark: #313131;
$button-bg-2-dark: #222222;
$button-text-dark: #ededed;
$button-text-2-dark: #afafaf;
$neutral-0-dark: #000000;
$neutral-100-dark: #313131;
$neutral-200-dark: #454545;
$neutral-300-dark: #4d4d4d;
$neutral-400-dark: #575756;
$neutral-500-dark: #737373;
$neutral-600-dark: #8e8e8e;
$neutral-700-dark: #d6d7d8;
$neutral-800-dark: #e4e6e8;
$neutral-900-dark: #f2f4f6;
$neutral-1000-dark: #f2f2f2;
$brand-1-dark: #f2f2f2;
$brand-2-dark: #70f46d;
$brand-2-darken-dark: #5edd5b;
$warning: #ffc700;
$success: #34d674;
$danger: #ff2e00;
$border: #dde1de;
$border-dark: #313131;
$brand-1: #8acfff;
$color-grey: #afafaf;
// End
$color-1: #22d1ee;
$color-2: #c5ff41;
$color-grey-2: #f0f0f0;
$color-black: #000000;
$neutral-950: #1e1e1e;
$green: #319f1f;
$color-white: #ffffff;
// End
//3.Boxshadow
$box-shadow-1: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
$box-shadow-2: 0px 6px 22px 0px rgba(0, 0, 0, 0.09);
$box-shadow-3: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
$box-shadow-4: 0px 22px 120px 0px rgba(0, 0, 0, 0.05);
$box-shadow-5: 0px 6px 15px 0px rgba(64, 79, 100, 0.05);
//4. Border
$border-color: #ececec;
//5. Background
$background-white: #ffffff;
$background-body: #ffffff;
$background-1: #fff0ec;
$background-2: #d8f4db;
$background-3: #e3f0ff;
$background-4: #f6f3fc;
$background-bg-1: #fff0ec;
$background-5: #ffec88;
$background-6: #9dd3fb;
$background-7: #fcf2fa;
$background-8: #f6f3fc;
$background-9: #fcfcf3;
$background-10: #a8baf9;
$background-11: #d4f6c0;
$background-12: #a8e2ef;
$background-13: #8cd8e9;
$background-card: #ffffff;
$background-card-dark: #191919;
$background-body-dark: #171717;
$background-1-dark: #242120;
$background-2-dark: #739393;
$background-3-dark: #1d2024;
$background-4-dark: #242226;
$background-5-dark: #ffdc88;
$background-6-dark: #93dced;
$background-7-dark: #1e1c1d;
$background-8-dark: #121d20;
$background-9-dark: #212114;
// 6. text-shadow
$text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
// 7. breakpoints
$grid-breakpoints: (
  xxxxs: 340px,
  xxxs: 375px,
  xxs: 450px,
  xs: 500px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);
