.box-feature-car {
  padding: 45px 45px 31px 45px;
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-background-card);
  box-shadow: $box-shadow-1;
  margin-bottom: 20px;
  border-radius: 8px;
}
.list-feature-car {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px -7px;
  .item-feature-car {
    padding: 0px 7px;
    // width: 25%;
    margin-bottom: 14px;
    .item-feature-car-inner {
      display: flex;
      align-items: center;
      padding: 14px 16px;
      border: 1px solid var(--bs-border-color);
      background-color: var(--bs-background-2);
      border-radius: 4px;
      .feature-image {
        margin-right: 5px;
        img {
          display: block;
        }
      }
    }
  }
}
.list-checked-green {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px !important;
  margin-bottom: 10px;
  li {
    list-style: none !important;
    width: 100%;
    background-image: url(../imgs/page/hotel/tick-green.svg);
    background-repeat: no-repeat;
    background-position: left center;
    padding: 0px 30px 0px 20px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: var(--bs-neutral-1000);
    margin-bottom: 5px;
  }
}
.list-checked-green-2 {
  list-style: none;
  padding: 0px !important;
  margin-bottom: 12px;
  li {
    list-style: none !important;
    background-image: url(../imgs/page/hotel/tick-green.svg);
    background-repeat: no-repeat;
    background-position: left center;
    padding: 0px 30px 0px 20px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: var(--bs-neutral-1000);
    margin-bottom: 12px;
  }
}
[data-bs-theme='dark'] {
  .box-button-book {
    .btn.btn-book {
      color: var(--bs-neutral-0);
      svg {
        stroke: var(--bs-neutral-0);
      }
    }
  }
}
