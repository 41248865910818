.form-container {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.form-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-step {
  display: flex;
  flex-direction: column;
}

.form-step span {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--bs-neutral-900);
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  min-height: auto;
}
input[type="checkbox"],
input[type="radio"] {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

label div {
  /* display: flex; */
  flex-direction: column;
}

label {
  display: block;
  /* margin-bottom: 10px; */
}

.submit-form-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  background-color: #70f46d;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
}

.submit-form-btn-small {
  margin-top: 20px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  background-color: #70f46d;
  color: #333;
  cursor: pointer;
  align-self: center;
}

.submit-form-btn:hover {
  background-color: #64db62;
}

.submit-form-btn-small:hover {
  background-color: #64db62;
}

.submit-form-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.submit-form-btn.disabled {
  background-color: #ccc; /* Disabled state */
  cursor: not-allowed;
}

.submit-form-btn.disabled:hover {
  background-color: #ccc; /* No hover effect if disabled */
}

@media (max-width: 1024px) {
  /* For tablets and smaller screens */
  .form-container {
    padding: 15px;
  }

  label {
    font-size: 14px;
  }

  input,
  select,
  textarea {
    font-size: 13px;
  }

  .submit-form-btn {
    font-size: 14px;
    padding: 8px 15px;
  }
}

@media screen and (max-width: 768px) {
  /* For mobile screens */
  .form-container {
    padding: 10px;
  }

  label {
    font-size: 13px;
  }

  input,
  select,
  textarea {
    font-size: 12px;
  }

  .submit-form-btn {
    font-size: 12px;
    padding: 8px 12px;
  }
}

.subLabel {
  font-weight: 600 !important;
}
.error-message {
  margin-left: 5px;
  margin-top: 5px;
  font-size: 10px;
  color: red !important;
}

.fottr_bttns {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.yesBttn,
.nobttn {
  padding: 0.7rem 2rem !important;
  border-radius: 7px !important;
  margin: 0px !important;
  font-size: 14px !important;
  height: 40px !important;
  font-weight: 600 !important;
}
.nobttn {
  background-color: #e5e7e7 !important;
  color: #000 !important;
  border: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: #e3e3e3 !important;
  }
}
.modl_title_tc_head {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}
.check_box_dvv {
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-left: 5px;
  input {
    margin: 0px !important;
  }
  label {
    margin: 0px !important;
  }
}

.terms_Contrrr {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 0.5rem;
  -webkit-overflow-scrolling: touch;
}
.form-check-label {
  margin-bottom: 0px !important;
}

.point_agry_terms {
  li {
    margin-left: 20px;
    list-style: disc;
    font-size: 14px;
    font-family: var(--urbanist);
  }
  margin-bottom: 12px;
}
@media screen and (min-width: 991px) {
  .dnone {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .dnone {
    display: none;
  }

  .label768 {
    width: 100%;
  }
  .modl_title_tc_head {
    font-size: 16px;
  }

  .terms_Contrrr {
    h2 {
      font-size: 14px !important;
    }
    p {
      font-size: 12px !important;
    }
    li {
      font-size: 12px !important;
    }
  }
  .check_box_dvv {
    label {
      font-size: 12px;
    }
  }
}
